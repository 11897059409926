<h2 id="tableLabel">Initial Analysis MSE</h2>
<p *ngIf="!dataSource"><em>Loading...</em></p>

<smart-table #table theme="custom" class="table-hover table-bordered table-responsive thead-primary" id="table_initial_analysis_mse"
             [dataSource]="dataSource" [paging]="paging"
             [pageIndex]="pageIndex" [pageSize]="pageSize"
             [columns]="columns" [sortMode]="'many'" [tooltip]="tooltip"
             [freezeHeader]="freezeHeader"
             [filtering]="filtering"
             [columnMenu]="columnMenu"
             [columnResize]="columnResize"></smart-table>

<smart-dialog-window #formWindow id="formWindow" [opened]="false" [label]="'Delete Confirmation'" >
  <h3>Are you sure ?</h3>
  <p>Do you really want to delete these record?</p>
</smart-dialog-window>
