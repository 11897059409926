import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-safety-check-list',
  templateUrl: './safety-check-list.component.html',
  styleUrls: ['./safety-check-list.component.css']
})
export class SafetyCheckListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Participant FirstName",
      "dataField": "Name_First",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Participant LastName",
      "dataField": "Name_Last",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "FirstName of person questioned",
      "dataField": "Name1_First",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "LastName of person questioned",
      "dataField": "Name1_Last",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Address to visit",
      "dataField": "Address",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there easy access to the property? No locked gates or stairs etc?",
      "dataField": "Dropdown",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around access",
      "dataField": "SingleLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this access poses?",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there easy access to exits throughout the home?",
      "dataField": "Dropdown1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around exits",
      "dataField": "SingleLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this exit access poses?",
      "dataField": "Radio1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are there any pets on the property?",
      "dataField": "Dropdown6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they friendly/will someone be there to allow safe access to the house?",
      "dataField": "Dropdown7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details about pets",
      "dataField": "SingleLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this/these pet/s poses?",
      "dataField": "Radio2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there anyone at or visiting the property known to be aggressive or violent?",
      "dataField": "Dropdown2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around aggression/violence",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this violence or aggression poses?",
      "dataField": "Radio3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are you aware of any firearms or weapons stored at the property?",
      "dataField": "Dropdown4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around firearms/weapons",
      "dataField": "SingleLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem these weapons pose?",
      "dataField": "Radio4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there anyone at or regularly visiting the property known to misuse alcohol or drugs?",
      "dataField": "Dropdown3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around alcohol/drug use",
      "dataField": "SingleLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this alcohol/drug use poses?",
      "dataField": "Radio5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is anyone displaying any COVID-19 symptoms or have any other infectious diseases?",
      "dataField": "Dropdown5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details around infection risk",
      "dataField": "SingleLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) What level of risk do you deem this possible infection poses?",
      "dataField": "Radio6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are there any other things we need to be aware of for our visit?",
      "dataField": "Dropdown8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other details",
      "dataField": "SingleLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) Is there a risk associated with this information? If so how much?",
      "dataField": "Radio7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist FirstName",
      "dataField": "Name2_First",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist LastName",
      "dataField": "Name2_Last",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Email",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date-Time",
      "dataField": "DateTime",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Safety_check');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/safety_check_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name_First: string',
          'Name_Last: string',
          'Name1_First: string',
          'Name1_Last: string',
          'Address_AddressLine1: string',
          'Address_AddressLine2: string',
          'Address_City: string',
          'Address_Region: string',
          'Address_ZipCode: string',
          'Dropdown: string',
          'SingleLine: string',
          'Radio: string',
          'Dropdown1: string',
          'SingleLine1: string',
          'Radio1: string',
          'Dropdown6: string',
          'Dropdown7: string',
          'SingleLine6: string',
          'Radio2: string',
          'Dropdown2: string',
          'SingleLine2: string',
          'Radio3: string',
          'Dropdown4: string',
          'SingleLine4: string',
          'Radio4: string',
          'Dropdown3: string',
          'SingleLine3: string',
          'Radio5: string',
          'Dropdown5: string',
          'SingleLine5: string',
          'Radio6: string',
          'Dropdown8: string',
          'SingleLine7: string',
          'Radio7: string',
          'Name2_First: string',
          'Name2_Last: string',
          'Email: string',
          'DateTime_ISO8601: string',
          'DateTime: string',
          'SingleLine8: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'REFERRER_NAME: string',
          'created_by: string',
        ]
      });
    });
  }

}
