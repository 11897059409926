<p>Please select client which you want to proceed:</p>
<smart-list-box #listbox [dataSource]="dataSource" [filterable]="true">
</smart-list-box>

<smart-window #formWindow id="formWindow" [opened]="false" [label]="'Assessment Forms'" (onReady)="onWindowReady($event)">
  <div class="d-flex">
    <h5><b>Client Details:</b></h5> &nbsp;&nbsp; <h5>{{selectedClient?.label}}</h5>
  </div>

  <input type="text" id="searchbox" (keyup)="searchFormName()" placeholder="Search for form names..">

  <ul id="myUL" style="width:100%">
    <p class="group-menu">Assessment</p>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/communication_function']">Communication Function</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/initial_analysis_mse']">Initial Analysis MSE</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/intake_assessment']">Intake Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/restrictive_practices_environment_assessment']">Restrictive Practices/Environment Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/risk_assessment']">Risk Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/safety_check']">Safety Check</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/stress_thermometer_tool']">Stress Thermometer Tool</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/suicide_assessment_kit']">Suicide Assessment Kit</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/whodas_child_form']">WHODAS Child</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/whodas_child_and_youth']">WHODAS Child And Youth</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/whoqol_bref_link_and_paper']">WHOQOL BREF Link And Paper</a>
    </li>




    <p class="group-menu">Reports</p>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/behaviour_assessment_report']">Behaviour Assessment Report</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/comprehensive_behaviour_support']">Comprehensive Behaviour Support</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/environmental_assessment_report']">Environmental Assessment Report</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/fade_out_plan']">Fade Out Plan</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/fai']">FAI</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/information_gathering_onsite_or_zoom']">Information Gathering Form</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/interim_behaviour_support']">Interim Behaviour Support</a>  <!--[queryParams]="{clientName:chirag}"-->
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/justification_letter']">Justification Letter</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/letter_of_support']">Letter Of Support</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/new_fba']">New FBA</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/outcome_report']">Outcome Report</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/psychology_outcome_report']">Psychology Outcome Report</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/purpose_of_medication']">Purpose Of Medication</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/recommendations_letter']">Recommendations Letter</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/session_note']">Session Note</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/treatment_plan']">Treatment Plan</a>
    </li>



    <p class="group-menu">Resources</p>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/initial_screening_and_prep']">Initial Screening And Prep</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/ndis_occupational_thearapy_initial_assessment']">NDIS Occupational Thearapy Initial Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/paediatric_initial_assessment']">Paediatric Initial Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/restrictive_practices_physical_restraint_protocol']">Restrictive Practices Physical Restraint Protocol</a>
    </li>



    <p class="group-menu">Behaviour Tracking Templates</p>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/aps_charter_for_clients']">APS Charter For Clients</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/behaviour_escalation_frequency']">Behaviour Escalation Frequency</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/consent_form_psych_attach_aps_charter']">Consent Form Psych Attach APS Charter</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/consent_to_share_information']">Consent To Share Information</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/observation_chart']">Observation Chart</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/structured_abc_data_sheet']">Structured ABC Data Sheet</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/visual_consent']">Visual Consent</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/visual_consent_psychology']">Visual Consent (Psychology)</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/visual_introduction']">Visual Introduction</a>
    </li>

    <p class="group-menu">Intake</p>
    <li class="list-group-item">
      <a class="nav-link text-dark" href="https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/BehaviourSupportPreIntakeAssessment/formperma/E-l-KVxBAZ8rljd4CSHXUOTNBimdGhJy2D1snmgQh3I" target=”_blank” >Behaviour Support Pre-Intake Assessment</a>
    </li>
    <li class="list-group-item">
      <a class="nav-link text-dark" href="https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/IntakeAssessment/formperma/UGrlZ9hgNuU80YZynP1HHVMvQOkLhaR5w9mC_YCy64s" target=”_blank” >Psychology Pre-Intake Assessment</a>
    </li>

    <!-- <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/risk_assessment_manual']">Risk Assessment Manual</a>
    </li> -->
    
    <!-- <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/hours_for_new_funding_period']">Hours For New Funding Period</a>
    </li> -->
    
    <!-- <li class="list-group-item">
      <a class="nav-link text-dark" [routerLink]="['/disdat']">DISDAT</a>
    </li> -->
    
  </ul>
</smart-window>

