<h4 id="tableLabel">Incidents</h4>
<hr />
<p *ngIf="!dataSource"><em>Loading...</em></p>

<div class="justify-content-end float-right">
  <button type="button" [routerLink]="['/incident']" class="btn btn-primary">Create new incident</button>
</div>

<br />
<br />

<div id="overlay">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div class="spinner"></div>
  </div>
</div>

<smart-table #table theme="custom" class="table-hover table-bordered table-responsive thead-primary" id="table_incident"
             [dataSource]="dataSource" [paging]="paging"
             [pageIndex]="pageIndex" [pageSize]="pageSize"
             [columns]="columns" [sortMode]="'many'" [tooltip]="tooltip"
             [freezeHeader]="freezeHeader"
             [filtering]="filtering"
             [columnMenu]="columnMenu"
             [columnResize]="columnResize" [filterRow]="filterRow"></smart-table>




<smart-dialog-window #formWindow id="formWindow" [opened]="false" [label]="'Delete Confirmation'" >
  <h3>Are you sure ?</h3>
  <p>Do you really want to delete these record?</p>
</smart-dialog-window>
