import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AzureAdService } from './azure-ad.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGaurd implements CanActivate {

  constructor(private _msalService: MsalService, private azureAdService: AzureAdService) { }

  //canActivate(
  //  route: ActivatedRouteSnapshot,
  //  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //  return true;
  //}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var allAccounts = this._msalService.instance.getAllAccounts();

    if (allAccounts.length > 0) {
      var account = allAccounts[0];
      const userRoles = (account.idTokenClaims as any).roles;
      const allowedRoles = route.data["roles"];
      const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));

      if (matchingRoles) {
        if (matchingRoles.includes("Admin")) {
          this.azureAdService.hasAdminAccess.next(true)
        }
        else if (matchingRoles.includes("Therapist")) {
          this.azureAdService.hasTherapistAccess.next(true)
        }
      }

      return matchingRoles.length > 0;
    }
    else
      return false;
  }
  
}
