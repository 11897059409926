import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peer-support-bookings',
  templateUrl: './peer-support-bookings.component.html',
  styleUrls: ['./peer-support-bookings.component.css']
})
export class PeerSupportBookingsComponent implements OnInit {
  iframeURI: string = "";
  constructor() { }

  ngOnInit(): void {
    this.iframeURI = `https://outlook.office365.com/owa/calendar/NationalPBS@nationalpbs.com.au/bookings/`;
  }

}
