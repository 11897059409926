import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desk-bookings',
  templateUrl: './desk-bookings.component.html',
  styleUrls: ['./desk-bookings.component.css']
})
export class DeskBookingsComponent implements OnInit {
  iframeURI: string = "";

  constructor() { }

  ngOnInit(): void {
    this.iframeURI = `https://apps.powerapps.com/play/e/677c193f-3bee-e0e0-bdd6-40a6d9ef75cc/a/49df5103-7d09-4b7c-8d14-f0b266afd504`;
  }

}
