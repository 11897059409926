<div class="justify-content-end text-align-center">
    <h4><b>Client - {{clientName}}</b></h4>
  </div>
  
  <div class="justify-content-end float-right">
    <button class="btn btn-primary" (click)="SaveAndUpload()">Upload on CorePlus</button>
  </div>
  <div id="overlay">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <div class="spinner"></div>
    </div>
  </div>
  
  <div class="justify-content-end" *ngIf="hasAdminAccess">
    <button class="btn btn-primary " (click)="Download()">Download</button>
  </div>
  <iframe frameborder="0" class="iframe-style" height='700px'
          [src]="iframeURI | safe"></iframe>
  