<h2 id="tableLabel">Paediatric Initial Assessment</h2>
<p *ngIf="!dataSource"><em>Loading...</em></p>

<smart-table #table theme="custom" class="table-hover table-bordered table-responsive thead-primary" id="table_paediatric_initial_assessment"
             [dataSource]="dataSource" [paging]="paging"
             [pageIndex]="pageIndex" [pageSize]="pageSize"
             [columns]="columns" [sortMode]="'many'" [tooltip]="tooltip"
             [freezeHeader]="freezeHeader"
             [filtering]="filtering"
             [columnMenu]="columnMenu"
             [dataSourceSettings]="dataSourceSettings"
             [columnResize]="columnResize"></smart-table>
<div class="options">
  <smart-button #button (onClick)="handleClick($event, 'csv')" id="csv">Export to CSV</smart-button>
  <!--<smart-button #button2 (onClick)="handleClick($event, 'html')" id="html">Export to HTML</smart-button>
  <smart-button #button3 (onClick)="handleClick($event, 'json')" id="json">Export to JSON</smart-button>-->
  <smart-button #button4 (onClick)="handleClick($event, 'pdf')" id="pdf">Export to PDF</smart-button>
  <!--<smart-button #button5 (onClick)="handleClick($event, 'xlsx')" id="xlsx">Export to XLSX</smart-button>
  <smart-button #button6 (onClick)="handleClick($event, 'xml')" id="xml">Export to XML</smart-button>-->
</div>
