<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">


      <a class="nav-link text-dark" [routerLink]="['/']">
        <img src="../../assets/Images/NPBS-Logo-150x150.png" alt="NPBS" loading="lazy" class="img-thumbnail" style="width:80%">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-between" id="navbarSupportedContent">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item">
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li>
          <!-- <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
            <a class="nav-link text-dark">Document Templates</a>
          </li> -->
          <li class="nav-item" *ngIf="isUserLoggedIn && (hasAdminAccess)">
            <a class="nav-link text-dark" [routerLink]="['/news-list']">News/Blogs</a>
          </li>

          <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess)">
            <a class="nav-link text-dark" [routerLink]="['/news-list-for-therapist']">News/Blogs</a>
          </li>

          <li class="nav-item dropdown" *ngIf="isUserLoggedIn && (hasAdminAccess)">
            <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Reports
            </button>
            <ul class="dropdown-menu">
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/incident-list']">Incidents</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/complaint-list']">Complaints</a>
              </li>
            </ul>
          </li>
          
          <li class="nav-item dropdown" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
            <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Bookings
            </button>
            <ul class="dropdown-menu">
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/desk-bookings']">Desk Bookings</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/car-bookings']">NPBS Car Bookings</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/room-bookings']">Room Bookings</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/peer-support-bookings']">Peer Support Bookings</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
            <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              NPBS Resources
            </button>
            <ul class="dropdown-menu">
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/list-of-strategies']">List of Strategies</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" href="https://nationalpbs.sharepoint.com/:f:/s/npbsintranet2/Erj-GMWG5z5Ar9iuWBcCiGQBtBnaLKg4v2sz_oK1Lu3sjA?e=jNwjAX" target=”_blank”>Documents</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" >Medication</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item">Diagnosis</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
            <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Training
            </button>
            <ul class="dropdown-menu">
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/pd-day']">PD Day</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
            <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Client
            </button>
            <ul class="dropdown-menu">
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)">
                <a class="dropdown-item" [routerLink]="['/clients']">New Assessment</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn && (hasTherapistAccess || hasAdminAccess)"> 
                <a class="dropdown-item text-dark" href="#"> Completed Assessment &raquo; </a>
                <ul id="menuUL" class="submenu dropdown-menu" style="max-height: 400px; overflow-y: auto;">
                  <input type="text" id="menusearchbox" (keyup)="searchFormName()" placeholder="Search for form names..">

                  <p class="group-menu">Assessment</p>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/communication_function_list']">Communication Function</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/initial_analysis_mse_list']">Initial Analysis MSE</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/intake_assessment_list']">Intake Assessment</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/restrictive_practices_environment_assessment_list']">Restrictive Practices/Environment Assessment</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/risk_assessment_list']">Risk Assessment</a>
                  </li>
                  <li  *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/safety_check_list']">Safety Check</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/stress_thermometer_tool_list']">Stress Thermometer Tool</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/suicide_assessment_kit_list']">Suicide Assessment Kit</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/whodas_child_form_list']">WHODAS Child</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/whodas_child_and_youth_list']">WHODAS Child And Youth</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/whoqol_bref_link_and_paper_list']">WHOQOL BREF Link And Paper</a>
                  </li>
                  


                  <p class="group-menu">Reports</p>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/behaviour_assessment_report_list']">Behaviour Assessment Report</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/comprehensive_behaviour_support_list']">Comprehensive Behaviour Support</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/environmental_assessment_report_list']">Environmental Assessment Report</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/fade_out_plan_list']">Fade Out Plan</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/fai_list']">FAI</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/information_gathering_onsite_or_zoom_list']">Information Gathering Form</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/interim_behaviour_support_list']">Interim Behaviour Support</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/justification_letter_list']">Justification Letter</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/letter_of_support_list']">Letter Of Support</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/new_fba_list']">New FBA</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/outcome_report_list']">Outcome Report</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/psychology_outcome_report_list']">Psychology Outcome Report</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/purpose_of_medication_list']">Purpose Of Medication</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/recommendations_letter_list']">Recommendations Letter</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/session_note_list']">Session Note</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/treatment_plan_list']">Treatment Plan</a>
                  </li>
                  
                  

                  <p class="group-menu">Resources</p>
                  <li  *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/initial_screening_and_prep_list']">Initial Screening And Prep</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/ndis_occupational_thearapy_initial_assessment_list']">NDIS Occupational Thearapy Initial Assessment</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/paediatric_initial_assessment_list']">Paediatric Initial Assessment</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/restrictive_practices_physical_restraint_protocol_list']">Restrictive Practices Physical Restraint Protocol</a>
                  </li>



                  <p class="group-menu">Behaviour Tracking Templates</p>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/aps_charter_for_clients_list']">APS Charter For Clients</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/behaviour_escalation_frequency_list']">Behaviour Escalation Frequency</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/consent_form_psych_attach_aps_charter_list']">Consent Form Psych Attach APS Charter</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/consent_to_share_information_list']">Consent To Share Information</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/observation_chart_list']">Observation Chart</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/structured_abc_data_sheet_list']">Structured ABC Data Sheet</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/visual_consent_list']">Visual Consent</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/visual_consent_psychology_list']">Visual Consent (Psychology)</a>
                  </li>
                  <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/visual_introduction_list']">Visual Introduction</a>
                  </li>

                  <!-- <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/hours_for_new_funding_period_list']">Hours For New Funding Period</a>
                  </li> -->
                  
                  <!-- <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/intake_form_17_and_under_list']">Intake Form 17 And Under</a>
                  </li> -->
                  
                  <!-- <li *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/disdat_list']">DISDAT</a>
                  </li> -->

                  <!-- <li class="nav-item" *ngIf="isUserLoggedIn">
                    <a class="dropdown-item" [routerLink]="['/risk_assessment_manual_list']">Risk Assessment Manual</a>
                  </li> -->
                  
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="justify-content-end">

          <span class="email_name">{{profile?.mail}}</span>

          <button mat-raised-button [matMenuTriggerFor]="loginMenu" class="btn btn-primary " (click)="login()" *ngIf="!isUserLoggedIn">Login</button>

          <button mat-raised-button [matMenuTriggerFor]="logoutMenu" class="btn btn-primary " (click)="logout()" *ngIf="isUserLoggedIn">Logout</button>
        </div>
      </div>
    </div>
  </nav>

  <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">
    <a class="navbar-brand" href="#">Brand</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="main_nav">
      <ul class="navbar-nav">
        <li class="nav-item active"> <a class="nav-link" href="#">Home </a> </li>
        <li class="nav-item"><a class="nav-link" href="#"> About </a></li>
        <li class="nav-item dropdown" id="myDropdown">
          <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">  Treeview menu  </a>
          <ul class="dropdown-menu">
            <li> <a class="dropdown-item" href="#"> Dropdown item 1 </a></li>
            <li> <a class="dropdown-item" href="#"> Dropdown item 2 &raquo; </a>
              <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item" href="#">Submenu item 1</a></li>
                <li><a class="dropdown-item" href="#">Submenu item 2</a></li>
                <li><a class="dropdown-item" href="#">Submenu item 3 &raquo; </a>
                  <ul class="submenu dropdown-menu">
                    <li><a class="dropdown-item" href="#">Multi level 1</a></li>
                    <li><a class="dropdown-item" href="#">Multi level 2</a></li>
                  </ul>
                </li>
                <li><a class="dropdown-item" href="#">Submenu item 4</a></li>
                <li><a class="dropdown-item" href="#">Submenu item 5</a></li>
              </ul>
            </li>
            <li><a class="dropdown-item" href="#"> Dropdown item 3 </a></li>
            <li><a class="dropdown-item" href="#"> Dropdown item 4 </a></li>
          </ul>
        </li>
      </ul>
    </div>
    </div>
    </nav> -->

    

  <!--<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="nav-link text-dark" [routerLink]="['/']">
        <img src="../../assets/Images/NPBS-Logo-150x150.png" alt="NPBS" loading="lazy" class="img-thumbnail" style="width:50%">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item dropdown">
            <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Resources
            </button>
            <ul class="dropdown-menu dropdown-menu-dark">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="isUserLoggedIn">
            <a class="nav-link" [routerLink]="">NPBS Induction</a>
          </li>
          <li class="nav-item dropdown" *ngIf="isUserLoggedIn">
            <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Assessments
            </button>
            <ul class="dropdown-menu dropdown-menu-dark">
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/restrictive_practies_environment_assessment']">Restrictive Practices/Environment Assessment</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/risk_assessment_manual']">Risk Assessment Manual</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/risk_assessment']">Risk Assessment</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/safety_check']">Safety Check</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/initial_screening_and_prep']">Initial Screening And Prep</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/ndis_occupational_thearapy_initial_assessment']">NDIS Occupational Thearapy Initial Assessment</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="dropdown-item" [routerLink]="['/paediatric_initial_assessment']">Paediatric Initial Assessment</a>
              </li>
            </ul>
          </li>

          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isUserLoggedIn">
            <a class="nav-link" [routerLink]="['/person']">Person</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/profile']">Profile</a>
          </li>
        </ul>

        <div class="collapse navbar-collapse d-sm-inline-flex justify-content-end">
          <button class="btn btn-primary" (click)="login()" *ngIf="!isUserLoggedIn">Login</button>
          <button class="btn btn-primary" (click)="logout()" *ngIf="isUserLoggedIn">Logout</button>
        </div>
      </div>
    </div>
  </nav>-->
</header>

