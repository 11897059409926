export class Incident {
  unique_id: number;
  summary: string;
  category: string;
  business_unit: string;
  is_service_recipient: boolean;
  service_recipient_text: string;
  is_other_non_recipient: boolean;
  other_non_recipient_text: string;
  description: string;
  location: string;
  is_incident_occurred: string;
  incident_occurred_datetime: string;
  winesses: string;
  immediate_actions_in_response: string;
  severity_rating: string;
  incident_reported_by: string;
  is_reported_for_someone_else: boolean;
  is_incident_reported: string;
  incident_reported_datetime: string;
  attachment_id: number;
  status: string;
  position_responsible: string;
  report_notify: string;
  exrernal_parties_notified: string;
  outcome_from_external_parties: string;
  investigation_process: string;
  investigation_outcome: string;
  actions_taken: string;
  is_resolve_by: string;
  resolve_by_datetime: string;
  related_to: string;
  improvements: string;
  external_identifier: string;
  relavent_policy_regulation: string;
  is_closed_on: string;
  closed_on_datetime: string;
  closing_comments: string;
  satisfactory_resolution: string;
  resolution_approved_by: string;
  is_return_to_work: boolean;
  report_to_brightsafe: string;
  return_to_work_text: string;
  enabled: boolean;
  incident_attachment: IncidentAttachment;
}

export class IncidentAttachment {
  File: File;
  Id: number;
  unique_id: number;
  Name: string;
}

export class Complaint {
  unique_id: number;
  summary: string;
  category: string;
  business_unit: string;
  is_service_recipient: boolean;
  service_recipient_text: string;
  complaint_in_detail: string;
  person_making_complaint: string;
  relationship_to_organisation: string;
  phone_number: string;
  email_address: string;
  lodged_by: string;
  method_complaint_received: string;
  when_complaint_received: string;
  when_complaint_received_datetime: string;
  when_complaint_ack: string;
  when_complaint_ack_datetime: string;
  attachment_id: number;
  status: string;
  priority: string;
  position_responsible: string;
  exrernal_parties_notified: string;
  outcome_from_external_parties: string;
  investigation_process: string;
  investigation_outcome: string;
  actions_taken: string;
  is_resolve_by: string;
  resolve_by_datetime: string;
  related_to: string;
  improvements: string;
  external_identifier: string;
  relavent_policy_regulation: string;
  is_closed_on: string;
  closed_on_datetime: string;
  closing_comments: string;
  satisfactory_resolution: string;
  resolution_approved_by: string;
  enabled: boolean;
  complaint_attachment: ComplaintAttachment;
}
export class ComplaintAttachment {
  File: File;
  Id: number;
  unique_id: number;
  Name: string;
}
