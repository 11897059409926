import { Component, OnInit } from '@angular/core';
import { News, NewsImage } from '../models/news.model';
import { HttpService } from '../http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {

  news: News = new News();
  unique_id: any;
  is_new_form: boolean = true;
  file: File = null;

  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private clientService: ClientService) { }

  ngOnInit(): void {
    this.news.news_image = new NewsImage();
    this.unique_id = this.route.snapshot.paramMap.get('id')

    if (this.unique_id) {
      this.showloader();
      this.is_new_form = false;
      this.httpService.get(`api/v1/news/get/${this.unique_id}`).subscribe(result => {
        this.news = result;
        this.hideloader();
      });
    }
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }

}
