<div id="overlay" style="height: 2570px;">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <div class="spinner"></div>
    </div>
</div>

<div class="p-2 row">
  <h3><b>{{news.summary}}</b></h3>
  <div class="col-sm-12">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>

<div class="form-group row p-2">
  <div class="col-sm-9">
    <!-- <input type="file" #file class="form-control" (change)="onFileChange($event)" [(ngModel)]="news.image_id" id="input-image" /> -->
    <img class="immg-up img-ht-sp-2" width="600" height="300" src="data:image/png;base64,{{news.news_image.File}}" onerror="this.src='./../assets/Images/main-banner-small.png'" />
  </div>
</div>

<div class="form-group row p-2">
    <!-- <p>{{news.description}}</p> -->
    <div [innerHTML]="news.description"></div>
  </div>

