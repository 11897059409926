import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-initial-screening-and-prep-list',
  templateUrl: './initial-screening-and-prep-list.component.html',
  styleUrls: ['./initial-screening-and-prep-list.component.css']
})
export class InitialScreeningAndPrepListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Participant Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS number",
      "dataField": "Number",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Px DOB",
      "dataField": "Date1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Name of person completing form",
      "dataField": "Name1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Email of person completing form",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date",
      "dataField": "Date",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Purpose of referral- what does the client need, why have you referred them to us and is a report required?",
      "dataField": "DecisionBox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Confirm Diagnosis/es.",
      "dataField": "DecisionBox12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is English their first language? Are they nonverbal? What is their preferred communication style? Is an Interpreter required?",
      "dataField": "DecisionBox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do we know the mobility function of the client? i.e. walking frame, one assist, two assist, hoist, bed bound.",
      "dataField": "DecisionBox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Confirm funding amount (hours for OT) to work within.",
      "dataField": "DecisionBox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other services involved?",
      "dataField": "DecisionBox13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "General Comments/Notes regarding above",
      "dataField": "MultiLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist Introduction- Who are you and why are you calling?",
      "dataField": "DecisionBox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Tell me a little about yourself, what do you need? Have you had an Occupational Therapist before?",
      "dataField": "DecisionBox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Who else will be present for the initial assessment? Family member, care worker, interpreter?",
      "dataField": "DecisionBox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are pets present? (They will need to be restrained at time of assessment)",
      "dataField": "DecisionBox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is their preferred communication method for correspondence? (SMS, Email, Phone Call)",
      "dataField": "DecisionBox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is parking available and easy to access?",
      "dataField": "DecisionBox10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When are you scheduled to attend? Confirm day, time and location (in their home, group home, day program, aged care facility)",
      "dataField": "DecisionBox11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "General Comments/Notes regarding above",
      "dataField": "MultiLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Have you or any family members been overseas, or come back from overseas in the last 2 weeks?",
      "dataField": "Radio4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you have any cold or flu symptoms at the moment?",
      "dataField": "Radio5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Have you been in contact with anyone who has tested positive for Covid?",
      "dataField": "Radio7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you have any significant heart or lung conditions, or are you immuno-suppressed?",
      "dataField": "Radio6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "General Comments/Notes regarding above",
      "dataField": "MultiLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is the appointment outside your usual working hours? Or will you be alone with the participant for the initial assessment?",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is the residence located in a high-risk area? (isolated, low socio-economic, housing commission)",
      "dataField": "Radio1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you know how to safely use the mobility or assistive aids used by the client?",
      "dataField": "Radio2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If you answered ‘Yes’ to ANY of the above questions (or any other additional safety concerns), have you discussed these safety controls with your Team Leader? What is the plan?",
      "dataField": "MultiLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Have you placed appropriate controls in place to manage ALL medium to high risks or hazards? What are they? Do not proceed with this assessment until all appropriate controls have been implemented.",
      "dataField": "MultiLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you feel safe completing an initial assessment for this participant?",
      "dataField": "Radio3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Equipment needed for completion of Initial Assessment and Your plan for the initial assessment.",
      "dataField": "MultiLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'National_occupational_therapy');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/initial_screening_and_prep_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name: string',
          'Number: string',
          'Date1_ISO8601: string',
          'Date1: string',
          'Name1: string',
          'Email: string',
          'Date_ISO8601: string',
          'Date: string',
          'DecisionBox: string',
          'DecisionBox12: string',
          'DecisionBox1: string',
          'DecisionBox2: string',
          'DecisionBox3: string',
          'DecisionBox13: string',
          'MultiLine: string',
          'DecisionBox4: string',
          'DecisionBox5: string',
          'DecisionBox6: string',
          'DecisionBox7: string',
          'DecisionBox9: string',
          'DecisionBox10: string',
          'DecisionBox11: string',
          'MultiLine1: string',
          'Radio4: string',
          'Radio5: string',
          'Radio7: string',
          'Radio6: string',
          'MultiLine5: string',
          'Radio: string',
          'Radio1: string',
          'Radio2: string',
          'MultiLine2: string',
          'MultiLine3: string',
          'Radio3: string',
          'MultiLine4: string',
          'SingleLine: string',
          'REFERRER_NAME: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'IP_ADDRESS: string',
          'created_by: string',
        ]
      });
    });
  }

}
