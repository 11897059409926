import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-risk-assessment-list',
  templateUrl: './risk-assessment-list.component.html',
  styleUrls: ['./risk-assessment-list.component.css']
})
export class RiskAssessmentListComponent implements OnInit {
  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      label: 'First Name',
      dataField: 'First Name',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Last Name',
      dataField: 'Last Name',
      dataType: 'string',
      width: 200
    },
    {
      label: 'DOB',
      dataField: 'DOB',
      dataType: 'string',
      width: 150
    },
    {
      label: 'Age at time of Assessment',
      dataField: 'Age at time of Assessment',
      dataType: 'string',
      width: 200
    },
    {
      label: 'NDIS number',
      dataField: 'NDIS number',
      dataType: 'string',
      width: 150
    },
    {
      label: 'First Name of person responding',
      dataField: 'First Name of person responding',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Lsat Name of person responding',
      dataField: 'Lsat Name of person responding',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Relationship to participant',
      dataField: 'Relationship to participant',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Phone',
      dataField: 'Phone',
      dataType: 'string',
      width: 150
    },
    {
      label: 'Email',
      dataField: 'Email',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Is the participant at risk of wandering?',
      dataField: 'Is the participant at risk of wandering?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What are the current risks for wandering?',
      dataField: 'What are the current risks for wandering?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - wandering',
      dataField: 'Description of risk - wandering',
      dataType: 'string',
      width: 500
    },
    {
      label: 'How frequently do they wander?',
      dataField: 'How frequently do they wander?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'How long do they wander for?',
      dataField: 'How long do they wander for?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Is the participant at risk of self harm e.g. biting, hitting, scratching, cutting themselves?',
      dataField: 'Is the participant at risk of self harm e.g. biting, hitting, scratching, cutting themselves?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - of harm to self',
      dataField: 'Description of risk - of harm to self',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What is the likelihood of this occurring/Do they harm themselves often?',
      dataField: 'What is the likelihood of this occurring/Do they harm themselves often?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Has the participant ever attempted or mentioned they have thought to take their life?',
      dataField: 'Has the participant ever attempted or mentioned they have thought to take their life?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - of Suicide',
      dataField: 'Description of risk - of Suicide',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Is there a current active plan of how they would carry out with the suicide?',
      dataField: 'Is there a current active plan of how they would carry out with the suicide?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are they currently seeing someone for their suicidal thoughts/attempts or self harm?',
      dataField: 'Are they currently seeing someone for their suicidal thoughts/attempts or self harm?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What is the likelihood of this occurring/Do they have means or a plan?',
      dataField: 'What is the likelihood of this occurring/Do they have means or a plan?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are there any risks of the participant using drugs or alcohol in an unsafe manner?',
      dataField: 'Are there any risks of the participant using drugs or alcohol in an unsafe manner?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - Substance misuse behaviour',
      dataField: 'Description of risk - Substance misuse behaviour',
      dataType: 'string',
      width: 500
    },
    {
      label: 'How likely is this to be a recent/current behaviour?',
      dataField: 'How likely is this to be a recent/current behaviour?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Is the participant at risk of harming others physically or through intimidation?',
      dataField: 'Is the participant at risk of harming others physically or through intimidation?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - of Physical harm to others/Intimidation',
      dataField: 'Description of risk - of Physical harm to others/Intimidation',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What is the likelihood of this physical harm occurring?',
      dataField: 'What is the likelihood of this physical harm occurring?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Is the participant at risk of harming others through verbal intimidation?',
      dataField: 'Is the participant at risk of harming others through verbal intimidation?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - of verbal harm',
      dataField: 'Description of risk - of verbal harm',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What is the likelihood of these risks occurring?',
      dataField: '1 What is the likelihood of these risks occurring?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Is the participant at risk of causing property damage?',
      dataField: 'Is the participant at risk of causing property damage?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - property damage',
      dataField: 'Description of risk - property damage',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What sort of cost is associated with the property damage?',
      dataField: 'What sort of cost is associated with the property damage?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are there any concerns or thoughts they might come to harm by any of the following?',
      dataField: 'Are there any concerns or thoughts they might come to harm by any of the following?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - at home',
      dataField: 'Description of risk - at home',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What level of risk do you think this poses?',
      dataField: '1 What level of risk do you think this poses?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are there any risks of the participant engaging in harmful social behaviours?',
      dataField: 'Are there any risks of the participant engaging in harmful social behaviours?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - risky behaviour social',
      dataField: 'Description of risk - risky behaviour social',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are these criminal matters recent?',
      dataField: 'Are these criminal matters recent?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are there any pending court dates or expectations of jail/prison time?',
      dataField: 'Are there any pending court dates or expectations of jail/prison time?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Details of upcoming proceedings',
      dataField: 'Details of upcoming proceedings',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What level of risk do you think this poses?',
      dataField: '2 What level of risk do you think this poses?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Are they at risk from any medical intervention challenges or chronic medical conditions?',
      dataField: 'Are they at risk from any medical intervention challenges or chronic medical conditions?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - medical intervention',
      dataField: 'Description of risk - medical intervention',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What is the likelihood of these risks occurring?',
      dataField: '2 What is the likelihood of these risks occurring?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Does the participant have any sexualised behaviours of concern?',
      dataField: 'Does the participant have any sexualised behaviours of concern?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Description of risk - sexualised behaviours',
      dataField: 'Description of risk - sexualised behaviours',
      dataType: 'string',
      width: 500
    },
    {
      label: 'What level of risk do you think this poses?',
      dataField: '3 What level of risk do you think this poses?',
      dataType: 'string',
      width: 500
    },
    {
      label: 'Therapist First Name',
      dataField: 'Therapist First Name',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Therapist Last Name',
      dataField: 'Therapist Last Name',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Therapist Email',
      dataField: 'Therapist Email',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Date-Time',
      dataField: 'Date-Time',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Added Time',
      dataField: 'Added Time',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Referrer Name',
      dataField: 'Referrer Name',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Risk_assessment');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/risk_assessment_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'First Name: string',
          'Last Name: string',
          'DOB: string',
          'Age at time of Assessment: string',
          'NDIS number: string',
          'First Name of person responding: string',
          'Lsat Name of person responding: string',
          'Relationship to participant: string',
          'Phone: string',
          'Email: string',
          'Is the participant at risk of wandering?: string',
          'What are the current risks for wandering?: string',
          'Description of risk - wandering: string',
          'How frequently do they wander?: string',
          'How long do they wander for?: string',
          'Is the participant at risk of self harm e.g. biting, hitting, scratching, cutting themselves?: string',
          'Description of risk - of harm to self: string',
          'What is the likelihood of this occurring/Do they harm themselves often?: string',
          'Has the participant ever attempted or mentioned they have thought to take their life?: string',
          'Description of risk - of Suicide: string',
          'Is there a current active plan of how they would carry out with the suicide?: string',
          'Are they currently seeing someone for their suicidal thoughts/attempts or self harm?: string',
          'What is the likelihood of this occurring/Do they have means or a plan?: string',
          'Are there any risks of the participant using drugs or alcohol in an unsafe manner?: string',
          'Description of risk - Substance misuse behaviour: string',
          'How likely is this to be a recent/current behaviour?: string',
          'Is the participant at risk of harming others physically or through intimidation?: string',
          'Description of risk - of Physical harm to others/Intimidation: string',
          'What is the likelihood of this physical harm occurring?: string',
          'Is the participant at risk of harming others through verbal intimidation?: string',
          'Description of risk - of verbal harm: string',
          '1 What is the likelihood of these risks occurring?: string',
          'Is the participant at risk of causing property damage?: string',
          'Description of risk - property damage: string',
          'What sort of cost is associated with the property damage?: string',
          'Are there any concerns or thoughts they might come to harm by any of the following?: string',
          'Description of risk - at home: string',
          '1 What level of risk do you think this poses?: string',
          'Are there any risks of the participant engaging in harmful social behaviours?: string',
          'Description of risk - risky behaviour social: string',
          'Are these criminal matters recent?: string',
          'Are there any pending court dates or expectations of jail/prison time?: string',
          'Details of upcoming proceedings: string',
          '2 What level of risk do you think this poses?: string',
          'Are they at risk from any medical intervention challenges or chronic medical conditions?: string',
          'Description of risk - medical intervention: string',
          '2 What is the likelihood of these risks occurring?: string',
          'Does the participant have any sexualised behaviours of concern?: string',
          'Description of risk - sexualised behaviours: string',
          '3 What level of risk do you think this poses?: string',
          'Therapist First Name: string',
          'Therapist Last Name: string',
          'Therapist Email: string',
          'Date-Time: string',
          'Added Time: string',
          'Referrer Name: string',
          'created_by: string',
        ]
      });
    });
  }

}
