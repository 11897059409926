<!--<iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://growecomm.s3.ap-southeast-2.amazonaws.com/5_a_Interim_Behaviour_Support.docx'
        width='1300px' height='700px'>This is an embedded
  <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by
  <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
</iframe>-->
<!--<iframe src="https://docs.google.com/gview?url=https://growecomm.s3.ap-southeast-2.amazonaws.com/5_a_Interim_Behaviour_Support.docx&embedded=true"
        width='1300px' height='700px'></iframe>-->
<!--<iframe src="https://view.officeapps.live.com/op/view.aspx?src=https://growecomm.s3.ap-southeast-2.amazonaws.com/5_a_Interim_Behaviour_Support.docx" width='1300px' height='700px'>

</iframe>-->
<!--<iframe src="https://docs.google.com/document/d/1dA7sk56rt_8oWHdM-FO9dB-sDvBz7M1s/edit" width='1300px' height='700px'>
</iframe>-->

<div class="justify-content-end text-align-center">
  <h4><b>Client - {{clientName}}</b></h4>
</div>

<div class="justify-content-end float-right">
    <button class="btn btn-primary" (click)="SaveAndUpload()">Upload on CorePlus</button>
</div>
<div id="overlay">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div class="spinner"></div>
  </div>
</div>

<div class="justify-content-end" *ngIf="hasAdminAccess">
  <button class="btn btn-primary " (click)="Download()">Download</button>
</div>
<iframe frameborder="0" class="iframe-style" height='700px'
        [src]="iframeURI | safe"></iframe>



