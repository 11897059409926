import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-of-strategies',
  templateUrl: './list-of-strategies.component.html',
  styleUrls: ['./list-of-strategies.component.css']
})
export class ListOfStrategiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
