<h4 *ngIf="is_new_form"><b>Add a new Complaint</b></h4>
<h4 *ngIf="!is_new_form"><b>Edit Complaint</b></h4>
<hr />
<div id="overlay" style="height: 2150px;">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div class="spinner"></div>
  </div>
</div>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Details</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
  <div class="col-sm-1"><button type="button" [routerLink]="['/complaint-list']" class="btn btn-gray">Cancel</button></div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-summary" class="col-sm-2 col-form-label required">Complaint Summary:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-complaint-summary" required [(ngModel)]="complaint.summary" placeholder="enter a brief summary of the complaint in just a few words">
  </div>
  <div class="col-sm-1">
    <button type="button" class="btn btn-gray" (click)="saveComplaint()" *ngIf="is_new_form"
            [disabled]="complaint.summary == null || complaint.summary == '' || complaint.complaint_in_detail == null || complaint.complaint_in_detail == ''">
      Save
    </button>
    <button type="button" class="btn btn-gray" (click)="updateComplaint()" *ngIf="!is_new_form"
            [disabled]="complaint.summary == null || complaint.summary == '' || complaint.complaint_in_detail == null || complaint.complaint_in_detail == ''">
      Update
    </button>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-category" class="col-sm-2 col-form-label">Category:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-category" [(ngModel)]="complaint.category">
      <option value="Participant complaint">Participant complaint</option>
      <option value="Provider complaint/concern">Provider complaint/concern</option>
      <option value="Therapist complaint">Therapist complaint</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-business" class="col-sm-2 col-form-label">Business Unit:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-business" [(ngModel)]="complaint.business_unit">
      <option value="Moonee Ponds">Moonee Ponds</option>
      <option value="Malvern East">Malvern East</option>
      <option value="Geelong">Geelong</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2">Service Recipient</div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check1" [(ngModel)]="complaint.is_service_recipient">
      <label class="form-check-label" for="check1">
        Does Complaint relate to a person receiving a service from this organization ?
      </label>
    </div>
  </div>
</div>
<div class="form-group row p-2" *ngIf="complaint.is_service_recipient">
  <label for="input-complaint-servie-recipient" class="col-sm-2 col-form-label"></label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-complaint-servie-recipient" [(ngModel)]="complaint.service_recipient_text" placeholder="">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint_in_detail" class="col-sm-2 col-form-label required">Complaint (In Detail):</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-complaint_in_detail" [(ngModel)]="complaint.complaint_in_detail" placeholder="enter the actual complaint including all relavent information">
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-person_making_complaint" class="col-sm-2 col-form-label">Person making complaint:</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="input-complaint-person_making_complaint" [(ngModel)]="complaint.person_making_complaint" placeholder="">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-phone_number" class="col-sm-2 col-form-label">Phone number (complaint):</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="input-complaint-phone_number" [(ngModel)]="complaint.phone_number" placeholder="enter a phone number for the person making the complaint">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-email_address" class="col-sm-2 col-form-label">Email address (complaint):</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="input-complaint-email_address" [(ngModel)]="complaint.email_address" placeholder="enter a email address for the person making the complaint">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-lodged_by" class="col-sm-2 col-form-label">Lodged by:</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="input-complaint-lodged_by" [(ngModel)]="complaint.lodged_by" placeholder="">
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-method_complaint_received" class="col-sm-2 col-form-label">Method Complaint received:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-method_complaint_received" [(ngModel)]="complaint.method_complaint_received">
      <option value="In person">In person</option>
      <option value="Via Email">Via Email</option>
    </select>
  </div>
</div>

<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">When Complaint received</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="when_complaint_received" id="gridRadios1" value="Not applicable" [(ngModel)]="complaint.when_complaint_received">
        <label class="form-check-label" for="gridRadios1">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="when_complaint_received" id="gridRadios2" value="Date & Time" [(ngModel)]="complaint.when_complaint_received">
        <label class="form-check-label" for="gridRadios2">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="complaint.when_complaint_received_datetime" [disabled]="complaint.when_complaint_received == 'Not applicable'" placeholder="insert date & time" name="when_complaint_received" />
    </div>
  </div>
</fieldset>

<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">When Complaint acknowledged</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="when_complaint_ack" id="gridRadios3" value="Not applicable" [(ngModel)]="complaint.when_complaint_ack">
        <label class="form-check-label" for="gridRadios3">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="when_complaint_ack" id="gridRadios4" value="Date & Time" [(ngModel)]="complaint.when_complaint_ack">
        <label class="form-check-label" for="gridRadios4">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="complaint.when_complaint_ack_datetime" [disabled]="complaint.when_complaint_ack == 'Not applicable'" placeholder="insert date & time" name="when_complaint_ack" />
    </div>
  </div>
</fieldset>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Attachments</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-helpful" class="col-sm-2 col-form-label">Helpful material:</label>
  <!--<div class="col-sm-9">
    <input type="file" #file placeholder="Choose file" (change)="file.click(); uploadFile(file.files)" style="display: none;">
    <button type="button" class="btn btn-success" (click)="file.click()">Upload File</button>-->
  <!--<input type="file" class="form-control" [(ngModel)]="complaint.attachment_id" (click)="uploadFile(file.files)" id="input-complaint-helpfull" placeholder="upload any photos, evidence or material that will elaborate on this Complaint">-->
  <!--</div>-->

  <div class="col-sm-9">
    <input type="file" #file class="form-control" (change)="onFileChange($event)" [(ngModel)]="complaint.attachment_id" id="input-complaint-helpful" placeholder="upload any photos, evidence or material that will elaborate on this Complaint" />
    {{complaint.complaint_attachment.Name}}
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray" [disabled]="complaint.attachment_id == null" (click)="downloadAttachment()">Download</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray" (click)="uploadFile()">Upload</button></div>
</div>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Actions</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-status" class="col-sm-2 col-form-label">Status:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-status" [(ngModel)]="complaint.status">
      <option value="New">New</option>
      <option value="In progress">In progress</option>
      <option value="Completed">Completed</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-priority" class="col-sm-2 col-form-label">Priority:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-priority" [(ngModel)]="complaint.priority">
      <option value="No priority">No priority</option>
      <option value="High priority">High priority</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-position" class="col-sm-2 col-form-label">Position responsible:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-complaint-position" [(ngModel)]="complaint.position_responsible">
      <option value="Team Leader">Team Leader</option>
      <option value="Operations Manager">Operations Manager</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-external-parties" class="col-sm-2 col-form-label">External parties notified:</label>
  <div class="col-sm-9">
    <textarea type="text" [(ngModel)]="complaint.exrernal_parties_notified" class="form-control" id="input-complaint-external-parties" placeholder="enter the angency/person notified(i.e. commission police, NOK / Representative / Gaurdian /EPOA, Advocate etc), submission format or recipient name, contact details,date and time of notification"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-outcome-external-parties" class="col-sm-2 col-form-label">Outcome from External parties:</label>
  <div class="col-sm-9">
    <textarea type="text" [(ngModel)]="complaint.outcome_from_external_parties" class="form-control" id="input-complaint-outcome-external-parties" placeholder="enter any feedback,reqirements or decisions given by external party"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-investigation" class="col-sm-2 col-form-label">Investigation process:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="complaint.investigation_process" class="form-control" id="input-complaint-investigation" placeholder="enter details of investigation steps including date,people involved, meetings or consultations">
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Open</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->
<div class="form-group row p-2">
  <label for="input-complaint-investigation-outcome" class="col-sm-2 col-form-label">Investigation outcome:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="complaint.investigation_outcome" class="form-control" id="input-complaint-investigation-outcome" placeholder="enter investigation findings,cause, or improvements identified">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-complaint-actions-taken" class="col-sm-2 col-form-label">Actions taken:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="complaint.actions_taken" class="form-control" id="input-complaint-actions-taken" placeholder="enter details of actions taken including date,people involved, meetings or consultations">
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Open</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->
<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">Resolve by:</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_resolve_by" id="gridRadios7" value="Not applicable" [(ngModel)]="complaint.is_resolve_by">
        <label class="form-check-label" for="gridRadios7">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_resolve_by" id="gridRadios8" value="Date & Time" [(ngModel)]="complaint.is_resolve_by">
        <label class="form-check-label" for="gridRadios8">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="complaint.resolve_by_datetime" [disabled]="complaint.is_resolve_by == 'Not applicable'" placeholder="insert date & time">
    </div>
  </div>
</fieldset>
<div class="form-group row p-2">
  <label for="input-complaint-relatedto" class="col-sm-2 col-form-label">Related to:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.related_to" id="input-complaint-relatedto" placeholder="add any related items here">
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="form-group row p-2">
  <label for="input-complaint-improvements" class="col-sm-2 col-form-label">Improvements:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.improvements" id="input-complaint-improvements" placeholder="add any improvements that have been created to address this complaint">
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="form-group row p-2">
  <label for="input-complaint-external-identifier" class="col-sm-2 col-form-label">External identifier:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.external_identifier" id="input-complaint-external-identifier" placeholder="reference number or ID, say for external complaint or management systems">
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-relavent-policy" class="col-sm-2 col-form-label">Relavent Policy/Regulation:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.relavent_policy_regulation" id="input-complaint-relavent-policy" placeholder="add any policies or regulations that are relevant to this complaint">
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Resolution</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>

<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">Closed on:</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_closed_on" id="gridRadios9" value="Not applicable" [(ngModel)]="complaint.is_closed_on">
        <label class="form-check-label" for="gridRadios9">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_closed_on" id="gridRadios10" value="Date & Time" [(ngModel)]="complaint.is_closed_on">
        <label class="form-check-label" for="gridRadios10">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="complaint.closed_on_datetime" [disabled]="complaint.is_closed_on == 'Not applicable'" placeholder="insert date & time">
    </div>
  </div>
</fieldset>
<div class="form-group row p-2">
  <label for="input-complaint-closing-comments" class="col-sm-2 col-form-label">Closing comments:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.closing_comments" id="input-complaint-closing-comments" placeholder="enter notes about the resolution of this complaint">
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-satisfactory" class="col-sm-2 col-form-label">Satisfactory resolution:</label>
  <label class="col-sm-4 col-form-label">Was complaint resolved to the satisfaction of all parties? </label>
  <div class="dropdown col-sm-5">
    <select class="form-select selectTag" id="input-complaint-satisfactory" [(ngModel)]="complaint.satisfactory_resolution">
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-complaint-resolution-approvedby" class="col-sm-2 col-form-label">Resolution approved by:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="complaint.resolution_approved_by" id="input-complaint-resolution-approvedby" placeholder="">
  </div>
</div>


<!--<div class="form-group row p-2">
  <div class="col-sm-9">
    <button type="submit" class="btn btn-primary">Sign in</button>
  </div>
</div>-->
