import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
  
@Injectable(
  {
    providedIn: 'root'
  }
)
export class AzureAdService {
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasAdminAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasTherapistAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
}
