<body>
  <iframe frameborder="0" style="height: 1170px; width: 100%; border: none; "
           [src]="iframeURI"></iframe>
</body>

<!--<div class="container tb-10"></div>
<h4>Restrictive Practices/Environment Assessment</h4>
<form class="col-md-6" (ngSubmit)="submit()">
  <div *ngIf="step == 1">
    <h4>Introduction to the Assessment</h4>
    <span>Just so you know the purpose of this assessment is to get an idea of the steps that you have to take to reduce the chances of behaviours of concern and possibly what you might have to do to ensure the safety of yourself and (Participant) </span>

    <h4>Participant Information</h4>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="first_name">Firstname<em class="important">*</em></label>
        <input type="text" class="form-control" id="first_name" placeholder="Firstname">
      </div>
      <div class="form-group col-md-6">
        <label for="last_name">Lastname<em class="important">*</em></label>
        <input type="text" class="form-control" id="last_name" placeholder="Lastname">
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="dbo">DOB</label>
      <input type="date" class="form-control" id="dbo">
    </div>
    <div class="form-group col-md-6">
      <label for="diagnosis">Diagnosis</label>
      <input type="text" class="form-control" id="diagnosis">
    </div>
    <div class="form-group col-md-6">
      <label for="ndis_number">NDIS Number</label>
      <input type="text" class="form-control" id="ndis_number">
    </div>
    <div class="form-group col-md-6">
      <label for="person_being_interviewed">Person being interviewed<em class="important">*</em></label>
      <input type="text" class="form-control" id="person_being_interviewed">
    </div>
    <div class="form-group col-md-6">
      <label for="role">Role<em class="important">*</em></label>
      <input type="text" class="form-control" id="role">
    </div>
  </div>
  <div *ngIf="step == 2">
    <h4>Accommodation/Environment</h4>
    <label class="bold">What environment is being assessed?<em class="important">*</em></label>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check1">
      <label class="form-check-label" for="check1">
        Private residence
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check2">
      <label class="form-check-label" for="check2">
        Supported Independent Living
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check3">
      <label class="form-check-label" for="check3">
        Specialist Disability Accommodation
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check4">
      <label class="form-check-label" for="check4">
        Short Term Accommodation/Respite
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check5">
      <label class="form-check-label" for="check5">
        Day Program
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="check6">
      <label class="form-check-label" for="check6">
        Aged-Care Accommodation
      </label>
    </div>
    <div class="form-group col-md-6">
      <label for="address">Address<em class="important">*</em></label>
      <input type="text" class="form-control" id="address">
    </div>
    <div class="form-group col-md-6">
      <label for="city">City</label>
      <input type="text" class="form-control" id="city">
    </div>
    <div class="form-group col-md-6">
      <label for="state">State</label>
      <input type="text" class="form-control" id="state">
    </div>
  </div>
  <button type="submit" class="btn btn-primary" *ngIf="step != 1" (click)="back()">BACK</button>
  <button type="submit" class="btn btn-primary">NEXT</button>
</form>-->
