import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ListBoxComponent } from 'smart-webcomponents-angular/listbox';
import { WindowComponent } from 'smart-webcomponents-angular/window';
import { AzureAdService } from '../azure-ad.service';
import { ClientService } from '../client.service';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit, AfterViewInit {

  @ViewChild('listbox', { read: ListBoxComponent, static: false }) listbox!: ListBoxComponent;
  @ViewChild('formWindow', { read: WindowComponent, static: false }) formWindow: WindowComponent;
  profile: any;
  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) { }

  dataSource = ["Select"];
  selectedClient: any;
  clientDetails: any;

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });
    this.profile = this.clientService.getProfile();
  }

  ngAfterViewInit(): void {
    const that = this;
    that.listbox.displayLoadingIndicator = true;
    this.httpService.get(`api/v1/clients/get/client-from-db/${this.profile?.mail}/${this.hasAdminAccess}`).subscribe(function (dataRows) {
      const result = [];
      that.clientDetails = dataRows;
      for (var i = 0; i < dataRows.length; i++) {
        let obj = {
          "label": `${dataRows[i].title} ${dataRows[i].firstName} ${dataRows[i].lastName}`,
          "value": `${dataRows[i].clientId}`
        }
        result.push(obj);
      }
      that.dataSource = result;
      that.listbox.displayLoadingIndicator = false;
    });

    that.listbox.addEventListener('change', (event: CustomEvent) => {
      this.selectedClient = event.detail;
      let filteredClientDetails = this.clientDetails.find(
        (element) => element.clientId == event.detail.value
        )
      this.clientService.setClientDetails(filteredClientDetails)
      console.log(event.detail);
      this.formWindow.open();
    })
  }

  onWindowReady(event: any): void {

  }

  searchFormName() {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('searchbox');
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}
