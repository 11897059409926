<div *ngIf="newsList.length > 0">
  <label class="fs-4" *ngIf="isUserLoggedIn">Latest News</label> 
  <button type="button" class="btn btn-labeled float-right" [routerLink]="['/news']" *ngIf="isUserLoggedIn && hasAdminAccess">
    <span class="btn-label"><i class="fa fa-plus" aria-hidden="true"></i></span>
    Add News/Blogs
  </button>

  <div class="col-12 text-center" *ngIf="isUserLoggedIn">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel"> 
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" *ngFor="let news of newsList; let j = index"
          [attr.data-bs-slide-to]="j" [ngClass]="j == 0? 'active': ''" aria-current="true" [attr.aria-label]="'Slide-' + j + 1"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item" [class.active]="i == 0" *ngFor="let news of newsList; let i = index" data-bs-interval="5000">
          <img src="data:image/png;base64,{{news.news_image?.File}}" class="d-block w-100" alt="..." onerror="this.src='./../assets/Images/main-banner-small.png'" >
          <div class="carousel-caption d-none d-md-block">
            <h3><i><a (click)="newsDetailsClick(news)">{{news.summary}}</a></i></h3>
          </div>
        </div>
        
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    </div>  
</div>


<!-- <div class="full-home-content"> -->
  <section class="container-fluid Main_banner_npbs" *ngIf="!isUserLoggedIn || newsList.length == 0" style="background: url(../../assets/Images/main-banner.jpg)no-repeat center center/cover;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 *ngIf="!isUserLoggedIn">Please sign-in to see your profile information.</h1>
          <h1 *ngIf="isUserLoggedIn">Welcome to National Positive Behaviour Support!</h1>
          
        </div>
      </div>
    </div>
  </section>
  <br *ngIf="isUserLoggedIn"/>

  <div>
    <iframe name="embed-feed" title="Viva Engage" src="https://web.yammer.com/embed/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3MzM2NDE5MzI4MCJ9?header=false&footer=false&theme=light&includeFeedInformation=true" style="border: 0px; overflow: hidden; width: 100%; height: 100%; min-height: 550px;"></iframe>
  </div>

  <br *ngIf="isUserLoggedIn"/>
  <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="isUserLoggedIn">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="external-tab" data-bs-toggle="tab" data-bs-target="#external" type="button" role="tab" aria-controls="external" aria-selected="true">External Events</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">Internal Events</button>
    </li>
    
  </ul>
  <div class="tab-content" id="myTabContent" *ngIf="isUserLoggedIn">
    <div class="tab-pane fade show active" id="external" role="tabpanel" aria-labelledby="external">
      <div class="col-sm-10">
        <iframe frameborder="0" class="iframe-style" height='500px'
        [src]="external_event_iframe_url | safe"></iframe>
      </div>
    </div>
    <div class="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
      <div class="col-sm-10">
        <iframe frameborder="0" class="iframe-style" height='500px'
        [src]="internal_event_iframe_url | safe"></iframe>
      </div>
    </div>
  </div>

  <br *ngIf="isUserLoggedIn"/>
  <hr *ngIf="isUserLoggedIn">
  <h4 *ngIf="isUserLoggedIn">Quick Links</h4>
  <div style="margin-top: 20px; margin-bottom: 20px;" *ngIf="isUserLoggedIn">
    <div class="row gx-5">
      <div class="col-sm-3">
        <a href="https://www.microsoft365.com" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/microsoft_icon.png" width="25%"/><label style="padding-left: 25px;">Microsoft 365</label></a>
      </div>
      <div class="col-sm-3">
        <a href="https://login.brighthr.com" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/bright_hr_icon.png" width="25%"/><label style="padding-left: 25px;">Bright HR</label></a>
      </div>
      <div class="col-sm-3">
        <a href="https://go.coreplus.com.au" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/coreplus_icon.png" width="25%"/><label style="padding-left: 25px;">Coreplus</label></a>
      </div>
      <div class="col-sm-3">
        <a href="https://outlook.office.com/mail" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/outlook_icon.png" width="25%"/><label style="padding-left: 25px;">Outlook Web</label></a>
      </div>
    </div>
  </div>
  <div style="margin-top: 75px; margin-bottom: 20px;" *ngIf="isUserLoggedIn">
    <div class="row gx-5">
      <div class="col-sm-3">
        <a href="https://security.microsoft.com/quarantine" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/quarantine_email.png" width="25%"/><label style="padding-left: 7px;">Quarantine Email</label></a>
      </div>
      <div class="col-sm-3">
        <a href="https://helpdesk.nationalpbs.com.au" class="padding2 border bg-light text-center shadow font-size" target=”_blank”><img src="../../assets/Images/it_help.png" width="25%"/><label style="padding-left: 20px;">IT Help Desk</label></a>
      </div>
    </div>
  </div>

  <br *ngIf="isUserLoggedIn"/>
  <h4 *ngIf="isUserLoggedIn">Internal Communication Forms</h4>
  <div style="margin-bottom: 20px;" *ngIf="isUserLoggedIn">
    <div class="row gx-6">
      <div class="col-sm-2">
       <div class="padding border bg-light text-center shadow"><a href="https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/COVID19/formperma/0vBPOaXA6MwMOwMJ43q9TJX8Ynp6e5X6u5EGzmXSTTw" target=”_blank”>COVID-19 Checklist</a></div>
      </div>
      <div class="col-sm-2">
        <div class="padding border bg-light text-center shadow"><a href="https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/Generalform/formperma/MVZ6xYuw9ZvvqQ3L55f48yqVgQb7ypapt23sOtNGOoc" target=”_blank”>General Form</a></div>
      </div>
    </div>
  </div>

  <footer class="container-fluid footer_npbs">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="social_footer">
            <a href="http://fb.com/nationalpbs"><img src="../../assets/Images/facebook-npbs.png" /></a>
            <a href="http://instagram.com/nationalpbs"><img src="../../assets/Images/.browserslistrc.png" /></a>
            <a href="http://linkedin.com/company/national-positive-behaviour-support"><img src="../../assets/Images/linkedin-npbs.png" /></a>
          </div>
          <p>National Positive Behaviour Support 2023</p>
        </div>
      </div>
    </div>
  </footer>
<!-- </div> -->
<!--<p *ngIf="isUserLoggedIn">Lorem Ipsum has been the industry's standard dummy</p>-->