import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { PersonComponent } from './person/person.component';
import { environment } from '../environments/environment';
import { Configuration } from 'msal';
import { ProfileComponent } from './profile/profile.component';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { map } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AzureAdService } from './azure-ad.service';
import { RestrictivePracticesEnvironmentAssessmentComponent } from './restrictive-practices-environment-assessment/restrictive-practices-environment-assessment.component';
import { RiskAssessmentManualComponent } from './risk-assessment-manual/risk-assessment-manual.component';
import { RiskAssessmentComponent } from './risk-assessment/risk-assessment.component';
import { SafetyCheckComponent } from './safety-check/safety-check.component';
import { InitialScreeningAndPrepComponent } from './initial-screening-and-prep/initial-screening-and-prep.component';
import { NdisOccupationalThearapyInitialAssessmentComponent } from './ndis-occupational-thearapy-initial-assessment/ndis-occupational-thearapy-initial-assessment.component';
import { PaediatricInitialAssessmentComponent } from './paediatric-initial-assessment/paediatric-initial-assessment.component';
import { RiskAssessmentManualListComponent } from './risk-assessment-manual-list/risk-assessment-manual-list.component';
import { TableModule } from 'smart-webcomponents-angular/table';
import { ButtonModule } from 'smart-webcomponents-angular/button';
import { ListBoxModule } from 'smart-webcomponents-angular/listbox';
import { WindowModule } from 'smart-webcomponents-angular/window';
import { RiskAssessmentListComponent } from './risk-assessment-list/risk-assessment-list.component';
import { SafetyCheckListComponent } from './safety-check-list/safety-check-list.component';
import { InitialScreeningAndPrepListComponent } from './initial-screening-and-prep-list/initial-screening-and-prep-list.component';
import { RestrictivePracticesEnvironmentAssessmentListComponent } from './restrictive-practices-environment-assessment-list/restrictive-practices-environment-assessment-list.component';
import { NdisOccupationalThearapyInitialAssessmentListComponent } from './ndis-occupational-thearapy-initial-assessment-list/ndis-occupational-thearapy-initial-assessment-list.component';
import { PaediatricInitialAssessmentListComponent } from './paediatric-initial-assessment-list/paediatric-initial-assessment-list.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ClientListComponent } from './client-list/client-list.component';
import { RoleGaurd } from './role.guard';
import { WhodasChildComponent } from './whodas-child/whodas-child.component';
import { WhodasChildListComponent } from './whodas-child-list/whodas-child-list.component';
import { StressThermometerToolComponent } from './stress-thermometer-tool/stress-thermometer-tool.component';
import { StressThermometerToolListComponent } from './stress-thermometer-tool-list/stress-thermometer-tool-list.component';
import { InterimBehaviourSupportComponent } from './interim-behaviour-support/interim-behaviour-support.component';
import { SafePipe } from './safe.pipe';
import { InterimBehaviourSupportListComponent } from './interim-behaviour-support-list/interim-behaviour-support-list.component';
import { InformationGatheringOnsiteOrZoomComponent } from './information-gathering-onsite-or-zoom/information-gathering-onsite-or-zoom.component';
import { InformationGatheringOnsiteOrZoomListComponent } from './information-gathering-onsite-or-zoom-list/information-gathering-onsite-or-zoom-list.component';
import { PurposeOfMedicationComponent } from './purpose-of-medication/purpose-of-medication.component';
import { PurposeOfMedicationListComponent } from './purpose-of-medication-list/purpose-of-medication-list.component';
import { ComprehensiveBehaviourSupportComponent } from './comprehensive-behaviour-support/comprehensive-behaviour-support.component';
import { ComprehensiveBehaviourSupportListComponent } from './comprehensive-behaviour-support-list/comprehensive-behaviour-support-list.component';
import { RestrictivePracticesPhysicalRestraintProtocolComponent } from './restrictive-practices-physical-restraint-protocol/restrictive-practices-physical-restraint-protocol.component';
import { RestrictivePracticesPhysicalRestraintProtocolListComponent } from './restrictive-practices-physical-restraint-protocol-list/restrictive-practices-physical-restraint-protocol-list.component';
import { WhoqolBrefLinkAndPaperComponent } from './whoqol-bref-link-and-paper/whoqol-bref-link-and-paper.component';
import { WhoqolBrefLinkAndPaperListComponent } from './whoqol-bref-link-and-paper-list/whoqol-bref-link-and-paper-list.component';
import { FadeOutPlanComponent } from './fade-out-plan/fade-out-plan.component';
import { FadeOutPlanListComponent } from './fade-out-plan-list/fade-out-plan-list.component';
import { NewFbaComponent } from './new-fba/new-fba.component';
import { NewFbaListComponent } from './new-fba-list/new-fba-list.component';
import { FaiComponent } from './fai/fai.component';
import { FaiListComponent } from './fai-list/fai-list.component';
import { ObservationChartComponent } from './observation-chart/observation-chart.component';
import { ObservationChartListComponent } from './observation-chart-list/observation-chart-list.component';
import { BehaviourEscalationFrequencyComponent } from './behaviour-escalation-frequency/behaviour-escalation-frequency.component';
import { BehaviourEscalationFrequencyListComponent } from './behaviour-escalation-frequency-list/behaviour-escalation-frequency-list.component';
import { StructuredAbcDataSheetComponent } from './structured-abc-data-sheet/structured-abc-data-sheet.component';
import { StructuredAbcDataSheetListComponent } from './structured-abc-data-sheet-list/structured-abc-data-sheet-list.component';
import { JustificationLetterComponent } from './justification-letter/justification-letter.component';
import { JustificationLetterListComponent } from './justification-letter-list/justification-letter-list.component';
import { LetterOfSupportComponent } from './letter-of-support/letter-of-support.component';
import { LetterOfSupportListComponent } from './letter-of-support-list/letter-of-support-list.component';
import { RecommendationsLetterComponent } from './recommendations-letter/recommendations-letter.component';
import { RecommendationsLetterListComponent } from './recommendations-letter-list/recommendations-letter-list.component';
import { HoursForNewFundingPeriodComponent } from './hours-for-new-funding-period/hours-for-new-funding-period.component';
import { HoursForNewFundingPeriodListComponent } from './hours-for-new-funding-period-list/hours-for-new-funding-period-list.component';
import { BehaviourAssessmentReportComponent } from './behaviour-assessment-report/behaviour-assessment-report.component';
import { BehaviourAssessmentReportListComponent } from './behaviour-assessment-report-list/behaviour-assessment-report-list.component';
import { OutcomeReportComponent } from './outcome-report/outcome-report.component';
import { OutcomeReportLsitComponent } from './outcome-report-list/outcome-report-list.component';
import { EnvironmentalAssessmentReportComponent } from './environmental-assessment-report/environmental-assessment-report.component';
import { EnvironmentalAssessmentReportListComponent } from './environmental-assessment-report-list/environmental-assessment-report-list.component';
import { ConsentToShareInformationComponent } from './consent-to-share-information/consent-to-share-information.component';
import { ConsentToShareInformationListComponent } from './consent-to-share-information-list/consent-to-share-information-list.component';
import { VisualConsentComponent } from './visual-consent/visual-consent.component';
import { VisualConsentListComponent } from './visual-consent-list/visual-consent-list.component';
import { ConsentFormPsychAttachApsCharterComponent } from './consent-form-psych-attach-aps-charter/consent-form-psych-attach-aps-charter.component';
import { ConsentFormPsychAttachApsCharterListComponent } from './consent-form-psych-attach-aps-charter-list/consent-form-psych-attach-aps-charter-list.component';
import { VisualIntroductionComponent } from './visual-introduction/visual-introduction.component';
import { VisualIntroductionListComponent } from './visual-introduction-list/visual-introduction-list.component';
import { VisualConsentPsychologyComponent } from './visual-consent-psychology/visual-consent-psychology.component';
import { VisualConsentPsychologyListComponent } from './visual-consent-psychology-list/visual-consent-psychology-list.component';
import { InitialAnalysisMseComponent } from './initial-analysis-mse/initial-analysis-mse.component';
import { InitialAnalysisMseListComponent } from './initial-analysis-mse-list/initial-analysis-mse-list.component';
import { IntakeAssessmentComponent } from './intake-assessment/intake-assessment.component';
import { IntakeAssessmentListComponent } from './intake-assessment-list/intake-assessment-list.component';
import { IntakeFormSeventeenAndUnderComponent } from './intake-form-seventeen-and-under/intake-form-seventeen-and-under.component';
import { IntakeFormSeventeenAndUnderListComponent } from './intake-form-seventeen-and-under-list/intake-form-seventeen-and-under-list.component';
import { PsychologyOutcomeReportComponent } from './psychology-outcome-report/psychology-outcome-report.component';
import { PsychologyOutcomeReportListComponent } from './psychology-outcome-report-list/psychology-outcome-report-list.component';
import { TreatmentPlanComponent } from './treatment-plan/treatment-plan.component';
import { TreatmentPlanListComponent } from './treatment-plan-list/treatment-plan-list.component';
import { SessionNoteComponent } from './session-note/session-note.component';
import { SessionNoteListComponent } from './session-note-list/session-note-list.component';
import { WhodasChildAndYouthComponent } from './whodas-child-and-youth/whodas-child-and-youth.component';
import { WhodasChildAndYouthListComponent } from './whodas-child-and-youth-list/whodas-child-and-youth-list.component';
import { DisdatComponent } from './disdat/disdat.component';
import { DisdatListComponent } from './disdat-list/disdat-list.component';
import { SuicideAssessmentKitComponent } from './suicide-assessment-kit/suicide-assessment-kit.component';
import { SuicideAssessmentKitListComponent } from './suicide-assessment-kit-list/suicide-assessment-kit-list.component';
import { ApsCharterForClientsComponent } from './aps-charter-for-clients/aps-charter-for-clients.component';
import { ApsCharterForClientsListComponent } from './aps-charter-for-clients-list/aps-charter-for-clients-list.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentListComponent } from './incident-list/incident-list.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { ComplaintListComponent } from './complaint-list/complaint-list.component';
import { DeskBookingsComponent } from './desk-bookings/desk-bookings.component';
import { CarBookingsComponent } from './car-bookings/car-bookings.component';
import { PeerSupportBookingsComponent } from './peer-support-bookings/peer-support-bookings.component';
import { RoomBookingsComponent } from './room-bookings/room-bookings.component';
import { ListOfStrategiesComponent } from './list-of-strategies/list-of-strategies.component';
import { PdDayComponent } from './pd-day/pd-day.component';
import { NewsComponent } from './news/news.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsListForTherapistComponent } from './news-list-for-therapist/news-list-for-therapist.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommunicationFunctionComponent } from './communication-function/communication-function.component';
import { CommunicationFunctionListComponent } from './communication-function-list/communication-function-list.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    PersonComponent,
    ProfileComponent,
    RestrictivePracticesEnvironmentAssessmentComponent,
    RiskAssessmentManualComponent,
    RiskAssessmentComponent,
    SafetyCheckComponent,
    InitialScreeningAndPrepComponent,
    NdisOccupationalThearapyInitialAssessmentComponent,
    PaediatricInitialAssessmentComponent,
    RiskAssessmentManualListComponent,
    RiskAssessmentListComponent,
    SafetyCheckListComponent,
    InitialScreeningAndPrepListComponent,
    RestrictivePracticesEnvironmentAssessmentListComponent,
    NdisOccupationalThearapyInitialAssessmentListComponent,
    PaediatricInitialAssessmentListComponent,
    ClientListComponent,
    WhodasChildComponent,
    WhodasChildListComponent,
    StressThermometerToolComponent,
    StressThermometerToolListComponent,
    InterimBehaviourSupportComponent,
    SafePipe,
    InterimBehaviourSupportListComponent,
    InformationGatheringOnsiteOrZoomComponent,
    InformationGatheringOnsiteOrZoomListComponent,
    PurposeOfMedicationComponent,
    PurposeOfMedicationListComponent,
    ComprehensiveBehaviourSupportComponent,
    ComprehensiveBehaviourSupportListComponent,
    RestrictivePracticesPhysicalRestraintProtocolComponent,
    RestrictivePracticesPhysicalRestraintProtocolListComponent,
    WhoqolBrefLinkAndPaperComponent,
    WhoqolBrefLinkAndPaperListComponent,
    FadeOutPlanComponent,
    FadeOutPlanListComponent,
    NewFbaComponent,
    NewFbaListComponent,
    FaiComponent,
    FaiListComponent,
    ObservationChartComponent,
    ObservationChartListComponent,
    BehaviourEscalationFrequencyComponent,
    BehaviourEscalationFrequencyListComponent,
    StructuredAbcDataSheetComponent,
    StructuredAbcDataSheetListComponent,
    JustificationLetterComponent,
    JustificationLetterListComponent,
    LetterOfSupportComponent,
    LetterOfSupportListComponent,
    RecommendationsLetterComponent,
    RecommendationsLetterListComponent,
    HoursForNewFundingPeriodComponent,
    HoursForNewFundingPeriodListComponent,
    BehaviourAssessmentReportComponent,
    BehaviourAssessmentReportListComponent,
    OutcomeReportComponent,
    OutcomeReportLsitComponent,
    EnvironmentalAssessmentReportComponent,
    EnvironmentalAssessmentReportListComponent,
    ConsentToShareInformationComponent,
    ConsentToShareInformationListComponent,
    VisualConsentComponent,
    VisualConsentListComponent,
    ConsentFormPsychAttachApsCharterComponent,
    ConsentFormPsychAttachApsCharterListComponent,
    VisualIntroductionComponent,
    VisualIntroductionListComponent,
    VisualConsentPsychologyComponent,
    VisualConsentPsychologyListComponent,
    InitialAnalysisMseComponent,
    InitialAnalysisMseListComponent,
    IntakeAssessmentComponent,
    IntakeAssessmentListComponent,
    IntakeFormSeventeenAndUnderComponent,
    IntakeFormSeventeenAndUnderListComponent,
    PsychologyOutcomeReportComponent,
    PsychologyOutcomeReportListComponent,
    TreatmentPlanComponent,
    TreatmentPlanListComponent,
    SessionNoteComponent,
    SessionNoteListComponent,
    WhodasChildAndYouthComponent,
    WhodasChildAndYouthListComponent,
    DisdatComponent,
    DisdatListComponent,
    SuicideAssessmentKitComponent,
    SuicideAssessmentKitListComponent,
    ApsCharterForClientsComponent,
    ApsCharterForClientsListComponent,
    IncidentComponent,
    IncidentListComponent,
    ComplaintComponent,
    ComplaintListComponent,
    DeskBookingsComponent,
    CarBookingsComponent,
    PeerSupportBookingsComponent,
    RoomBookingsComponent,
    ListOfStrategiesComponent,
    PdDayComponent,
    NewsComponent,
    NewsListComponent,
    NewsDetailsComponent,
    NewsListForTherapistComponent,
    CommunicationFunctionComponent,
    CommunicationFunctionListComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    TableModule,
    ListBoxModule,
    ButtonModule,
    WindowModule,
    AppRoutingModule,
    CKEditorModule,
    MsalModule.forRoot(new PublicClientApplication
      (
        {
          auth: {
            clientId: environment.clientId,
            redirectUri: environment.redirectUrl,
            authority: environment.authority,
            postLogoutRedirectUri: environment.postLogoutUrl
          },
          cache:
          {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false,
          }
        }
      ),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['user.read']
        },
        loginFailedRoute: '/login-failed'
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map(
          [
            [environment.resource_map, ['user.read']]
          ]
        )
      }
    )
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    AzureAdService,
    RoleGaurd
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
