<div class="row">
    <div class="col-sm-2 border" style="box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);">
        <div class="margin-top">UNIT 7</div>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/5bff16bb8fba454cae49550c546aff5e@nationalpbs.com.au/0eba4c94de7246d9893b9e540dbac7cf9038354808808853696/calendar.html'">Meeting Room 1</button>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/9233c6a1b4eb4413b16a064af22dbeeb@nationalpbs.com.au/97108d84ae7645b1976c6ea279e1b27411205727619847793680/calendar.html'">Meeting Room 2</button>
        
        <div class="margin-top">UNIT 4</div>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/7743671000d14ebcadbc7450fbd27f77@nationalpbs.com.au/9a06bccf58854675bc4887445dad016c8879579692309694937/calendar.html'">Meeting Room 3</button>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/4bafdd5b17e54969bad4ce7e2887fd7f@nationalpbs.com.au/44b73013ad084869a9d7def6bde023642644417281302626117/calendar.html'">Meeting Room 4</button>
        
        <div class="margin-top">GEELONG OFFICE</div>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/7d8b7ddd7f7f4e788efcb094b14f02a0@nationalpbs.com.au/8fbb69593c1746ec8358d3024b1596223864187378720589285/calendar.html'">Geelong Meeting Room 1</button>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/c24d311159d9475eae7f8db889419c10@nationalpbs.com.au/8e3c45cfdb074cf2bd2717da73ada70711901361487698415073/calendar.html'">Geelong Meeting Room 2</button>

        <div class="margin-top">MALVERN OFFICE</div>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/7ea44c0e08ac466b84a067d286b766cd@nationalpbs.com.au/6de3c80f74a54519be14ec5afa02d80b7851428160397540439/calendar.html'">Calm Oasis</button>
        <button class="btn fontSize border-bottom" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/4f424a7e93704d8ca0f30fa9b01848fa@nationalpbs.com.au/f6e6a788fe804613befb58ad91458ec39011859686155103762/calendar.html'">Thoughtful Tranquility</button>

    </div>
    <div class="col-sm-10">
        <iframe frameborder="0" class="iframe-style" height='700px'
        [src]="iframeURI | safe"></iframe>
    </div>
</div>