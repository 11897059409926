import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pd-day',
  templateUrl: './pd-day.component.html',
  styleUrls: ['./pd-day.component.css']
})

export class PdDayComponent implements OnInit {

  iframeURI: string = "";
  constructor() { }

  ngOnInit(): void {
    this.iframeURI = `https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=9caa49af-dea3-4c6c-be93-abd764dbd901&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create`;
  }
  
  // @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  // isPlay: boolean = false;
  // toggleVideo(event: any) {
  //   this.videoplayer.nativeElement.play();
  // }
}



 