<h4 *ngIf="is_new_form"><b>Add a new Incident</b></h4>
<h4 *ngIf="!is_new_form"><b>Edit Incident</b></h4>
<hr />
<div id="overlay" style="height: 2570px;">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div class="spinner"></div>
  </div>
</div>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Details</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
  <div class="col-sm-1"><button type="button" [routerLink]="['/incident-list']" class="btn btn-gray">Cancel</button></div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-summary" class="col-sm-2 col-form-label required">Incident Summary:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-summary" required [(ngModel)]="incident.summary" placeholder="enter a brief summary of the incident in just a few words">
  </div>
  <div class="col-sm-1">
    <button type="button" class="btn btn-gray" (click)="saveIncident()" *ngIf="is_new_form"
            [disabled]="incident.summary == null || incident.summary == '' || incident.description == null || incident.description == '' || incident.location == null || incident.location == ''">
      Save
    </button>
    <button type="button" class="btn btn-gray" (click)="updateIncident()" *ngIf="!is_new_form"
            [disabled]="incident.summary == null || incident.summary == '' || incident.description == null || incident.description == '' || incident.location == null || incident.location == ''">
      Update
    </button>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-category" class="col-sm-2 col-form-label">Category:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-category" [(ngModel)]="incident.category">
      <option value="Participant incident">Participant incident</option>
      <option value="Provider incident/concern">Provider incident/concern</option>
      <option value="Therapist incident">Therapist incident</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-business" class="col-sm-2 col-form-label">Business Unit:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-business" [(ngModel)]="incident.business_unit">
      <option value="Moonee Ponds">Moonee Ponds</option>
      <option value="Malvern East">Malvern East</option>
      <option value="Geelong">Geelong</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2">Service Recipient</div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check1" [(ngModel)]="incident.is_service_recipient">
      <label class="form-check-label" for="check1">
        Does Incident relate to a person receiving a service from this organization ?
      </label>
    </div>
  </div>
</div>
<div class="form-group row p-2" *ngIf="incident.is_service_recipient">
  <label for="input-indicent-servie-recipient" class="col-sm-2 col-form-label"></label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-servie-recipient" [(ngModel)]="incident.service_recipient_text" placeholder="">
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2">Other(non-recipient)</div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check2" [(ngModel)]="incident.is_other_non_recipient">
      <label class="form-check-label" for="check2">
        Does Incident relate to a person not receiving a service ?
      </label>
    </div>
  </div>
</div>
<div class="form-group row p-2" *ngIf="incident.is_other_non_recipient">
  <label for="input-indicent-other_non-servie-recipient" class="col-sm-2 col-form-label"></label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-other_non-servie-recipient" [(ngModel)]="incident.other_non_recipient_text" placeholder="">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-description" class="col-sm-2 col-form-label required">Description of the Incident:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-description" [(ngModel)]="incident.description" placeholder="enter a description of the incident including all relavent information">
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-location" class="col-sm-2 col-form-label required">Location of the Incident:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-location" [(ngModel)]="incident.location" placeholder="enter a address and any further details about where the Incident occurred">
  </div>
</div>
<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">When Incident occurred</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="Not applicable" [(ngModel)]="incident.is_incident_occurred">
        <label class="form-check-label" for="gridRadios1">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="Date & Time" [(ngModel)]="incident.is_incident_occurred">
        <label class="form-check-label" for="gridRadios2">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="incident.incident_occurred_datetime" [disabled]="incident.is_incident_occurred == 'Not applicable'" placeholder="insert date & time" name="when-incident-occurred" />
    </div>
  </div>
</fieldset>

<div class="form-group row p-2">
  <label for="input-indicent-witnesses" class="col-sm-2 col-form-label">Witnesses:</label>
  <div class="col-sm-9">
    <textarea type="text" class="form-control" [(ngModel)]="incident.winesses" id="input-indicent-witnesses" placeholder="enter the names of any witnesses of this Incident"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-immediate-response" class="col-sm-2 col-form-label">Immediate actions in response:</label>
  <div class="col-sm-9">
    <textarea type="text" class="form-control" [(ngModel)]="incident.immediate_actions_in_response" id="input-indicent-immediate-response" placeholder="enter any actions taken immediately following the Incident"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-severity-rating" class="col-sm-2 col-form-label">Severity rating:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-severity-rating" [(ngModel)]="incident.severity_rating">
      <option value="No further reporting/minor">No further reporting/minor</option>
      <option value="Moderate">Moderate</option>
      <option value="Major">Major</option>
      <option value="Catastrophic">Catastrophic</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-reportedby" class="col-sm-2 col-form-label">Incident reported by:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-reportedby" [(ngModel)]="incident.incident_reported_by" placeholder="">
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check3" [(ngModel)]="incident.is_reported_for_someone_else">
      <label class="form-check-label" for="check3">
        This is being reported on behalf of someone else
      </label>
    </div>
  </div>
</div>
<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">When Incident was reported:</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_incident_reported" id="gridRadios5" value="Not applicable" [(ngModel)]="incident.is_incident_reported">
        <label class="form-check-label" for="gridRadios5">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_incident_reported" id="gridRadios6" value="Date & Time" [(ngModel)]="incident.is_incident_reported">
        <label class="form-check-label" for="gridRadios6">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="incident.incident_reported_datetime" [disabled]="incident.is_incident_reported == 'Not applicable'" placeholder="insert date & time" name="when-incident-occurred" />
    </div>
  </div>
</fieldset>


<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Attachments</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-indicent-helpful" class="col-sm-2 col-form-label">Helpful material:</label>
  <!--<div class="col-sm-9">
    <input type="file" #file placeholder="Choose file" (change)="file.click(); uploadFile(file.files)" style="display: none;">
    <button type="button" class="btn btn-success" (click)="file.click()">Upload File</button>-->
  <!--<input type="file" class="form-control" [(ngModel)]="incident.attachment_id" (click)="uploadFile(file.files)" id="input-indicent-helpfull" placeholder="upload any photos, evidence or material that will elaborate on this Incident">-->
  <!--</div>-->

  <div class="col-sm-9">
    <input type="file" #file class="form-control" (change)="onFileChange($event)" [(ngModel)]="incident.attachment_id" id="input-indicent-helpful" placeholder="upload any photos, evidence or material that will elaborate on this Incident" />
    {{incident.incident_attachment.Name}}
  </div>
</div>
<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray" [disabled]="incident.attachment_id == null" (click)="downloadAttachment()">Download</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray" (click)="uploadFile()">Upload</button></div>
</div>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Actions</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-status" class="col-sm-2 col-form-label">Status:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-status" [(ngModel)]="incident.status">
      <option value="New">New</option>
      <option value="In progress">In progress</option>
      <option value="Completed">Completed</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-position" class="col-sm-2 col-form-label">Position responsible:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-position" [(ngModel)]="incident.position_responsible">
      <option value="Team Leader">Team Leader</option>
      <option value="Operations Manager">Operations Manager</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-report" class="col-sm-2 col-form-label">Report/notify:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-report" [(ngModel)]="incident.report_notify">
      <option value="No external reporting required">No external reporting required</option>
      <option value="Internal/External reporting (other)">Internal/External reporting (other)</option>
      <option value="Report to NDIS within 1 week">Report to NDIS within 1 week</option>
      <option value="Report to NDIS within 24 hours">Report to NDIS within 24 hours</option>
    </select>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-external-parties" class="col-sm-2 col-form-label">External parties notified:</label>
  <div class="col-sm-9">
    <textarea type="text" [(ngModel)]="incident.exrernal_parties_notified" class="form-control" id="input-indicent-external-parties" placeholder="enter the angency/person notified(i.e. commission police, NOK / Representative / Gaurdian /EPOA, Advocate etc), submission format or recipient name, contact details,date and time of notification"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-outcome-external-parties" class="col-sm-2 col-form-label">Outcome from External parties:</label>
  <div class="col-sm-9">
    <textarea type="text" [(ngModel)]="incident.outcome_from_external_parties" class="form-control" id="input-indicent-outcome-external-parties" placeholder="enter any feedback,reqirements or decisions given by external party"></textarea>
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-investigation" class="col-sm-2 col-form-label">Investigation process:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="incident.investigation_process" class="form-control" id="input-indicent-investigation" placeholder="enter details of investigation steps including date,people involved, meetings or consultations" >
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Open</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->
<div class="form-group row p-2">
  <label for="input-indicent-investigation-outcome" class="col-sm-2 col-form-label">Investigation outcome:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="incident.investigation_outcome" class="form-control" id="input-indicent-investigation-outcome" placeholder="enter investigation findings,cause, or improvements identified" >
  </div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-actions-taken" class="col-sm-2 col-form-label">Actions taken:</label>
  <div class="col-sm-9">
    <input type="text" [(ngModel)]="incident.actions_taken" class="form-control" id="input-indicent-actions-taken" placeholder="enter details of actions taken including date,people involved, meetings or consultations" >
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Open</button></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->
<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">Resolve by:</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_resolve_by" id="gridRadios7" value="Not applicable" [(ngModel)]="incident.is_resolve_by">
        <label class="form-check-label" for="gridRadios7">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_resolve_by" id="gridRadios8" value="Date & Time" [(ngModel)]="incident.is_resolve_by">
        <label class="form-check-label" for="gridRadios8">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="incident.resolve_by_datetime" [disabled]="incident.is_resolve_by == 'Not applicable'" placeholder="insert date & time" >
    </div>
  </div>
</fieldset>
<div class="form-group row p-2">
  <label for="input-indicent-relatedto" class="col-sm-2 col-form-label">Related to:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.related_to" id="input-indicent-relatedto" placeholder="add any related items here" >
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="form-group row p-2">
  <label for="input-indicent-improvements" class="col-sm-2 col-form-label">Improvements:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.improvements" id="input-indicent-improvements" placeholder="add any improvements that have been created to address this complaint" >
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="form-group row p-2">
  <label for="input-indicent-external-identifier" class="col-sm-2 col-form-label">External identifier:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.external_identifier" id="input-indicent-external-identifier" placeholder="reference number or ID, say for external complaint or management systems" >
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-indicent-relavent-policy" class="col-sm-2 col-form-label">Relavent Policy/Regulation:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.relavent_policy_regulation" id="input-indicent-relavent-policy" placeholder="add any policies or regulations that are relevant to this complaint" >
  </div>
</div>
<!--<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-1"><button type="button" class="btn btn-gray">Add</button></div>
</div>-->

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">Resolution</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
</div>

<fieldset class="form-group p-2">
  <div class="row">
    <legend class="col-form-label col-sm-2 pt-0">Closed on:</legend>
    <div class="col-sm-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_closed_on" id="gridRadios9" value="Not applicable" [(ngModel)]="incident.is_closed_on">
        <label class="form-check-label" for="gridRadios9">Not applicable</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gridRadios_closed_on" id="gridRadios10" value="Date & Time" [(ngModel)]="incident.is_closed_on">
        <label class="form-check-label" for="gridRadios10">Date & Time:</label>
      </div>
    </div>
    <div class="col-sm-3">
      <input type="date" class="form-control" [(ngModel)]="incident.closed_on_datetime" [disabled]="incident.is_closed_on == 'Not applicable'" placeholder="insert date & time">
    </div>
  </div>
</fieldset>
<div class="form-group row p-2">
  <label for="input-indicent-closing-comments" class="col-sm-2 col-form-label">Closing comments:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.closing_comments" id="input-indicent-closing-comments" placeholder="enter notes about the resolution of this complaint">
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-indicent-satisfactory" class="col-sm-2 col-form-label">Satisfactory resolution:</label>
  <label class="col-sm-4 col-form-label">Was incident resolved to the satisfaction of all parties? </label>
  <div class="dropdown col-sm-5">
    <select class="form-select selectTag" id="input-indicent-satisfactory" [(ngModel)]="incident.satisfactory_resolution">
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-indicent-resolution-approvedby" class="col-sm-2 col-form-label">Resolution approved by:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.resolution_approved_by" id="input-indicent-resolution-approvedby" placeholder="">
  </div>
</div>

<div class="form-group row p-2">
  <div class="col-sm-2">Return to work:</div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check4" [(ngModel)]="incident.is_return_to_work">
      <label class="form-check-label" for="check4">
        Did this incident result in a 'Return to Work Plan'?
      </label>
    </div>
  </div>
</div>

<div class="form-group row p-2">
  <label for="input-indicent-report-on-brightSafe" class="col-sm-2 col-form-label">Report on BrightSafe:</label>
  <div class="dropdown col-sm-9">
    <select class="form-select selectTag" id="input-indicent-report-on-brightSafe" [(ngModel)]="incident.report_to_brightsafe">
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
</div>
<div class="form-group row p-2" *ngIf="incident.report_to_brightsafe == 'Yes'">
  <label for="input-indicent-return-to-work" class="col-sm-2 col-form-label">Return to work:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" [(ngModel)]="incident.return_to_work_text" id="input-indicent-return-to-work" placeholder="Space for details/incident number for reference">
  </div>
</div>


<!--<div class="form-group row p-2">
  <div class="col-sm-9">
    <button type="submit" class="btn btn-primary">Sign in</button>
  </div>
</div>-->
