import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { RedirectClient } from '@azure/msal-browser/dist/internals';
import { env } from 'process';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AzureAdService } from '../azure-ad.service';
import { ClientService } from '../client.service';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  mail?: string,
  jobTitle?: string
};

const GRAPH_ENDPOINT = environment.graph_endpoint;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  isUserLoggedIn = false;
  hasAdminAccess = false;
  hasTherapistAccess = false;
  private readonly _destroy = new Subject<void>();
  profile: ProfileType;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private azureAdService: AzureAdService,
    private http: HttpClient, private clientService: ClientService,
  ) { }

  ngOnInit(): void {

    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    this.azureAdService.hasTherapistAccess.subscribe(
      x => {
        this.hasTherapistAccess = x;
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status == InteractionStatus.None),
        takeUntil(this._destroy)
      )
      .subscribe(x => {
        var accounts = this.authService.instance.getAllAccounts();
        this.isUserLoggedIn = accounts.length > 0;
        this.azureAdService.isUserLoggedIn.next(this.isUserLoggedIn);
        if (accounts.length > 0) {
          this.getProfile();
          localStorage.setItem(environment.tokenlocalAccountId, accounts[0].localAccountId);

          const userRoles = (accounts[0].idTokenClaims as any).roles;
          if (userRoles.includes("Admin")) {
            this.azureAdService.hasAdminAccess.next(true)
          }
          else if (userRoles.includes("Therapist")) {
            this.azureAdService.hasTherapistAccess.next(true)
          }
        }
      })

    if (this.isUserLoggedIn) {
      this.getProfile();
    }
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.clientService.setProfile(profile);
        this.profile = profile;
      });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    }
    else {
      this.authService.loginPopup()
    }
  }

  logout() {
    this.authService.logoutPopup({
      postLogoutRedirectUri: environment.postLogoutUrl
    }).subscribe(x => {
      localStorage.removeItem(environment.tokenlocalAccountId);
      this.profile = null;
    });
    
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  searchFormName() {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('menusearchbox');
    filter = input.value.toUpperCase();
    ul = document.getElementById("menuUL");
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}

