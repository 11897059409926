import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-stress-thermometer-tool-list',
  templateUrl: './stress-thermometer-tool-list.component.html',
  styleUrls: ['./stress-thermometer-tool-list.component.css']
})
export class StressThermometerToolListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Participant Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Interviewee",
      "dataField": "Name1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Relationship to Participant",
      "dataField": "SingleLine10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "I will read out some options for you to see what best suits them",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When they are calm, do they make any noises to let you know they are happy, like laughing?",
      "dataField": "Checkbox12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other calm (vocal)",
      "dataField": "SingleLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What about when they are becoming upset, do they make any noises to let you know they are starting to get upset?",
      "dataField": "Checkbox13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other starting to get upset (vocal)",
      "dataField": "SingleLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "And when they are extremely upset, do they make any noises to let you know they are upset?",
      "dataField": "Checkbox14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other extremely upset (vocal)",
      "dataField": "SingleLine9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "(Therapist only) Do they communicate through speech?",
      "dataField": "Radio1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any words that they use to communicate?",
      "dataField": "Radio2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What words do they use?",
      "dataField": "SingleLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is their speech... (read below options) when they are calm?",
      "dataField": "Checkbox15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What about when they are starting to get upset?",
      "dataField": "Checkbox16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "And when they are extremely upset?",
      "dataField": "Checkbox17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When they are calm, how does their overall facial appearance look?",
      "dataField": "Checkbox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when calm, indicate (facial)",
      "dataField": "MultiLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How about when they are starting to get upset, is there something you notice about their facial appearance look?",
      "dataField": "Checkbox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when starting to get upset, indicate (facial)",
      "dataField": "MultiLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How about when they are really upset, is there something you notice about their facial appearance look?",
      "dataField": "Checkbox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when really upset, indicate (facial)",
      "dataField": "MultiLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Now when they are calm, how does their jaw look?",
      "dataField": "Checkbox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when calm, indicate (jaw)",
      "dataField": "MultiLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How about when they are starting to get upset, how does their jaw look?",
      "dataField": "Checkbox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when getting upset, indicate (jaw)",
      "dataField": "MultiLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How about when they are really upset, how does their jaw look?",
      "dataField": "Checkbox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when really upset, indicate (jaw)",
      "dataField": "MultiLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Now when they are calm, how do their eyes look?",
      "dataField": "Checkbox8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when calm, indicate (jaw)",
      "dataField": "MultiLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What about when they are starting to become upset, how do their eyes look?",
      "dataField": "Checkbox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when getting upset, indicate (eyes)",
      "dataField": "MultiLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What about when they are extremely upset, how do their eyes look?",
      "dataField": "Checkbox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If other when really upset, indicate (eyes)",
      "dataField": "MultiLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When they are calm, how does their skin look?",
      "dataField": "Checkbox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What about when they are starting to get upset, how does their skin look?",
      "dataField": "Checkbox10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "And when they are extremely upset, how does their skin look?",
      "dataField": "Checkbox11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any mannerisms when they are happy, e.g. rocking, dancing?",
      "dataField": "Radio3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What does this look like?",
      "dataField": "SingleLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any mannerisms when they are starting to get overwhelmed?",
      "dataField": "Radio5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What does this look like?",
      "dataField": "SingleLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any mannerisms when they are extremely overwhelmed?",
      "dataField": "Radio6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What does this look like?",
      "dataField": "SingleLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any items that help them to stay calm and happy?",
      "dataField": "Radio4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What item/s?",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any items that you avoid giving when they are starting to get overwhelmed, e.g iPad?",
      "dataField": "Radio7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What item/s?",
      "dataField": "SingleLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any items that you avoid giving when they are extremely overwhelmed, e.g iPad?",
      "dataField": "Radio8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How comfortable are they around other people when they are calm?",
      "dataField": "Checkbox18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How comfortable are they around other people when they are starting to get upset? Do they start avoiding people for example?",
      "dataField": "Checkbox19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How comfortable are they around other people when they are extremely upset? Do they avoid people completely for example?",
      "dataField": "Checkbox20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they sit and stand when calm? I will give you some examples...",
      "dataField": "Checkbox21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "And how do they sit and stand when starting to get overwhelmed?",
      "dataField": "Checkbox22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "And how do they sit and stand when extremely overwhelmed?",
      "dataField": "Checkbox23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any difficulty with breathing when calm?",
      "dataField": "Radio9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their breathing change when starting to get upset?",
      "dataField": "Radio10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their breathing change when extremely overwhelmed?",
      "dataField": "Radio11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they generally sleep well when they are calm?",
      "dataField": "Radio12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their sleep decline or increase when they are starting to feel overwhelmed with their days?",
      "dataField": "Radio13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their sleep decline or increase when they are extremely overwhelmed with their days?",
      "dataField": "Radio14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have regular eating habits and meals when calm?",
      "dataField": "Radio15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their eating habits and meals change when starting to get upset?",
      "dataField": "Radio16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Does their eating habits and meals change when extremley upset?",
      "dataField": "Radio17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there anything else you can think of that they do when calm, getting upset or really upset that we may have missed?",
      "dataField": "Radio18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If yes, what?",
      "dataField": "SingleLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'stress_thermometer_tool');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/stress_thermometer_tool_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name: string',
          'Name1: string',
          'SingleLine10: string',
          'Radio: string',
          'Checkbox12: string',
          'SingleLine7: string',
          'Checkbox13: string',
          'SingleLine8: string',
          'Checkbox14: string',
          'SingleLine9: string',
          'Radio1: string',
          'Radio2: string',
          'SingleLine: string',
          'Checkbox15: string',
          'Checkbox16: string',
          'Checkbox17: string',
          'Checkbox: string',
          'MultiLine: string',
          'Checkbox1: string',
          'MultiLine1: string',
          'Checkbox2: string',
          'MultiLine2: string',
          'Checkbox3: string',
          'MultiLine3: string',
          'Checkbox4: string',
          'MultiLine4: string',
          'Checkbox5: string',
          'MultiLine5: string',
          'Checkbox8: string',
          'MultiLine8: string',
          'Checkbox7: string',
          'MultiLine7: string',
          'Checkbox6: string',
          'MultiLine6: string',
          'Checkbox9: string',
          'Checkbox10: string',
          'Checkbox11: string',
          'Radio3: string',
          'SingleLine1: string',
          'Radio5: string',
          'SingleLine3: string',
          'Radio6: string',
          'SingleLine4: string',
          'Radio4: string',
          'SingleLine2: string',
          'Radio7: string',
          'SingleLine5: string',
          'Radio8: string',
          'Checkbox18: string',
          'Checkbox19: string',
          'Checkbox20: string',
          'Checkbox21: string',
          'Checkbox22: string',
          'Checkbox23: string',
          'Radio9: string',
          'Radio10: string',
          'Radio11: string',
          'Radio12: string',
          'Radio13: string',
          'Radio14: string',
          'Radio15: string',
          'Radio16: string',
          'Radio17: string',
          'Radio18: string',
          'SingleLine6: string',
          'SingleLine11: string',
          'MODIFIED_USER: string',
          'REFERRER_NAME: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'created_by: string',
        ]
      });
    });
  }

}
