import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-bookings',
  templateUrl: './room-bookings.component.html',
  styleUrls: ['./room-bookings.component.css']
})
export class RoomBookingsComponent implements OnInit {
  iframeURI: string = "";
  constructor() { }

  ngOnInit(): void {
    this.iframeURI = `https://outlook.office365.com/owa/calendar/5bff16bb8fba454cae49550c546aff5e@nationalpbs.com.au/0eba4c94de7246d9893b9e540dbac7cf9038354808808853696/calendar.html`;
  }

}
