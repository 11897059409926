import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ClientService } from '../client.service';
import { News, NewsImage } from '../models/news.model';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: News = new News();
  unique_id: any;
  is_new_form: boolean = true;
  file: File = null;
  imageSrc: SafeResourceUrl;
  public Editor = ClassicEditor;

  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router, 
    private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.news.news_image = new NewsImage();
    this.unique_id = this.route.snapshot.paramMap.get('id')

    if (this.unique_id) {
      this.showloader();
      this.is_new_form = false;
      this.httpService.get(`api/v1/news/get/${this.unique_id}`).subscribe(result => {
        this.news = result;
        this.imageSrc = "data:image/png;base64,"+ this.news.news_image.File;
        this.hideloader();
      });
    }
  }

  saveNews() {
    this.showloader();
    this.httpService.post(`api/v1/news/create`, JSON.stringify(this.news)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/news-list`]);
    });
  }

  updateNews() {
    this.news.news_image = null;
    this.showloader();
    this.httpService.patch(`api/v1/news/update/${this.news.unique_id}`, JSON.stringify(this.news)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/news-list`]);
    });
  }

  uploadFile() {
    if (!this.file) {
      return;
    }
    this.showloader();
    //let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);

    this.http.post(`${environment.npbsApiUrl}api/v1/news/upload-image`, formData).subscribe(result => {
      this.hideloader();
      this.news.image_id = parseInt(<string>result);
    });
  };

  onFileChange(event  ) {

    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.target.files[0]));
    }
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }
}
