import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor() { }

  public clientDetails: any
  public formDetails: any
  public profile: any

  getClientDetails(): any {
    return this.clientDetails;
  }

  setClientDetails(clientDetails: any) {
    this.clientDetails = clientDetails;
  }

  getFormDetails(): any {
    return this.formDetails;
  }

  setFormDetails(formDetails: any) {
    this.formDetails = formDetails;
  }

  getProfile(): any {
    return this.profile;
  }

  setProfile(profile: any) {
    this.profile = profile;
  }

  createFileName(pdfDocResp): any {
    const content = pdfDocResp.headers.getAll('Content-Disposition');
    const contentArray = content[0].split('filename*');
    const filename = contentArray.pop().split("'").pop();
    return decodeURIComponent(filename);
  }

  downloadPdf(pdfDocResp) {
    const filename =
      this.createFileName(pdfDocResp) || 'npbs_form.pdf';
    const PDFDataBlob = new Blob([pdfDocResp.body], {
      type: 'application/pdf',
    });
    if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
      window.navigator['msSaveOrOpenBlob'](PDFDataBlob, filename);
      return;
    }
    const url = window.URL.createObjectURL(PDFDataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // FireFox support
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
  }
}
