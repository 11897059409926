import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { Router } from '@angular/router';
import { AzureAdService } from '../azure-ad.service';
import { WindowComponent } from 'smart-webcomponents-angular/window';

@Component({
  selector: 'app-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.css']
})
export class IncidentListComponent implements OnInit {

  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;
  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('formWindow', { read: WindowComponent, static: false }) formWindow: WindowComponent;

  formList: any; hasAdminAccess = false;

  popupHeader: any = "Select";
  popupSubheader: any = "Select";;
  popupOpen: boolean;
  saveParamter: string = '';
  editor: boolean;
  unique_id: any;
  deleteRow: any;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService,
    private router: Router, private changeDetect: ChangeDetectorRef) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      label: 'Download',
      dataField: '',
      width: 155,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button3 id="download" row-id="${settings.data.unique_id}" class="download-row" (click)="downloadForm($event)">Download</smart-button>`;
      }
    },
    {
      label: 'Edit',
      dataField: '',
      width: 100,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button id="edit" row-id="${settings.data.unique_id}" class="edit-row" (click)="editForm($event)">Edit</smart-button>`;
      }
    },
    {
      label: 'Delete',
      dataField: '',
      width: 130,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button2 id="delete" row-index="${settings.data.$.index}" row-id="${settings.data.unique_id}" class="delete-row" (click)="deleteForm($event)">Delete</smart-button>`;
      }
    },
    {
      label: 'Id',
      dataField: 'unique_id',
      dataType: 'number',
      width: 60
    },
    {
      label: 'Incident',
      dataField: 'summary',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Category',
      dataField: 'category',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Business Unit',
      dataField: 'business_unit',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Service Receipient',
      dataField: 'service_recipient_text',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Relavent Policy/Regulation',
      dataField: 'relavent_policy_regulation',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Occurred On',
      dataField: 'incident_occurred_datetime',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Severity',
      dataField: 'severity_rating',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Status',
      dataField: 'status',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  filterRow: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  ngOnInit(): void {
    this.delay(1000).then((any) => {
      const that = this;
      this.httpService.get(`api/v1/incident/get`).subscribe(function (dataRows) {
        that.formList = dataRows;
        that.dataSource = new window.Smart.DataAdapter({
          dataSource: dataRows,
          dataFields: [
            'unique_id: number',
            'summary: string',
            'category: string',
            'business_unit: string',
            'service_recipient_text: string',
            'relavent_policy_regulation: string',
            'incident_occurred_datetime: string',
            'severity_rating: string',
            'status: string',
          ]
        });
      });
    });
  }

  ngAfterViewInit(): void {

    const that = this;
    this.table.addEventListener('click', function (event: Event) {
      that.deleteRow = (<HTMLSpanElement>event.target).closest('.delete-row');
      const editRow = (<HTMLSpanElement>event.target).closest('.edit-row');
      const downloadRow = (<HTMLSpanElement>event.target).closest('.download-row');
        
      if (that.deleteRow) {
        that.unique_id = that.deleteRow.getAttribute('row-id');
        that.formWindow.open();
      }
      else if (editRow) {
        const unique_id = editRow.getAttribute('row-id');
        that.router.navigate([`/incident`, { id: unique_id }]);
      }
      else if (downloadRow) {
        that.showloader();
        const unique_id = downloadRow.getAttribute('row-id');
        that.httpService.getRequestCallPDF(`api/v1/incident/download/${unique_id}`).subscribe(result => {
          that.hideloader();
          that.clientService.downloadPdf(result);
        });
      }
    });

    that.formWindow.addEventListener('click', dialogButtonsHandler);

    function dialogButtonsHandler(event: CustomEvent): void {
      const target: HTMLElement = event.target as HTMLElement;

      if (target.closest('.smart-cancel-button')) {
        that.formWindow.close();
      }
      else if (target.closest('.smart-confirm-button')) {
        that.httpService.delete(`api/v1/incident/delete/${that.unique_id}`).subscribe(function (result) {
          if (result) {
            const rowIndex = that.deleteRow.getAttribute('row-index');
            that.table.nativeElement.removeRow(rowIndex);

            that.formWindow.close();
          }
        });
      }
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }
}
