import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-whodas-child-list',
  templateUrl: './whodas-child-list.component.html',
  styleUrls: ['./whodas-child-list.component.css']
})
export class WhodasChildListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Participant Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Participant DOB",
      "dataField": "Date",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Number",
      "dataField": "Number41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist name",
      "dataField": "Name1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist email",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.1\t\"Concentrating for 10 minutes at a time or more while doing homework, playing a game, or doing something you were asked to do?\"",
      "dataField": "Number",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.2\t\"Remembering to do important things, such as crossing the street safely, taking the right books to school, and remembering to do homework assignments?\"",
      "dataField": "Number1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.3\t\"Finding a way to deal with common, everyday problems that other people your age can manage?\"",
      "dataField": "Number2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.4\t\"Learning how to do something new, for example, how to play a new game, or learning something new at school?\"",
      "dataField": "Number3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.5\tGenerally understanding what people say?",
      "dataField": "Number4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D1.6\t\"Telling your family or friends about things you have done, or people you have met, or places you have been?\"",
      "dataField": "Number5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D2.1\t\"Standing for a reasonable period of time, for example, in PE or school assembly or church/temple?\"",
      "dataField": "Number6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D2.2\tGetting up from a sitting position?",
      "dataField": "Number7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D2.3\tMoving around inside your home?",
      "dataField": "Number8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D2.4\tGetting around at school or at a friend’s?",
      "dataField": "Number9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D2.5\t\"Walking a long distance such as as other people your age can?\"",
      "dataField": "Number10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D3.1\t\"Keeping yourself and your clothes clean, taking baths or showers, and brushing your teeth without being asked?\"",
      "dataField": "Number11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D3.2\tGetting dressed on your own?",
      "dataField": "Number12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D3.3\tEating meals without help?",
      "dataField": "Number13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D3.4\t\"Staying safe when you are alone or not putting him/herself in danger when there are no adults around?\"",
      "dataField": "Number14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D4.1\t\"Getting along with people you do not know well?\"",
      "dataField": "Number15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D4.2\tKeeping a friendship?",
      "dataField": "Number16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D4.3\tGetting along with family members?",
      "dataField": "Number17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D4.4\tMaking new friends?",
      "dataField": "Number18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D4.5\t\"Getting along with your teachers or adults who aren’t in your family?\"",
      "dataField": "Number19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.1\t\"Doing chores or other things you are expected to do at home to help out?\"",
      "dataField": "Number20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.2\t\"Finishing chores or home activities that you are supposed to do?\"",
      "dataField": "Number21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.3\tDoing chores or other home activities well?",
      "dataField": "Number22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.4\t\"Doing these home activities quickly when it is important?\"",
      "dataField": "Number23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.5\tDoing your regular school assignments?",
      "dataField": "Number24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.6\tStudying for important school tests?",
      "dataField": "Number25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.7\t\"Completing all of the school assignments and activities that you needed to do?\"",
      "dataField": "Number26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.8\t\"Getting your school work done as quickly as needed?\"",
      "dataField": "Number27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D5.9\t\"How much difficulty do you have in following rules or fitting in with others at school?\"",
      "dataField": "Number28",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D6.1\t\"Do you have more of a problem joining in on community activities (for example, clubs, religious groups, or after-school activities) than you thought you should?\"",
      "dataField": "Number29",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D6.2\t\"How much do you feel that you are not getting invited to many as parties, play dates, or just hanging out, as you would like?\"",
      "dataField": "Number30",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D6.3\t\"How much time do your parents or other family member spend on your health condition problems you may have?\"",
      "dataField": "Number31",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D6.4\t\"How much have you been upset by his/her health condition?\"",
      "dataField": "Number32",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "D6.5\t\"How much of a problem do you have in doing things by yourself for relaxation or pleasure (do you have any problems keeping yourself busy doing things that you like to do)?\"",
      "dataField": "Number33",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Domain Score",
      "dataField": "Formula5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Overall Socre",
      "dataField": "Formula6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H1\t\"How do you rate your health overall health in the past 30 days?\"",
      "dataField": "SingleLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H2\t\"Overall, how much did these difficulties interfere with your life?\"",
      "dataField": "SingleLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H3\tOverall, in the past 30 days, how many days were these difficulties present?",
      "dataField": "Number36",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H4\t\"n the past 30 days, for how many days were you totally unable to carry out your usual activities or school/work because of any health condition?\"",
      "dataField": "Number37",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H5\t\"In the past 30 days, not counting the days that you were totally unable, for how many days did you cut back or reduce your usual activities or school/work because of any health condition?\"",
      "dataField": "Number38",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H6\t\"In the past 30 days, how many days were you absent from school?\"",
      "dataField": "Number39",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "H7\t\"In the past 30 days, how many days were you late for school?\"",
      "dataField": "Number40",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date of Assessment",
      "dataField": "Date1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Whodas_child');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/whodas_child_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name: string',
          'Date_ISO8601: string',
          'Date: string',
          'Number41: string',
          'Name1: string',
          'Email: string',
          'Number: string',
          'Number1: string',
          'Number2: string',
          'Number3: string',
          'Number4: string',
          'Number5: string',
          'Formula: string',
          'Number6: string',
          'Number7: string',
          'Number8: string',
          'Number9: string',
          'Number10: string',
          'Formula1: string',
          'Number11: string',
          'Number12: string',
          'Number13: string',
          'Number14: string',
          'Formula2: string',
          'Number15: string',
          'Number16: string',
          'Number17: string',
          'Number18: string',
          'Number19: string',
          'Formula3: string',
          'Number20: string',
          'Number21: string',
          'Number22: string',
          'Number23: string',
          'Number24: string',
          'Number25: string',
          'Number26: string',
          'Number27: string',
          'Number28: string',
          'Formula4: string',
          'Number29: string',
          'Number30: string',
          'Number31: string',
          'Number32: string',
          'Number33: string',
          'Formula5: string',
          'Formula6: string',
          'SingleLine: string',
          'SingleLine1: string',
          'Number36: string',
          'Number37: string',
          'Number38: string',
          'Number39: string',
          'Number40: string',
          'Date1_ISO8601: string',
          'Date1: string',
          'SingleLine2: string',
          'REFERRER_NAME: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'MODIFIED_USER: string',
          'created_by: string',
        ]
      });
    });
  }
}
