import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ClientService } from '../client.service';
import { HttpService } from '../http.service';
import { Complaint, ComplaintAttachment } from '../models/incident.model';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {
  complaint: Complaint = new Complaint();
  unique_id: any;
  is_new_form: boolean = true;
  file: File = null;
  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private clientService: ClientService) { }

  ngOnInit(): void {
    this.complaint.complaint_attachment = new ComplaintAttachment();
    this.unique_id = this.route.snapshot.paramMap.get('id')

    if (this.unique_id) {
      this.showloader();
      this.is_new_form = false;
      this.httpService.get(`api/v1/complaint/get/${this.unique_id}`).subscribe(result => {
        this.complaint = result;
        this.hideloader();
      });
    }
    else {
      this.complaint.is_resolve_by = 'Not applicable';
      this.complaint.is_closed_on = 'Not applicable';
      this.complaint.status = 'New';
      this.complaint.position_responsible = 'Valid';
      this.complaint.satisfactory_resolution = 'Not applicable';
      this.complaint.when_complaint_received = 'Not applicable';
      this.complaint.when_complaint_ack = 'Not applicable';
    }
  }

  saveComplaint() {
    this.showloader();
    this.httpService.post(`api/v1/complaint/create`, JSON.stringify(this.complaint)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/complaint-list`]);
    });
  }

  updateComplaint() {
    this.complaint.complaint_attachment = null;
    this.showloader();
    this.httpService.patch(`api/v1/complaint/update/${this.complaint.unique_id}`, JSON.stringify(this.complaint)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/complaint-list`]);
    });
  }

  uploadFile() {
    if (!this.file) {
      return;
    }
    this.showloader();
    //let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);

    this.http.post(`${environment.npbsApiUrl}api/v1/complaint/upload-file`, formData).subscribe(result => {
      this.hideloader();
      this.complaint.attachment_id = parseInt(<string>result);
    });
  };

  onFileChange(event) {
    this.file = event.target.files[0];
  }

  downloadAttachment() {
    this.showloader();
    this.httpService.getRequestCallPDF(`api/v1/complaint/download-attachment/${this.complaint.attachment_id}`).subscribe(result => {
      this.hideloader();
      this.clientService.downloadPdf(result);
    });
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }

}
