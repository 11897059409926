import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-risk-assessment-manual-list',
  templateUrl: './risk-assessment-manual-list.component.html',
  styleUrls: ['./risk-assessment-manual-list.component.css']
})
export class RiskAssessmentManualListComponent implements OnInit {
  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;
  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      label: 'Name of Participant',
      dataField: 'Name of Participant',
      dataType: 'string',
      width: 300
    },
    {
      label: 'NDIS No',
      dataField: 'NDIS No',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Name of practitioner completing this assessment',
      dataField: 'Name of practitioner completing this assessment',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Date',
      dataField: 'Date',
      dataType: 'string',
      width: 150
    },
    {
      label: 'Relationships and sexuality',
      dataField: 'Relationships and sexuality',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Unsafe Lifestyle Choices',
      dataField: 'Unsafe Lifestyle Choices',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Hazard Exposure',
      dataField: 'Hazard Exposure',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Behaviours of Concern',
      dataField: 'Behaviours of Concern',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Environmental and Social',
      dataField: 'Environmental and Social',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Abuse or Neglect',
      dataField: 'Abuse or Neglect',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Eating and Drinking',
      dataField: 'Eating and Drinking',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Medical Conditions',
      dataField: 'Medical Conditions',
      dataType: 'string',
      width: 300,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var object = JSON.parse(settings.value);
          const lastKey = Object.keys(object).pop();
          var result = '';
          for (const [key, value] of Object.entries(object)) {
            if (value && value[0] != '') {
              if (key != lastKey)
                result = result + (`${key} <br> ${value} <hr>`);
              else
                result = result + (`${key} <br> ${value}`);
            }
          }
          settings.template = `<div">${result}</div>`;
        }
      }
    },
    {
      label: 'Referrer Name',
      dataField: 'Referrer Name',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Added Time',
      dataField: 'Added Time',
      dataType: 'string',
      width: 150
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  freezeFooter: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  };

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Risk_assessment_manual');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/risk_assessment_manual_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name of Participant: string',
          'NDIS No: string',
          'Name of practitioner completing this assessment: string',
          'Date_ISO8601: string',
          'Date: string',
          'Relationships and sexuality: string',
          'Unsafe Lifestyle Choices: string',
          'Hazard Exposure: string',
          'Behaviours of Concern: string',
          'Environmental and Social: string',
          'Abuse or Neglect: string',
          'Eating and Drinking: string',
          'Medical Conditions: string',
          'Referrer Name: string',
          'Added Time: string',
          'created_by: string',
        ]
      });
    });
  }
}
