<h3>List of Strategies</h3>
<hr />
<p>1. Regulated Restrictive Practices Authorisation Checklist – a list of the legislative requirements for authorisation of restrictive practices, including information as to how these criteria can be met within a Behaviour Support Plan: <a href="https://www.dffh.vic.gov.au/regulated-restrictive-practices-authorisation-check-list" target="_blank">https://www.dffh.vic.gov.au/regulated-restrictive-practices-authorisation-check-list</a> </p>

<p>2. Positive Practice Framework – a resource that operationalises positive behaviour support and promotes a person-centred approach to responding to people with emerging or presenting behaviours of concern: <a href="https://www.dffh.vic.gov.au/positive-practice-framework-word" target="_blank">https://www.dffh.vic.gov.au/positive-practice-framework-word</a> </p>

<p>3. Directions and Prohibitions issued by the Victorian Senior Practitioner – find all directions and prohibitions regarding use of restrictive practices from the Victorian Senior Practitioner here: <a href="https://www.dffh.vic.gov.au/victorian-senior-practitioners-directions-and-prohibitions" target="_blank">https://www.dffh.vic.gov.au/victorian-senior-practitioners-directions-and-prohibitions</a> </p>

<p>4. Chemical Restraint: Information for APOs – a quick reference guide for chemical restraint, including frequently asked questions:  <a href="https://www.dffh.vic.gov.au/chemical-restraint-information-authorised-program-officers-word" target="_blank">https://www.dffh.vic.gov.au/chemical-restraint-information-authorised-program-officers-word</a> </p>

<p>5. Recognising and Reducing Mechanical Restraint Practice Guide – this resource provides a framework and evidence-based recommendations for identifying, assessing, planning, and implementing mechanical restraint reduction plans: <a href="https://www.dffh.vic.gov.au/recognising-and-reducing-mechanical-restraint-practice-guide-word" target="_blank">https://www.dffh.vic.gov.au/recognising-and-reducing-mechanical-restraint-practice-guide-word</a> </p>

<p>6. NDIS Commission Regulated Restrictive Practice Guide: <a href="https://www.ndiscommission.gov.au/sites/default/files/2022-02/regulated-restrictive-practice-guide-rrp-20200.pdf" target="_blank">https://www.ndiscommission.gov.au/sites/default/files/2022-02/regulated-restrictive-practice-guide-rrp-20200.pdf</a> </p>

<p>7. NDIS Commission Practice Alerts: <a href="https://www.ndiscommission.gov.au/workerresources" target="_blank">https://www.ndiscommission.gov.au/workerresources</a> </p>

<p>8. NDIS Zero Tolerance Framework: <a href="https://www.nds.org.au/resources/all-resources/zero-tolerance" target="_blank">https://www.nds.org.au/resources/all-resources/zero-tolerance</a> </p>