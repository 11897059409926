import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrls: ['./risk-assessment.component.css']
})
export class RiskAssessmentComponent implements OnInit {

  iframeURI: SafeResourceUrl;
  formBaseUrl: string = 'https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/RiskAssessment/formperma/IuyVmGhPeFek1v8xo-y0lmA_77Jtd9GJ9hLuBT6ycv4';
  constructor(private sanitizer: DomSanitizer, private clientService: ClientService) {

  }

  ngOnInit(): void {
    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }
    let clientDetails = this.clientService.getClientDetails();
    if (clientDetails) {
      let url = `${this.formBaseUrl}?first_name=${clientDetails.firstName}&last_name=${clientDetails.lastName}&dob=${clientDetails.dob}&client_id=${clientDetails.clientId}&created_by=${emailId}`;
      url = url.replace(/\[/g, '(').replace(/]/g, ')');
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
      else
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(this.formBaseUrl);
  }

}
