import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';

import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public res: Response;
  private url: string;
  private groupId: number;
  private correlationId: uuidv4;
  constructor(
    public http: HttpClient
  ) {
  }

  public post(
    urlExt: string,
    jsonReq: string,
  ): any {
    this.correlationId = uuidv4();
    const napAccessToken = this.getAccessTokenFromLocalStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
        'Content-type':
          'application/json;odata.metadata=minimal;odata.streaming=true',
        Authorization: 'Bearer ' + napAccessToken,
        correlationId: this.correlationId,
      }),
    };
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.post(this.url, jsonReq, httpOptions).pipe(
      map((res: Response) => {
        return this.handleResponse(res);
      }),
    );
  }

  public put(
    urlExt: string,
    jsonReq: string,
  ): any {
    this.correlationId = uuidv4();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-http': 'true',
        'x-secure': 'true',
      }),
    };
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.put(this.url, jsonReq, httpOptions).pipe(
      map((res: Response) => {
        if (res.status === 200) {
          return this.handleResponse(res);
        }
      }),
    );
  }

  public patch(
    urlExt: string,
    jsonReq: string,
  ): any {
    this.correlationId = uuidv4();
    const napAccessToken = this.getAccessTokenFromLocalStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
        'Content-type':
          'application/json;odata.metadata=minimal;odata.streaming=true',
        Authorization: 'Bearer ' + napAccessToken,
        correlationId: this.correlationId,
      }),
    };
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.patch(this.url, jsonReq, httpOptions).pipe(
      map((res: Response) => {
        return this.handleResponse(res);
      }),
    );
  }

  public delete(urlExt: string): any {
    this.correlationId = uuidv4();
    const napAccessToken = this.getAccessTokenFromLocalStorage();
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + napAccessToken,
      correlationId: this.correlationId,
    });
    headers.append('Content-type', 'application/json');
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.delete(this.url, { headers }).pipe(
      map((res: Response) => {
        return this.handleResponse(res);
      }),
    );
  }

  private handleResponse(res: any): Promise<any> {
    return res;
  }

  get(urlExt: string): any {
    const napAccessToken = this.getAccessTokenFromLocalStorage();
    this.correlationId = uuidv4();
    this.url = environment.npbsApiUrl + urlExt;
    const httpOptions = {
      headers: new HttpHeaders({
        //addAccessToken: 'true',
        Authorization: 'Bearer ' + napAccessToken,
        correlationId: this.correlationId,
      }),
    };
    return this.http.get(this.url, httpOptions);
  }
  postRequestCall(urlExt: string, jsonReq: string): any {
    this.correlationId = uuidv4();
    this.url = environment.npbsApiUrl + urlExt;
    const httpOptions = {
      headers: new HttpHeaders({
        addAccessToken: 'true',
        correlationId: this.correlationId,
      }),
    };

    return this.http.post(this.url, jsonReq, httpOptions);
  }

  postRequestCallWithObserveResponse(
    urlExt: string,
    jsonReq: string,
  ): any {
    this.correlationId = uuidv4();
    this.url = environment.npbsApiUrl + urlExt;
    const headers = new HttpHeaders({
      addAccessToken: 'true',
      correlationId: this.correlationId,
    });

    return this.http.post(this.url, jsonReq, {
      headers: headers,
      observe: 'response',
    });
  }
  postRequestCallPDF(urlExt: string, jsonReq: string): any {
    this.url = environment.npbsApiUrl + urlExt;
    this.correlationId = uuidv4();
    const headers = new HttpHeaders({
      addAccessToken: 'true',
      correlationId: this.correlationId,
    });
    return this.http.post(this.url, jsonReq, {
      headers: headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getRequestCallPDF(urlExt: string): any {
    this.url = environment.npbsApiUrl + urlExt;
    this.correlationId = uuidv4();
    const headers = new HttpHeaders({
      addAccessToken: 'true',
      correlationId: this.correlationId,
    });
    return this.http.get(this.url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getRequestCallWithObserveResponse(urlExt: string) {
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.get(this.url, {
      headers: new HttpHeaders({
        addAccessToken: 'true',
        correlationId: uuidv4(),
      }),
      observe: 'response',
    });
  }
  /* These methods are added for password and Email Expiry flow where correlationId, 
   token is not required and err handling is different.
  */
  public postRequest(
    urlExt: string,
    isGroupRequired: boolean,
    jsonReq: string,
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-type', 'application/json');
    this.url = environment.npbsApiUrl + urlExt;
    if (isGroupRequired) {
      this.url = this.url + 'groupId=' + this.groupId;
    }
    return this.http.post(this.url, jsonReq, { headers }).pipe(
      map((res) => this.handleResponse(res)),
      catchError((res) => this.handleError(res)),
    );
  }
  /* This method is added for password and Email Expiry flow*/
  public getRequest(
    urlExt: string,
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-type', 'application/json');
    this.url = environment.npbsApiUrl + urlExt;
    return this.http.get(this.url, { headers }).pipe(
      map((res: Response) => this.handleResponse(res)),
      catchError((res: Response) => this.handleError(res)),
    );
  }
  /* This method is added for password and Email Expiry flow to show server side err*/
  handleError(error: any): Promise<any> {
    if (error.status === 400) {
      if (
        error.reason_code === '400-999' ||
        String(error.reason_code).startsWith('700')
      ) {
        return Promise.resolve(error);
      } else if (
        error.error.reason_code === '400-999' ||
        String(error.error.reason_code).startsWith('700')
      ) {
        return Promise.resolve(error);
      }
    }
    return Promise.reject(error.message || error);
  }

  public getAccessTokenFromLocalStorage(): string {
    let tokenlocalAccountId = localStorage.getItem(environment.tokenlocalAccountId);
    var key = `${tokenlocalAccountId}.${environment.tenantId}-login.windows.net-idtoken-${environment.clientId}-${environment.tenantId}---`
    var values = JSON.parse(localStorage.getItem(key))?.secret;
    return values;
  }
}
