import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-stress-thermometer-tool',
  templateUrl: './stress-thermometer-tool.component.html',
  styleUrls: ['./stress-thermometer-tool.component.css']
})
export class StressThermometerToolComponent implements OnInit {

  iframeURI: SafeResourceUrl;
  formBaseUrl: string = 'https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/StressThermometerTool/formperma/Rd-kCrqXUbd5O107gwwiJl86NoSOC7g_ciYwwA_PULk';
  constructor(private sanitizer: DomSanitizer, private clientService: ClientService) {

  }

  ngOnInit(): void {
    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }
    let clientDetails = this.clientService.getClientDetails();
    if (clientDetails) {
      let url = `${this.formBaseUrl}?first_name=${clientDetails.firstName}&last_name=${clientDetails.lastName}&client_id=${clientDetails.clientId}&created_by=${emailId}`
      url = url.replace(/\[/g, '(').replace(/]/g, ')');
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
      else
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(this.formBaseUrl);
  }

}
