import { Component, OnInit } from '@angular/core';
import { AzureAdService } from '../azure-ad.service';
import { HttpService } from '../http.service';
import { News } from '../models/news.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  newsList: any;
  hasAdminAccess = false;
  hasTherapistAccess = false;
  external_event_iframe_url = "";
  internal_event_iframe_url = "";

  constructor(private azureAdService: AzureAdService, private httpService: HttpService, private router: Router) { }

  ngOnInit(): void {

    this.external_event_iframe_url = `https://outlook.office365.com/owa/calendar/5d82856139674809a9e6fd3144544f41@nationalpbs.com.au/e568bd956f534ac5bb4063a276eb680e2732462860931251568/calendar.html`;
    this.internal_event_iframe_url = `https://outlook.office365.com/owa/calendar/0fc8a6eae6eb423dbf79654605ae9a94@nationalpbs.com.au/563abc334ced4e709c5ae7f1f760d14014088624566885249756/calendar.html`;

    this.azureAdService.isUserLoggedIn.subscribe(
      x => {
        this.isUserLoggedIn = x;
      });

      this.azureAdService.hasAdminAccess.subscribe(
        x => {
          this.hasAdminAccess = x;
        });
  
      this.azureAdService.hasTherapistAccess.subscribe(
        x => {
          this.hasTherapistAccess = x;
        });
  

      this.httpService.get(`api/v1/news/get/enabled`).subscribe(
        newsResponse => {
          this.newsList = newsResponse;
        });
  }

  newsDetailsClick(news: any) {
    this.router.navigate([`/news-details`, { id: news.unique_id }]);
  }

  
}
