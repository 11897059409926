import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-paediatric-initial-assessment-list',
  templateUrl: './paediatric-initial-assessment-list.component.html',
  styleUrls: ['./paediatric-initial-assessment-list.component.css']
})
export class PaediatricInitialAssessmentListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Personal Alarm/App/Have you checked in?",
      "dataField": "DecisionBox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Name tag",
      "dataField": "DecisionBox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Appropriate footwear",
      "dataField": "DecisionBox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Parking is safe",
      "dataField": "DecisionBox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Gate is easy to open and close, if present",
      "dataField": "DecisionBox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Animal are contained (if present)",
      "dataField": "DecisionBox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Pathways level",
      "dataField": "DecisionBox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Entry and exits clear",
      "dataField": "DecisionBox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Handrails present if needed",
      "dataField": "DecisionBox8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Trip hazards absent",
      "dataField": "DecisionBox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments on Exterior Environment",
      "dataField": "MultiLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Entrances and exits known",
      "dataField": "DecisionBox10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Floor coverings and spills not present",
      "dataField": "DecisionBox11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Trip hazards not present",
      "dataField": "DecisionBox12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Clutter removed",
      "dataField": "DecisionBox13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Electrical cords safe",
      "dataField": "DecisionBox14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Temperature/Humidity Safe",
      "dataField": "DecisionBox15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Lighting adequate",
      "dataField": "DecisionBox16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Furniture appropriateness and of adequate strength if going to be used",
      "dataField": "DecisionBox17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments on Interior Environment",
      "dataField": "MultiLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date of Birth",
      "dataField": "Date",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Contact number",
      "dataField": "PhoneNumber",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Email",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Number",
      "dataField": "Number",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Current Plan Date",
      "dataField": "Date1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How is the Plan Managed?",
      "dataField": "Dropdown",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Long term goals",
      "dataField": "MultiLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Location/Date/Time",
      "dataField": "MultiLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "People Present",
      "dataField": "MultiLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Assessments Completed",
      "dataField": "Checkbox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Assessment Other",
      "dataField": "MultiLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Verbal Consent for initial Assessment",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Vision- (glasses, bifocal)",
      "dataField": "MultiLine9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Hearing- (aids or devices)",
      "dataField": "MultiLine10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Medications",
      "dataField": "MultiLine11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Webster Pack/Tab Timer",
      "dataField": "MultiLine12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Administration assistance?",
      "dataField": "MultiLine13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Storage?",
      "dataField": "MultiLine14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any medical specialists involved?",
      "dataField": "MultiLine15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Self, parent?",
      "dataField": "MultiLine16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns?",
      "dataField": "MultiLine17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Oral, liquid, solid, peg, injection?",
      "dataField": "MultiLine18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Repeat Subscription- how often/how do they get repeats?",
      "dataField": "MultiLine19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other?",
      "dataField": "MultiLine20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognised Disability, Other Conditions",
      "dataField": "MultiLine25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any set routines?",
      "dataField": "MultiLine28",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns?",
      "dataField": "MultiLine29",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any dislikes/preferences? (see sensory section below)",
      "dataField": "MultiLine30",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine31",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine32",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine33",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine34",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine35",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine36",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine37",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine38",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine39",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Washing",
      "dataField": "MultiLine40",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any behaviours of concern to self/others?",
      "dataField": "MultiLine41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: typical age appropriate developmental milestones?",
      "dataField": "MultiLine42",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Mobility",
      "dataField": "MultiLine43",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Transfers",
      "dataField": "MultiLine44",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Upper Limb",
      "dataField": "MultiLine45",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Fine Dexterity",
      "dataField": "MultiLine46",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Lower Limb",
      "dataField": "MultiLine47",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other- Strength, Coordination, ROM, Pain",
      "dataField": "MultiLine48",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: Typical age appropriate developmental milestones?",
      "dataField": "MultiLine49",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Sensation:",
      "dataField": "MultiLine50",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Cognition",
      "dataField": "MultiLine51",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Learning and Education",
      "dataField": "MultiLine52",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: typical age appropriate developmental milestones?",
      "dataField": "MultiLine53",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns relating to Sensory Processing?",
      "dataField": "MultiLine54",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Concerns/potential difficulties?",
      "dataField": "MultiLine55",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Avoidance/Seeking potential behaviours?",
      "dataField": "MultiLine56",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Impact upon function, relationships, participation, goals?",
      "dataField": "MultiLine57",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for further Ax/Treatment?",
      "dataField": "MultiLine58",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: Self-regulation, behaviour and emotional regulation needs?",
      "dataField": "MultiLine59",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Concerns/potential difficulties?",
      "dataField": "MultiLine60",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Impact upon function, relationships, participation, goals?",
      "dataField": "MultiLine61",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for further Ax/Treatment?",
      "dataField": "MultiLine62",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: Typical age-appropriate developmental milestones",
      "dataField": "MultiLine63",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Communication Type",
      "dataField": "MultiLine64",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Communication Needs/Concerns",
      "dataField": "MultiLine65",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any concerns RE: typical age appropriate developmental milestones?",
      "dataField": "MultiLine66",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Access to and from home",
      "dataField": "MultiLine67",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bedroom",
      "dataField": "MultiLine68",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathroom",
      "dataField": "MultiLine69",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Type of home",
      "dataField": "MultiLine70",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Living Area",
      "dataField": "MultiLine71",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Current AT",
      "dataField": "MultiLine72",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other environments relevant",
      "dataField": "MultiLine73",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Limitations observed RE: Environment/AT",
      "dataField": "MultiLine74",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Short Term",
      "dataField": "MultiLine76",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Long Term",
      "dataField": "MultiLine77",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "OT Service Recommendations",
      "dataField": "MultiLine78",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other Allied Health Needs Identified",
      "dataField": "MultiLine79",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist contact details provided (mobile and email), as well as an alternate contact number- Office Mobile- 1300 282 940",
      "dataField": "DecisionBox18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client was provided with a clear action plan and knows when they are to expect next Therapist contact",
      "dataField": "DecisionBox19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Next session booked into Diary",
      "dataField": "DecisionBox20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Cancellation Policy explained",
      "dataField": "DecisionBox21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Travel Charges Discussed",
      "dataField": "DecisionBox22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Service Agreement Signed- (*likely to have prior to initial contact from Support team)",
      "dataField": "DecisionBox23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Their right to choice and provider feedback",
      "dataField": "DecisionBox24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Name",
      "dataField": "Name3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Phone",
      "dataField": "PhoneNumber3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Email",
      "dataField": "Email3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Task Owner",
      "dataField": "RECORD_OWNER",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Alternate Contact',
      dataField: 'SubFormParse',
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].Name1_First},${contacts[i].Name1_Last}</td><td>${contacts[i].SingleLine}</td ><td>${contacts[i].PhoneNumber1}</td ><td>${contacts[i].Email1}</td ></tr>`
          }
          var r = `<table class="table smart-table-container"><tr style="height: auto;"><th>Name</th><th>Relationship</th><th>Phone</th><th>Email</th></tr>${result}</table>`;
          settings.template = `${r}`;
        }
      }
    },
    {
      label: 'Plan Manager detail',
      dataField: 'SubFormParse1',
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].Name2_First},${contacts[i].Name2_Last}</td><td>${contacts[i].SingleLine1}</td ><td>${contacts[i].PhoneNumber2}</td ><td>${contacts[i].Email2}</td ></tr>`
          }
          var ra = `<table class="table smart-table-container"><tr style="height: auto;"><th>Name</th><th>Relationship</th><th>Phone</th><th>Email</th></tr>${result}</table>`;
          settings.template = `${ra}`;
        }
      }
    },
    {
      label: 'Goals',
      dataField: 'SubFormParse2',
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].MultiLine2}</td ></tr>`
          }

          var rat = `<table class="table smart-table-container">${result}</table>`;
          settings.template = `${rat}`;
        }
      }
    },
    {
      label: 'Goals',
      dataField: 'SubFormParse3',
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].MultiLine75}</td ></tr>`
          }

          var rate = `<table class="table smart-table-container">${result}</table>`;
          settings.template = `${rate}`;
        }
      }
    },
    {
      label: 'Steps',
      dataField: 'SubFormParse4',
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].MultiLine80}</td ></tr>`
          }

          var rates = `<table class="table smart-table-container">${result}</table>`;
          settings.template = `${rates}`;
        }
      }
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  };

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Paediatric_initial_assessment');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/paediatric_initial_assessment_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'DecisionBox: string',
          'DecisionBox1: string',
          'DecisionBox2: string',
          'DecisionBox3: string',
          'DecisionBox4: string',
          'DecisionBox5: string',
          'DecisionBox6: string',
          'DecisionBox7: string',
          'DecisionBox8: string',
          'DecisionBox9: string',
          'MultiLine: string',
          'DecisionBox10: string',
          'DecisionBox11: string',
          'DecisionBox12: string',
          'DecisionBox13: string',
          'DecisionBox14: string',
          'DecisionBox15: string',
          'DecisionBox16: string',
          'DecisionBox17: string',
          'MultiLine1: string',
          'Name: string',
          'Date_ISO8601: string',
          'Date: string',
          'PhoneNumber: string',
          'Email: string',
          'Number: string',
          'Date1_ISO8601: string',
          'Date1: string',
          'Dropdown: string',
          'MultiLine3: string',
          'MultiLine4: string',
          'MultiLine5: string',
          'Checkbox: string',
          'MultiLine6: string',
          'Radio: string',
          'MultiLine7: string',
          'MultiLine8: string',
          'MultiLine9: string',
          'MultiLine10: string',
          'MultiLine11: string',
          'MultiLine12: string',
          'MultiLine13: string',
          'MultiLine14: string',
          'MultiLine15: string',
          'MultiLine16: string',
          'MultiLine17: string',
          'MultiLine18: string',
          'MultiLine19: string',
          'MultiLine20: string',
          'MultiLine21: string',
          'MultiLine22: string',
          'MultiLine23: string',
          'MultiLine24: string',
          'MultiLine25: string',
          'MultiLine26: string',
          'MultiLine27: string',
          'MultiLine28: string',
          'MultiLine29: string',
          'MultiLine30: string',
          'MultiLine31: string',
          'MultiLine32: string',
          'MultiLine33: string',
          'MultiLine34: string',
          'MultiLine35: string',
          'MultiLine36: string',
          'MultiLine37: string',
          'MultiLine38: string',
          'MultiLine39: string',
          'MultiLine40: string',
          'MultiLine41: string',
          'MultiLine42: string',
          'MultiLine43: string',
          'MultiLine44: string',
          'MultiLine45: string',
          'MultiLine46: string',
          'MultiLine47: string',
          'MultiLine48: string',
          'MultiLine49: string',
          'MultiLine50: string',
          'MultiLine51: string',
          'MultiLine52: string',
          'MultiLine53: string',
          'MultiLine54: string',
          'MultiLine55: string',
          'MultiLine56: string',
          'MultiLine57: string',
          'MultiLine58: string',
          'MultiLine59: string',
          'MultiLine60: string',
          'MultiLine61: string',
          'MultiLine62: string',
          'MultiLine63: string',
          'MultiLine64: string',
          'MultiLine65: string',
          'MultiLine66: string',
          'MultiLine67: string',
          'MultiLine68: string',
          'MultiLine69: string',
          'MultiLine70: string',
          'MultiLine71: string',
          'MultiLine72: string',
          'MultiLine73: string',
          'MultiLine74: string',
          'MultiLine76: string',
          'MultiLine77: string',
          'MultiLine78: string',
          'MultiLine79: string',
          'DecisionBox18: string',
          'DecisionBox19: string',
          'DecisionBox20: string',
          'DecisionBox21: string',
          'DecisionBox22: string',
          'DecisionBox23: string',
          'DecisionBox24: string',
          'Name3: string',
          'PhoneNumber3: string',
          'Email3: string',
          'SingleLine2: string',
          'REFERRER_NAME: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'MODIFIED_USER: string',
          'SubFormParse: string',
          'SubFormParse1: string',
          'SubFormParse2: string',
          'SubFormParse3: string',
          'SubFormParse4: string',
          'created_by: string',
        ]
      });
    });
  }

}
