<h4 *ngIf="is_new_form"><b>Add a new News/Blogs</b></h4>
<h4 *ngIf="!is_new_form"><b>Edit News/Blogs</b></h4>
<hr />
<div id="overlay" style="height: 2570px;">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div class="spinner"></div>
  </div>
</div>

<div class="p-2 row">
  <label class="col-sm-2 col-form-label overhaed">News/Blogs Details</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <hr width="100%">
    </div>
  </div>
  <div class="col-sm-1"><button type="button" [routerLink]="['/news-list']" class="btn btn-gray">Cancel</button></div>
</div>
<div class="form-group row p-2">
  <label for="input-indicent-summary" class="col-sm-2 col-form-label required">Title:</label>
  <div class="col-sm-9">
    <input type="text" class="form-control" id="input-indicent-summary" required [(ngModel)]="news.summary" placeholder="enter a title of news/blogs">
  </div>
  <div class="col-sm-1">
    <button type="button" class="btn btn-gray" (click)="saveNews()" *ngIf="is_new_form"
            [disabled]="news.summary == null || news.summary == '' || news.description == null || news.description == ''">
      Save
    </button>
    <button type="button" class="btn btn-gray" (click)="updateNews()" *ngIf="!is_new_form"
            [disabled]="news.summary == null || news.summary == '' || news.description == null || news.description == ''">
      Update
    </button>
  </div>
</div>
<div class="form-group row p-2">
    <label for="input-description" class="col-sm-2 col-form-label required">Description:</label>
    <div class="col-sm-9">
      <!-- <textarea type="text" [(ngModel)]="news.description" class="form-control" id="input-idescription" placeholder="enter thedescription of news/blogs"></textarea> -->
      <ckeditor [editor]="Editor" [(ngModel)]="news.description"></ckeditor>
    </div>
    
</div>
<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-9">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="check3" [(ngModel)]="news.enabled">
      <label class="form-check-label" for="check3">
        Display Enabled
      </label>
    </div>
  </div>
</div>

<div class="form-group row p-2">
  <div class="col-sm-2"></div>
  <div class="col-sm-9">
    <p>Note : Please upload image with width: 1346px and heigh: 550px</p>
  </div>
</div>
<div class="form-group row p-2">
    <label for="input-image" class="col-sm-2 col-form-label required">Upload Image:</label>
    <div class="col-sm-5">
      <input type="file" #file class="form-control" (change)="onFileChange($event)" [(ngModel)]="news.image_id" id="input-image" />
      
    </div>
    <div class="col-sm-4">
    </div>
    <div class="col-sm-1">
      
    </div>
    </div>
    
  <div class="form-group row p-2">
    <div class="col-sm-2"></div>
    <div class="col-sm-3">
      <img [src]="imageSrc || '../../assets/Images/image-placeholder.jpeg'" width="300" height="200" alt="your image" />
    </div>
    <div class="col-sm-1">
      <button type="button" class="btn btn-gray" (click)="uploadFile()">Upload</button>
    </div>
  </div>
