import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-safety-check',
  templateUrl: './safety-check.component.html',
  styleUrls: ['./safety-check.component.css']
})
export class SafetyCheckComponent implements OnInit {

  iframeURI: SafeResourceUrl;
  formBaseUrl: string = 'https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/Safetycheck/formperma/Ld9-fd6sW0ZsPnGU70kLxRLSe1tCFA518GJ3C_WOLjg';
  constructor(private sanitizer: DomSanitizer, private clientService: ClientService) {

  }

  ngOnInit(): void {
    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }
    let clientDetails = this.clientService.getClientDetails();
    if (clientDetails) {
      let url = `${this.formBaseUrl}?first_name=${clientDetails.firstName}&last_name=${clientDetails.lastName}&client_id=${clientDetails.clientId}&created_by=${emailId}`;
      url = url.replace(/\[/g, '(').replace(/]/g, ')');
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(this.formBaseUrl);
  }

}
