<h4>PD day</h4>

<!-- <video id="video" src="https://www.dropbox.com/scl/fo/h2d28y7ogn5ew6l0acvwd/h?dl=0&preview=session+1.mp4&rlkey=el7gspj0yvxto6d0otsatedvh" autoplay="autoplay" /> -->

<!-- <div class="video">
    <video controls (click)="toggleVideo()" #videoPlayer class="col-sm-5">
        <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
        Browser not supported
    </video>
    <div class="col-sm-1"></div>
    <video controls (click)="toggleVideo()" #videoPlayer class="col-sm-5">
        <source src="https://nationalpbs-my.sharepoint.com/:v:/g/personal/pranish_nationalpbs_com_au/EWpws6Zr4aFFkx9sZcZs3BYBnGynWKIRUyMBxiGtYnS54Q?e=1Qt1qS" type="video/mp4" />
        Browser not supported
    </video>
</div> -->

<div class="row">
    <div class="col-sm-1 border" style="box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);">
        <button class="btn border-bottom margin" (click)="iframeURI = 'https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=9caa49af-dea3-4c6c-be93-abd764dbd901&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 1</button>
        
        <button class="btn border-bottom margin" (click)="iframeURI = 'https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=aceae305-08ad-45f5-886a-6650ed53b0d7&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 2</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=5464dcea-d831-488b-aac8-b5c3ae72ba1a&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 3</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=161d03b7-89a3-4d7c-844d-177168a586d0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 4</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=0b0e2b6a-6dec-4d78-ae11-d552f279226e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 5</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=b4163fe9-d74c-4030-8d39-ce849a242cd0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 6</button>
    
        <button class="btn border-bottom margin" (click)="iframeURI ='https://nationalpbs.sharepoint.com/sites/NationalPositiveBehaviourSupport/_layouts/15/embed.aspx?UniqueId=81cc3b47-ba09-4ae2-9435-a27674a23aad&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'">Session 7</button>
    </div>
    <div class="col-sm-11">
        <iframe [src]="iframeURI | safe" width="100%" height="500" frameborder="0" scrolling="no" allowfullscreen ></iframe>
    </div>
</div>
