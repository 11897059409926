export class News {
  unique_id: number;
  summary: string;
  description: string;
  image_id: number;
  news_image: NewsImage;
  enabled: boolean;
}

export class NewsImage {
  File: File;
  Id: number;
  unique_id: number;
  Name: string;
}