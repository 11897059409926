<h1 id="tableLabel">Persons</h1>

<p>This component demonstrates fetching data from the server.</p>

<p *ngIf="!persons"><em>Loading...</em></p>

<table class='table table-striped' aria-labelledby="tableLabel" *ngIf="persons">
  <thead>
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Age</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let person of persons">
      <td>{{ person.id }}</td>
      <td>{{ person.name }}</td>
      <td>{{ person.age }}</td>
    </tr>
  </tbody>
</table>
