import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { TableColumn, TableComponent } from 'smart-webcomponents-angular/table';
import { WindowComponent } from 'smart-webcomponents-angular/window';
import { HttpService } from '../http.service';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-list-for-therapist',
  templateUrl: './news-list-for-therapist.component.html',
  styleUrls: ['./news-list-for-therapist.component.css']
})
export class NewsListForTherapistComponent implements OnInit {

  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;
  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  
  formList: any; hasAdminAccess = false;

  popupHeader: any = "Select";
  popupSubheader: any = "Select";;
  popupOpen: boolean;
  saveParamter: string = '';
  editor: boolean;
  unique_id: any;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService,
    private router: Router, private changeDetect: ChangeDetectorRef) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      label: 'View',
      dataField: '',
      width: 100,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button id="view" row-id="${settings.data.unique_id}" class="view-row" (click)="viewNews($event)">View</smart-button>`;
      }
    },
    {
      label: 'Id',
      dataField: 'unique_id',
      dataType: 'number',
      width: 60
    },
    {
      label: 'Title',
      dataField: 'summary',
      dataType: 'string',
      width: 300
    },
    {
      label: 'Description',
      dataField: 'description',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Enabled',
      dataField: 'enabled',
      dataType: 'string',
      width: 200
    },
    
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  filterRow: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  ngOnInit(): void {
    this.delay(1000).then((any) => {
      const that = this;
      this.httpService.get(`api/v1/news/get`).subscribe(function (dataRows) {
        that.formList = dataRows;
        that.dataSource = new window.Smart.DataAdapter({
          dataSource: dataRows,
          dataFields: [
            'unique_id: number',
            'summary: string',
            'description: string',
            'enabled: string',
          ]
        });
      });
    });
  }

  ngAfterViewInit(): void {

    const that = this;
    this.table.addEventListener('click', function (event: Event) {
      const viewRow = (<HTMLSpanElement>event.target).closest('.view-row');
        
      if (viewRow) {
        const unique_id = viewRow.getAttribute('row-id');
        that.router.navigate([`/news-details`, { id: unique_id }]);
      }
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }

}
