import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AzureAdService } from '../azure-ad.service';
import { WindowComponent } from 'smart-webcomponents-angular/window';

@Component({
  selector: 'app-intake-assessment-list',
  templateUrl: './intake-assessment-list.component.html',
  styleUrls: ['./intake-assessment-list.component.css']
})
export class IntakeAssessmentListComponent implements OnInit {
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;
  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('formWindow', { read: WindowComponent, static: false }) formWindow: WindowComponent;

  formList: any; hasAdminAccess = false;
  documentId: any;
  deleteRow: any;

  constructor(private httpService: HttpService, private clientService: ClientService,private azureAdService: AzureAdService,
    private router: Router) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      label: 'Form Name',
      dataField: 'DocName',
      dataType: 'string',
      width: 200
    },
    {
      label: 'Client Name',
      dataField: 'ClientName',
      dataType: 'string',
      width: 100
    },
    {
      label: 'Edit',
      dataField: '',
      width: 70,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button id="edit" row-id="${settings.data.DocId}" class="edit-row" (click)="editForm($event)">Edit</smart-button>`;
      }
    }
    , {
      label: 'Delete',
      dataField: '',
      width: 70,
      allowEdit: false,
      formatFunction(settings: any) {
        settings.template = `<smart-button #button2 id="delete" row-index="${settings.data.$.index}" row-id="${settings.data.DocId}" class="delete-row" (click)="deleteForm($event)">Delete</smart-button>`;
      }
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    this.delay(1000).then((any) => {
      const that = this;

      let emailId = null;
      let profile = this.clientService.getProfile();
      if (profile) {
        emailId = profile.mail;
      }

      this.httpService.get(`api/v1/formlist/get-document-list/form-name/Intake_Assessment/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {
        that.formList = dataRows;
        that.dataSource = new window.Smart.DataAdapter({
          dataSource: dataRows,
          dataFields: [
            'DocId: string',
            'DocName: string',
            'ClientName: string',
          ]
        });
      });
    });
  }

  ngAfterViewInit(): void {

    const that = this;
    this.table.addEventListener('click', function (event: Event) {
      that.deleteRow = (<HTMLSpanElement>event.target).closest('.delete-row');
      const editRow = (<HTMLSpanElement>event.target).closest('.edit-row');

      if (that.deleteRow) {
        that.documentId = that.deleteRow.getAttribute('row-id');
        that.formWindow.open();
      }
      else if (editRow) {
        const docId = editRow.getAttribute('row-id');
        const clientDetails = that.formList.filter((row: any) => row.DocId == docId)[0];
        that.router.navigate([`/intake_assessment`, { id: docId, clientName: clientDetails?.ClientName, clientId: clientDetails?.ClientId }]);
      }
    });

    that.formWindow.addEventListener('click', dialogButtonsHandler);

    function dialogButtonsHandler(event: CustomEvent): void {
      const target: HTMLElement = event.target as HTMLElement;

      if (target.closest('.smart-cancel-button')) {
        that.formWindow.close();
      }
      else if (target.closest('.smart-confirm-button')) {
        that.httpService.delete(`api/v1/forms/delete-form/doc-id/${that.documentId}`).subscribe(function (result) {
          if (result) {
            const rowIndex = that.deleteRow.getAttribute('row-index');
            that.table.nativeElement.removeRow(rowIndex);

            that.formWindow.close();
          }
        });
      }
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
