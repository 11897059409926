
<div class="row">
    <div class="col-sm-1 border" style="box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);">
        <button class="btn border-bottom margin" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/450f9cd7d3eb4025aec2b2504394104e@nationalpbs.com.au/15d95b672a0a4331a98ffd207d68b46e16648238880303949292/calendar.html'">Car 1</button>
        
        <button class="btn border-bottom margin" (click)="iframeURI = 'https://outlook.office365.com/owa/calendar/1ce0824b5a3b42b5949aa6f6ee35199a@nationalpbs.com.au/b85f2c4990204d4bb5202e3f5b58a86f5153745950815879218/calendar.html'">Car 2</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/68a0406d8b5a4cbfb2a796645e026497@nationalpbs.com.au/b28aa796a187440eb0239100344fbd7312330056566926965204/calendar.html'">Car 3</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/1fa63c5f4dde4cc49ccd603c5b7d9a1f@nationalpbs.com.au/3275ebab465141fabe2f3309f20d94058141085782078301950/calendar.html'">Car 4</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/c3aede911de44433996a984b412725f9@nationalpbs.com.au/1c7075661cc34f0cb8d3a774d40ceefb16720636974843297360/calendar.html'">Car 5</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/fc51d9d495b44838b4b107ab3262a78b@nationalpbs.com.au/c267c88a077a4fe1badc352e668fb39013652294772929050257/calendar.html'">Car 6</button>

        <button class="btn border-bottom margin" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/20070538a6174a3da34745189a96b565@nationalpbs.com.au/f8b5d0fe3728432994fd0c68a2dae50410715906512028261524/calendar.html'">Car 8</button>

        <button class="btn border-bottom" style="margin-top: 10px;" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/257a5727baf34753980907525232ca24@nationalpbs.com.au/08903a95ab49461295892b4fa4df999613699537783469135281/calendar.html'">Own Car</button>

        <button class="btn border-bottom" style="margin-top: 10px;" (click)="iframeURI ='https://outlook.office365.com/owa/calendar/2a62313409d24274a651df15f03f9423@nationalpbs.com.au/71e9d3ece66142a7b25dac443d3041f06542085329324380148/calendar.html'">Malvern Car</button>
    </div>
    <div class="col-sm-11">
        <iframe frameborder="0" class="iframe-style" height='700px'
        [src]="iframeURI | safe"></iframe>
    </div>
</div>