import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-risk-assessment-manual',
  templateUrl: './risk-assessment-manual.component.html',
  styleUrls: ['./risk-assessment-manual.component.css']
})

export class RiskAssessmentManualComponent implements OnInit {
  iframeURI: SafeResourceUrl;
  formBaseUrl: string = 'https://forms.zohopublic.com.au/nationalpositivebehavioursuppo/form/RiskAssessmentNPBS21ManualForm/formperma/sfJCwlDVWfuJURlAN8oyhoyWgGe1oLmcIg3x9XA-Pv4';
  constructor(private sanitizer: DomSanitizer, private clientService: ClientService) {
    
  }

  ngOnInit(): void {

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    let clientDetails = this.clientService.getClientDetails();
    if (clientDetails) {
      let name = `${clientDetails.title} ${clientDetails.firstName} ${clientDetails.lastName}`;
      let url = `${this.formBaseUrl}?participant_name=${name}&client_id=${clientDetails.clientId}&created_by=${emailId}`;
      url = url.replace(/\[/g, '(').replace(/]/g, ')');
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else
      this.iframeURI = this.sanitizer.bypassSecurityTrustResourceUrl(this.formBaseUrl);
  }

}
