import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-restrictive-practices-environment-assessment-list',
  templateUrl: './restrictive-practices-environment-assessment-list.component.html',
  styleUrls: ['./restrictive-practices-environment-assessment-list.component.css']
})
export class RestrictivePracticesEnvironmentAssessmentListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "DOB",
      "dataField": "Date",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Diagnosis",
      "dataField": "SingleLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Number",
      "dataField": "SingleLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Person being interviewed",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Role",
      "dataField": "SingleLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What environment is being assessed?",
      "dataField": "Checkbox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Address",
      "dataField": "Address2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever placed in a room inside their home where they cannot leave?",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is this done?",
      "dataField": "SingleLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is the door to the room they are in locked?",
      "dataField": "Radio1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Under what conditions can they come out?",
      "dataField": "SingleLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is the length of time they are kept in this room for?",
      "dataField": "SingleLine20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate with them why/ how long and under what conditions they can leave this room?",
      "dataField": "Radio36",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate this to them?",
      "dataField": "SingleLine21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever locked inside the home with no supports?",
      "dataField": "Radio2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "For what reason?",
      "dataField": "SingleLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is the length of time they are home alone for?",
      "dataField": "SingleLine22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How often does this occur?",
      "dataField": "SingleLine23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate how long and why you are leaving them alone?",
      "dataField": "Radio37",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate this to them?",
      "dataField": "SingleLine41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever locked in a vehicle?",
      "dataField": "Radio3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "For what reason?",
      "dataField": "SingleLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is the length of time they are locked in the vehicle for?",
      "dataField": "SingleLine25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate how long and why you are leaving them in the vehicle?",
      "dataField": "Radio38",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate the reason and length of time to them?",
      "dataField": "SingleLine24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they provided access to a bathroom prior to travelling?",
      "dataField": "Radio40",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of communicating the need to go to the bathroom while in the vehicle?",
      "dataField": "Radio44",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate this?",
      "dataField": "SingleLine32",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they provided access to water and other items whilst travelling?",
      "dataField": "Radio39",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is provided?",
      "dataField": "SingleLine26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they the last person to get into the vehicle?",
      "dataField": "Radio41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they the last person to exit the vehicle?",
      "dataField": "Radio42",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are there any sides or rails on their beds?",
      "dataField": "Radio4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this for a medical reason?",
      "dataField": "Radio33",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What medical reason?",
      "dataField": "SingleLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If yes:",
      "dataField": "FileUpload2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What reason is there for this rail to be on their bed?",
      "dataField": "SingleLine27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How long is the rail on the bed for?",
      "dataField": "SingleLine28",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is it communicated to them that the rail will be placed on the bed and why?",
      "dataField": "Radio43",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How is this communicated to them?",
      "dataField": "SingleLine29",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of communicating their need to go to the bathroom when the rail is on (if able)?",
      "dataField": "Radio45",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate the need to use the bathroom (if able)?",
      "dataField": "SingleLine33",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How are they supported to leave their bed (if possible) when the rail is on if needed for any other reason?",
      "dataField": "SingleLine30",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How can they communicate this need to someone?",
      "dataField": "SingleLine31",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they currently on any medication?",
      "dataField": "Radio5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Purpose of Medication form/Treatment Sheet",
      "dataField": "FileUpload",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are any of their medications only given just when needed (PRN)?",
      "dataField": "Radio6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is this medication given to them?",
      "dataField": "Checkbox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When is this medication given?",
      "dataField": "SingleLine15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What dosage/How many tablets at a time?",
      "dataField": "SingleLine18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you know the maximum amount you can administer each day?",
      "dataField": "Radio35",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Maximum dosage",
      "dataField": "SingleLine19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What signs tell you that they are anxious/agitated if they require a PRN?",
      "dataField": "SingleLine16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate with them why you are giving this to them?",
      "dataField": "Radio34",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate this to them?",
      "dataField": "SingleLine17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are any of their other medications used for:",
      "dataField": "Checkbox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a diagnosed sleep disorder?",
      "dataField": "Radio8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a diagnosis of depression or an anxiety related disorder?",
      "dataField": "Radio9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there a confirmed mental health disorder associated with the psychosis or hallucinations?",
      "dataField": "Radio10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any diagnosis related to taking this medication for menstrual suppression?",
      "dataField": "Radio12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they taking this for:",
      "dataField": "Radio13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If Medical Condition, please note:",
      "dataField": "SingleLine12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If Other, please note:",
      "dataField": "SingleLine14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they aware of why they are taking this contraception?",
      "dataField": "Radio14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any additional information:",
      "dataField": "SingleLine13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever prevented from leaving their home/environment due to a locked exit?",
      "dataField": "Radio15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to wandering or other behaviour of concern?",
      "dataField": "Radio18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If this is for a day program, Respite or Aged-Care facility ask the following: Is this in your service agreement that the door is locked?",
      "dataField": "Radio27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is locked?",
      "dataField": "Checkbox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is this locked?",
      "dataField": "MultiLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When is this locked",
      "dataField": "Radio46",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when this will be locked (if PRN)",
      "dataField": "Radio47",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you let them know it will be locked?",
      "dataField": "SingleLine34",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking to leave the property?",
      "dataField": "Radio48",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they let you know they need to leave?",
      "dataField": "SingleLine35",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you always have the ability to assist them to leave the property?",
      "dataField": "Radio49",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever prevented from accessing certain activities?",
      "dataField": "Radio19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern associated with the activity?",
      "dataField": "Radio30",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What activities and why?",
      "dataField": "MultiLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when this will be unavailable (if PRN)",
      "dataField": "Radio50",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you let them know it will be unavailable? (If PRN)",
      "dataField": "SingleLine36",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to the activity?",
      "dataField": "Radio51",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate their want/need to do the activity?",
      "dataField": "SingleLine37",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the activity when they ask?",
      "dataField": "Radio52",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine38",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever prevented from accessing certain items?",
      "dataField": "Radio20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern associated with the item?",
      "dataField": "Radio28",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What items and why?",
      "dataField": "MultiLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when this item will be unavailable (if PRN)",
      "dataField": "Radio53",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they have no access to the item?",
      "dataField": "SingleLine42",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to the item?",
      "dataField": "Radio54",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate their want/need to access the item?",
      "dataField": "SingleLine39",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the item when they ask?",
      "dataField": "Radio55",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine40",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever not allowed access to certain areas of their home?",
      "dataField": "Radio21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern associated with the area?",
      "dataField": "Radio25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What areas?",
      "dataField": "Checkbox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details of why",
      "dataField": "MultiLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when this area will be unavailable (if PRN)",
      "dataField": "Radio56",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they have no access to the area?",
      "dataField": "SingleLine43",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to the area?",
      "dataField": "Radio57",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you?",
      "dataField": "SingleLine46",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the area when they ask?",
      "dataField": "Radio58",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine44",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever restricted from access to a fridge, freezer, pantry or cupboards?",
      "dataField": "Radio24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern with access to the cupboards etc?",
      "dataField": "Radio29",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What things are not accessible?",
      "dataField": "Checkbox8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Details of why",
      "dataField": "MultiLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when this fridge/freezer/cupboard will be unavailable (if PRN)",
      "dataField": "Radio59",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they have no access to the area?",
      "dataField": "SingleLine45",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to the fridge/freezer/cupboard etc?",
      "dataField": "Radio60",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you?",
      "dataField": "SingleLine47",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the fridge/freezer/cupboard etc when they ask?",
      "dataField": "Radio61",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine48",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever restricted from using toiletries, cutlery, and other sharps?",
      "dataField": "Radio23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern causing harm?",
      "dataField": "Radio11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What items are locked away?",
      "dataField": "Checkbox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why?",
      "dataField": "MultiLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when the toiletries/cutlery etc will be unavailable (if PRN)",
      "dataField": "Radio62",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they have no access to the toiletries/cutlery etc?",
      "dataField": "SingleLine49",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to the toiletries/cutlery etc?",
      "dataField": "Radio63",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you?",
      "dataField": "SingleLine51",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the toiletries/cutlery etc when they ask?",
      "dataField": "Radio65",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine50",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they ever unable to access certain areas of their community?",
      "dataField": "Radio22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this due to a behaviour of concern in their community?",
      "dataField": "Radio31",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What areas and why?",
      "dataField": "MultiLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when they will need supervision in the community due to their behaviour (if PRN)",
      "dataField": "Radio66",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they have no access to the store/part of the community etc?",
      "dataField": "SingleLine52",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for access to certain shops etc?",
      "dataField": "Radio64",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you?",
      "dataField": "SingleLine53",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided access to the stores etc when they ask?",
      "dataField": "Radio67",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine54",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What type of support is needed in these areas?",
      "dataField": "Checkbox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have any devices such as helmets, wheelchair, lap belts or buckles (in wheelchairs), body suits, harnesses to reduce their behaviour?",
      "dataField": "Radio17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If Yes:",
      "dataField": "Checkbox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is this for therapeutic use?",
      "dataField": "Radio7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "File Upload",
      "dataField": "FileUpload1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "When does this device get used and why?",
      "dataField": "SingleLine9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is the maximum time that this is kept on/used for?",
      "dataField": "SingleLine58",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when they will need this device on/need to use this device? (if PRN)",
      "dataField": "Radio68",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that they need to use this?",
      "dataField": "SingleLine55",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for this to be removed?",
      "dataField": "Radio69",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you?",
      "dataField": "SingleLine56",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided the opportunity to have this removed when they ask?",
      "dataField": "Radio70",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine57",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you ever have to physically stop them from hurting others/themselves or do things like directing them to a shower or activity that they find challenging?",
      "dataField": "Radio16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "For what purpose?",
      "dataField": "SingleLine10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What does it look like when you use this?",
      "dataField": "SingleLine63",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What is the maximum time you would have to hold them/redirect them?",
      "dataField": "SingleLine59",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you communicate when they will be held etc?",
      "dataField": "Radio71",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do you communicate that you need to do this?",
      "dataField": "SingleLine60",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for this to stop?",
      "dataField": "Radio72",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate that need to you to stop?",
      "dataField": "SingleLine61",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided the opportunity to have you stop when they ask?",
      "dataField": "Radio73",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine62",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have the ability to make day to day decisions about important life matters? (No Guardian)",
      "dataField": "Radio76",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine67",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do you have to hold on to their money?",
      "dataField": "Radio26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "If Yes, for what purpose?",
      "dataField": "Checkbox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why?",
      "dataField": "SingleLine68",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a behaviour of concern associated with spending?",
      "dataField": "Radio32",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "What behaviour?",
      "dataField": "SingleLine11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Who has access to this and are they always available to provide them access when needed?",
      "dataField": "SingleLine64",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they have a way of asking for their money?",
      "dataField": "Radio74",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How do they communicate the need for access to their money?",
      "dataField": "SingleLine65",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Are they always provided their money when they ask?",
      "dataField": "Radio75",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Why is that?",
      "dataField": "SingleLine66",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist Name",
      "dataField": "Name1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Therapist email",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date-Time",
      "dataField": "DateTime",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine69",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Environment_assessment_form');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/environment_assessment_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'Name: string',
          'Date_ISO8601: string',
          'Date: string',
          'SingleLine1: string',
          'SingleLine: string',
          'SingleLine2: string',
          'SingleLine3: string',
          'Checkbox: string',
          'Address2_AddressLine1: string',
          'Address2_City: string',
          'Address2_Region: string',
          'Radio: string',
          'Radio.Numeric: string',
          'SingleLine4: string',
          'Radio1: string',
          'Radio1.Numeric: string',
          'SingleLine5: string',
          'SingleLine20: string',
          'Radio36: string',
          'SingleLine21: string',
          'Radio2: string',
          'Radio2.Numeric: string',
          'SingleLine6: string',
          'SingleLine22: string',
          'SingleLine23: string',
          'Radio37: string',
          'SingleLine41: string',
          'Radio3: string',
          'Radio3.Numeric: string',
          'SingleLine7: string',
          'SingleLine25: string',
          'Radio38: string',
          'SingleLine24: string',
          'Radio40: string',
          'Radio44: string',
          'SingleLine32: string',
          'Radio39: string',
          'SingleLine26: string',
          'Radio41: string',
          'Radio42: string',
          'Radio4: string',
          'Radio4.Numeric: string',
          'Radio33: string',
          'Radio33.Numeric: string',
          'SingleLine8: string',
          'FileUpload2: string',
          'SingleLine27: string',
          'SingleLine28: string',
          'Radio43: string',
          'SingleLine29: string',
          'Radio45: string',
          'SingleLine33: string',
          'SingleLine30: string',
          'SingleLine31: string',
          'Radio5: string',
          'Radio5.Numeric: string',
          'FileUpload: string',
          'Radio6: string',
          'Radio6.Numeric: string',
          'Checkbox3: string',
          'SingleLine15: string',
          'SingleLine18: string',
          'Radio35: string',
          'SingleLine19: string',
          'SingleLine16: string',
          'Radio34: string',
          'SingleLine17: string',
          'Checkbox2: string',
          'Radio8: string',
          'Radio8.Numeric: string',
          'Radio9: string',
          'Radio9.Numeric: string',
          'Radio10: string',
          'Radio10.Numeric: string',
          'Radio12: string',
          'Radio12.Numeric: string',
          'Radio13: string',
          'Radio13.Numeric: string',
          'SingleLine12: string',
          'SingleLine14: string',
          'Radio14: string',
          'Radio14.Numeric: string',
          'SingleLine13: string',
          'Radio15: string',
          'Radio15.Numeric: string',
          'Radio18: string',
          'Radio18.Numeric: string',
          'Radio27: string',
          'Checkbox4: string',
          'MultiLine5: string',
          'Radio46: string',
          'Radio47: string',
          'SingleLine34: string',
          'Radio48: string',
          'SingleLine35: string',
          'Radio49: string',
          'Radio19: string',
          'Radio19.Numeric: string',
          'Radio30: string',
          'Radio30.Numeric: string',
          'MultiLine: string',
          'Radio50: string',
          'SingleLine36: string',
          'Radio51: string',
          'SingleLine37: string',
          'Radio52: string',
          'SingleLine38: string',
          'Radio20: string',
          'Radio20.Numeric: string',
          'Radio28: string',
          'Radio28.Numeric: string',
          'MultiLine2: string',
          'Radio53: string',
          'SingleLine42: string',
          'Radio54: string',
          'SingleLine39: string',
          'Radio55: string',
          'SingleLine40: string',
          'Radio21: string',
          'Radio21.Numeric: string',
          'Radio25: string',
          'Radio25.Numeric: string',
          'Checkbox7: string',
          'MultiLine1: string',
          'Radio56: string',
          'SingleLine43: string',
          'Radio57: string',
          'SingleLine46: string',
          'Radio58: string',
          'SingleLine44: string',
          'Radio24: string',
          'Radio24.Numeric: string',
          'Radio29: string',
          'Radio29.Numeric: string',
          'Checkbox8: string',
          'MultiLine6: string',
          'Radio59: string',
          'SingleLine45: string',
          'Radio60: string',
          'SingleLine47: string',
          'Radio61: string',
          'SingleLine48: string',
          'Radio23: string',
          'Radio23.Numeric: string',
          'Radio11: string',
          'Radio11.Numeric: string',
          'Checkbox6: string',
          'MultiLine3: string',
          'Radio62: string',
          'SingleLine49: string',
          'Radio63: string',
          'SingleLine51: string',
          'Radio65: string',
          'SingleLine50: string',
          'Radio22: string',
          'Radio22.Numeric: string',
          'Radio31: string',
          'Radio31.Numeric: string',
          'MultiLine4: string',
          'Radio66: string',
          'SingleLine52: string',
          'Radio64: string',
          'SingleLine53: string',
          'Radio67: string',
          'SingleLine54: string',
          'Checkbox5: string',
          'Radio17: string',
          'Radio17.Numeric: string',
          'Checkbox1: string',
          'Radio7: string',
          'Radio7.Numeric: string',
          'FileUpload1: string',
          'SingleLine9: string',
          'SingleLine58: string',
          'Radio68: string',
          'SingleLine55: string',
          'Radio69: string',
          'SingleLine56: string',
          'Radio70: string',
          'SingleLine57: string',
          'Radio16: string',
          'Radio16.Numeric: string',
          'SingleLine10: string',
          'SingleLine63: string',
          'SingleLine59: string',
          'Radio71: string',
          'SingleLine60: string',
          'Radio72: string',
          'SingleLine61: string',
          'Radio73: string',
          'SingleLine62: string',
          'Radio76: string',
          'Radio76.Numeric: string',
          'SingleLine67: string',
          'Radio26: string',
          'Radio26.Numeric: string',
          'Checkbox9: string',
          'SingleLine68: string',
          'Radio32: string',
          'Radio32.Numeric: string',
          'SingleLine11: string',
          'SingleLine64: string',
          'Radio74: string',
          'SingleLine65: string',
          'Radio75: string',
          'SingleLine66: string',
          'Name1: string',
          'Email: string',
          'DateTime_ISO8601: string',
          'DateTime: string',
          'SingleLine69: string',
          'REFERRER_NAME: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'created_by: string',
        ]
      });
    });
  }

}
