import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../client.service';
import { HttpService } from '../http.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-letter-of-support',
  templateUrl: './letter-of-support.component.html',
  styleUrls: ['./letter-of-support.component.css']
})
export class LetterOfSupportComponent implements OnInit {

  iframeURI: string = "";
  docId: string = "";
  clientDetails: any;
  clientName: string = "";
  formName = "letter_of_support";
  clientId = "";
  hasAdminAccess = false;

  constructor(private sanitizer: DomSanitizer, private httpService: HttpService, private clientService: ClientService, private route: ActivatedRoute, private azureAdService: AzureAdService) { }

  ngOnInit(): void {

    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    this.showloader();

    this.docId = this.route.snapshot.paramMap.get('id')
    this.clientName = this.route.snapshot.paramMap.get('clientName')
    this.clientId = this.route.snapshot.paramMap.get('clientId')

    if (this.docId) {
      this.iframeURI = `https://docs.google.com/document/d/${this.docId}/edit`;
      this.hideloader();
    }
    else {
      let clientDetails = this.clientService.getClientDetails();

      if (clientDetails) {
        this.clientDetails = clientDetails;
        this.clientName = clientDetails?.firstName + ' ' + clientDetails?.lastName;
        this.clientId = clientDetails.clientId;
      }

      let emailId = null;
      let profile = this.clientService.getProfile();
      if (profile) {
        emailId = profile.mail;
      }

      let documentDetails = {
        DocName: "Letter_Of_Support",
        ClientId: clientDetails?.clientId,
        ClientName: clientDetails?.firstName + '_' + clientDetails?.lastName,
        CreatedBy: emailId,
      }

      this.httpService.post(`api/v1/forms/get_fresh_form`, JSON.stringify(documentDetails)).subscribe(result => {
        this.docId = result.DocId;
        this.iframeURI = `https://docs.google.com/document/d/${this.docId}/edit`;
        this.hideloader();
      });
    }
  }

  SaveAndUpload() {
    this.showloader();
    this.httpService.get(`api/v1/forms/save-and-upload/form-name/${this.formName}/doc-id/${this.docId}/clientId/${this.clientId}/clientName/${this.clientName}`).subscribe(result => {
      this.hideloader();
      if (result) {
        alert("Successfully uploaded document on Coreplus.")
      }
    });
  };

  Download() {
    this.showloader();
    this.httpService.getRequestCallPDF(`api/v1/forms/download/form-name/${this.formName}/doc-id/${this.docId}/clientName/${this.clientName}`).subscribe(result => {
      this.hideloader();
      this.clientService.downloadPdf(result);
    });
  };

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }
}
