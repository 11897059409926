import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ClientService } from '../client.service';
import { HttpService } from '../http.service';
import { Incident, IncidentAttachment } from '../models/incident.model';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent implements OnInit {
  incident: Incident = new Incident();
  unique_id: any;
  is_new_form: boolean = true;
  file: File = null;
  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private clientService: ClientService) { }

  ngOnInit(): void {
    this.incident.incident_attachment = new IncidentAttachment();
    this.unique_id = this.route.snapshot.paramMap.get('id')

    if (this.unique_id) {
      this.showloader();
      this.is_new_form = false;
      this.httpService.get(`api/v1/incident/get/${this.unique_id}`).subscribe(result => {
        this.incident = result;
        this.hideloader();
      });
    }
    else {
      this.incident.is_incident_occurred = 'Not applicable';
      this.incident.is_incident_reported = 'Not applicable';
      this.incident.is_resolve_by = 'Not applicable';
      this.incident.is_closed_on = 'Not applicable';
      this.incident.severity_rating = 'No rating';
      this.incident.status = 'New';
      this.incident.position_responsible = 'Valid';
      this.incident.report_notify = 'No reporting required';
      this.incident.satisfactory_resolution = 'Not applicable';
    }
  }

  saveIncident() {
    this.showloader();
    this.httpService.post(`api/v1/incident/create`, JSON.stringify(this.incident)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/incident-list`]);
    });
  }

  updateIncident() {
    this.incident.incident_attachment = null;
    this.showloader();
    this.httpService.patch(`api/v1/incident/update/${this.incident.unique_id}`, JSON.stringify(this.incident)).subscribe(result => {
      this.hideloader();
      this.router.navigate([`/incident-list`]);
    });
  }

  uploadFile() {
    if (!this.file) {
      return;
    }
    this.showloader();
    //let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);

    this.http.post(`${environment.npbsApiUrl}api/v1/incident/upload-file`, formData).subscribe(result => {
      this.hideloader();
      this.incident.attachment_id = parseInt(<string>result);
    });
  };

  onFileChange(event) {
    this.file = event.target.files[0];
  }

  //uploadFile(files) {
  //  if (files.length === 0) {
  //    return;
  //  }
  //  this.showloader();
  //  let fileToUpload = <File>files[0];
  //  const formData = new FormData();
  //  formData.append('file', fileToUpload, fileToUpload.name);

  //  this.http.post(`${environment.npbsApiUrl}api/v1/incident/upload-file`, formData).subscribe(result => {
  //    this.hideloader();
  //    this.incident.attachment_id = parseInt(<string>result);
  //  });
  //};

  downloadAttachment() {
    this.showloader();
    this.httpService.getRequestCallPDF(`api/v1/incident/download-attachment/${this.incident.attachment_id}`).subscribe(result => {
      this.hideloader();
      this.clientService.downloadPdf(result);
    });
  }

  showloader() {
    document.getElementById('overlay')
      .style.display = 'flex';
  }

  hideloader() {
    document.getElementById('overlay')
      .style.display = 'none';
  }
}
