import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent, TableColumn } from 'smart-webcomponents-angular/table';
import { HttpService } from '../http.service';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ClientService } from '../client.service';
import { AzureAdService } from '../azure-ad.service';

@Component({
  selector: 'app-ndis-occupational-thearapy-initial-assessment-list',
  templateUrl: './ndis-occupational-thearapy-initial-assessment-list.component.html',
  styleUrls: ['./ndis-occupational-thearapy-initial-assessment-list.component.css']
})
export class NdisOccupationalThearapyInitialAssessmentListComponent implements OnInit {

  @ViewChild('button', { read: ButtonComponent, static: false }) button!: ButtonComponent;
  @ViewChild('button2', { read: ButtonComponent, static: false }) button2!: ButtonComponent;
  @ViewChild('button3', { read: ButtonComponent, static: false }) button3!: ButtonComponent;
  @ViewChild('button4', { read: ButtonComponent, static: false }) button4!: ButtonComponent;
  @ViewChild('button5', { read: ButtonComponent, static: false }) button5!: ButtonComponent;
  @ViewChild('button6', { read: ButtonComponent, static: false }) button6!: ButtonComponent;
  @ViewChild('table', { read: TableComponent, static: false }) table: TableComponent;

  hasAdminAccess = false;

  constructor(private httpService: HttpService, private clientService: ClientService, private azureAdService: AzureAdService) {

  }

  dataSource = new window.Smart.DataAdapter({
    dataSource: []
  });
  columns: TableColumn[] = [
    {
      "label": "Personal Alarm/App/Have you checked in?",
      "dataField": "DecisionBox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Name tag",
      "dataField": "DecisionBox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Appropriate footwear",
      "dataField": "DecisionBox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Parking is safe",
      "dataField": "DecisionBox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Gate is easy to open and close, if present",
      "dataField": "DecisionBox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Animal are contained (if present)",
      "dataField": "DecisionBox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Pathways level",
      "dataField": "DecisionBox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Entry and exits clear",
      "dataField": "DecisionBox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Handrails present if needed",
      "dataField": "DecisionBox8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Trip hazards absent",
      "dataField": "DecisionBox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments on Exterior Environment",
      "dataField": "MultiLine",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Entrances and exits known",
      "dataField": "DecisionBox10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Floor coverings and spills not present",
      "dataField": "DecisionBox11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Trip hazards not present",
      "dataField": "DecisionBox12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Clutter removed",
      "dataField": "DecisionBox13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Electrical cords safe",
      "dataField": "DecisionBox14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Temperature/Humidity Safe",
      "dataField": "DecisionBox15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Lighting adequate",
      "dataField": "DecisionBox16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Furniture appropriateness and of adequate strength if going to be used",
      "dataField": "DecisionBox17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments on Interior Environment",
      "dataField": "MultiLine1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Name",
      "dataField": "Name",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date of Birth",
      "dataField": "Date",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Address",
      "dataField": "Address",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Home Contact",
      "dataField": "PhoneNumber",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Mobile Contact",
      "dataField": "PhoneNumber1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Email",
      "dataField": "Email",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Alternate Contact",
      "dataField": "SubFormParse",
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].Name1_Salutation},${contacts[i].Name1_First},${contacts[i].Name1_Last}</td><td>${contacts[i].SingleLine}</td ><td>${contacts[i].PhoneNumber2}</td ><td>${contacts[i].Email1}</td ></tr>`
          }

          var s = `<table class="table smart-table-container"><tr style="height: auto;"><th>Name</th><th>Relationship</th><th>Phone</th><th>Email</th></tr>${result}</table>`;
          settings.template = `${s}`;
        }
        //settings.template = '<table class="table smart-table-container"><tr style="height: auto;"><th>Name</th><th>Relationship</th><th>Phone</th><th>Email</th></tr><tr style="height: auto;" *ngFor="let contact of contacts"><td>{{contact.name1_Salutation}}, {{contact.name1_First}}, {{contact.name1_Last}}</td><td>{{contact.singleLine}}</td><td>{{ contact.phoneNumber2 }}</td><td>{{contact.email1}}</td></tr></table>';
      }
    },
    {
      "label": "NDIS Number",
      "dataField": "Number",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Current Plan start date",
      "dataField": "Date1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Current Plan end date",
      "dataField": "Date2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How is the Plan Managed?",
      "dataField": "Dropdown",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Plan Manager detail",
      "dataField": "SubFormParse1",
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].Name2_Salutation},${contacts[i].Name2_First},${contacts[i].Name2_Last}</td><td>${contacts[i].SingleLine1}</td ><td>${contacts[i].PhoneNumber3}</td ><td>${contacts[i].Email2}</td ></tr>`
          }
          var sa = `<table class="table smart-table-container"><tr style="height: auto;"><th>Name</th><th>Relationship</th><th>Phone</th><th>Email</th></tr>${result}</table>`;
          settings.template = `${sa}`;
        }
      }
    },
    {
      "label": "NDIS Goal 1",
      "dataField": "MultiLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Goal 2",
      "dataField": "MultiLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Goal 3",
      "dataField": "MultiLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "NDIS Goal 4",
      "dataField": "MultiLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Long term goals",
      "dataField": "MultiLine6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Date/Time of initital assessment",
      "dataField": "DateTime",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Location of initital assessment",
      "dataField": "SingleLine2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "People present",
      "dataField": "MultiLine7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Assessments Completed",
      "dataField": "Checkbox",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Assessments Other",
      "dataField": "MultiLine8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Verbal Consent for initial Assessment",
      "dataField": "Radio",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Primary Disability",
      "dataField": "MultiLine23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other medical Conditions",
      "dataField": "MultiLine16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How has their recent health been?",
      "dataField": "MultiLine24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recent Hospitalisations?",
      "dataField": "MultiLine25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Falls?",
      "dataField": "MultiLine26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Fatigue?",
      "dataField": "MultiLine27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Height and weight?",
      "dataField": "MultiLine29",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Vision- (glasses, bifocal)",
      "dataField": "SingleLine3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Hearing - (aids or devices)",
      "dataField": "SingleLine4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Medications",
      "dataField": "MultiLine9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How is their medication kept- (Webster Pack, Tab Timer, Own boxes)",
      "dataField": "MultiLine10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Administration",
      "dataField": "MultiLine11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Storage",
      "dataField": "MultiLine12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Repeat Subscription- how often/how do they get repeats?",
      "dataField": "MultiLine13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other?",
      "dataField": "MultiLine14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Mobility",
      "dataField": "Checkbox1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any other comments on mobility (Gait pattern)",
      "dataField": "MultiLine15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Type of chair used",
      "dataField": "MultiLine17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Type of bed used",
      "dataField": "MultiLine18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any other comments on transfers? (To be discussed further later in respected categories)",
      "dataField": "MultiLine19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Balance and Coordination",
      "dataField": "Checkbox26",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on Balance and Coordination",
      "dataField": "MultiLine20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Fine Motor Skills/Hand Function and Dominance: *Is the patient requiring further functional testing",
      "dataField": "Checkbox2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Fine Motor Skills/Hand Function:",
      "dataField": "MultiLine28",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Range of Movement and Pain",
      "dataField": "Checkbox27",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on Range of Movement and Pain",
      "dataField": "MultiLine21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Is there a history of pressure injuries?",
      "dataField": "MultiLine41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any observations re skin – e.g. dry, paper thin, oedema, swelling, broken skin, skin tears, wounds etc",
      "dataField": "MultiLine42",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for Physiotherapy or Exercise Physiology indicated?",
      "dataField": "MultiLine43",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "People/Place/Time Details",
      "dataField": "MultiLine46",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Short term- 30 seconds to several days- meal earlier that day or day prior, what they did earlier that day, Therapist’s name",
      "dataField": "MultiLine47",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Long term - recollection of an important day in the distant past (early birthday, graduation, wedding etc.)",
      "dataField": "MultiLine48",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recall",
      "dataField": "MultiLine49",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Visuospatial",
      "dataField": "MultiLine51",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Attention",
      "dataField": "MultiLine52",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recognition",
      "dataField": "MultiLine53",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Safety/Judgement",
      "dataField": "MultiLine54",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other comments",
      "dataField": "MultiLine55",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for further Cognitive Assessment indicated? E.g. MMSE, RUDAS, MoCA",
      "dataField": "MultiLine56",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Word finding difficulties, slurred speech, other?",
      "dataField": "MultiLine57",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for Speech Pathology referral indicated?",
      "dataField": "MultiLine59",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Concerns and triggers, self-harm or suicide ideation",
      "dataField": "MultiLine60",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for Psychology or Behavioural Therapy referral indicated?",
      "dataField": "MultiLine63",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Balance/Vestibular",
      "dataField": "MultiLine64",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Proprioception",
      "dataField": "MultiLine65",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Strong likes or dislikes?",
      "dataField": "MultiLine66",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Visual processing?",
      "dataField": "MultiLine67",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Auditory processing?",
      "dataField": "MultiLine68",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Strong likes or dislikes?",
      "dataField": "MultiLine69",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "High/low registration",
      "dataField": "MultiLine70",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Sensory seeking/avoiding",
      "dataField": "MultiLine71",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Sounds/light",
      "dataField": "MultiLine72",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Crowds",
      "dataField": "MultiLine73",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Social deficits",
      "dataField": "MultiLine74",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Ability to form friendships",
      "dataField": "MultiLine75",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Social cues",
      "dataField": "MultiLine77",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Taking turns in conversation",
      "dataField": "MultiLine76",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Eye contact",
      "dataField": "MultiLine78",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Recommendations",
      "dataField": "MultiLine79",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathing/Showering",
      "dataField": "Checkbox3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments - Showering/Bathing",
      "dataField": "MultiLine80",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Toileting",
      "dataField": "Checkbox4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Incontinent- Bladder",
      "dataField": "Radio1",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Incontinent- Bowels",
      "dataField": "Radio2",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Transfers- level of assistance and aids involved",
      "dataField": "MultiLine86",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Dressing",
      "dataField": "Checkbox5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Shoes and socks- tie laces or slip-on shoes?",
      "dataField": "MultiLine96",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Grooming",
      "dataField": "Checkbox6",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Nail care- hands and feet",
      "dataField": "MultiLine104",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Need for Podiatrist referral indicated?",
      "dataField": "MultiLine105",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Feeding",
      "dataField": "Checkbox7",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Speech Pathologist referral required?",
      "dataField": "MultiLine22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Speech Pathologist referral required?",
      "dataField": "MultiLine108",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Sleeping patterns?",
      "dataField": "MultiLine109",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Own and/or use a mobile phone?",
      "dataField": "MultiLine114",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Look up and dial numbers?",
      "dataField": "MultiLine115",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Needs support with basic technology skills?",
      "dataField": "MultiLine116",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Independent",
      "dataField": "DecisionBox18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Supervision",
      "dataField": "DecisionBox19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Prompting",
      "dataField": "DecisionBox20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Assist x 1",
      "dataField": "DecisionBox21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Aids required- e.g.shopping buggy?",
      "dataField": "MultiLine121",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Meal Preparation and Cooking",
      "dataField": "Checkbox8",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Able to meal prep and cook meals independently?",
      "dataField": "MultiLine127",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Checkbox",
      "dataField": "Checkbox9",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Light tidying tasks- putting things away",
      "dataField": "MultiLine134",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Checkbox",
      "dataField": "Checkbox10",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Aids required?",
      "dataField": "MultiLine138",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Checkbox",
      "dataField": "Checkbox11",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Aids required?",
      "dataField": "MultiLine139",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Employment/Education",
      "dataField": "Checkbox12",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Where do they work/What are they studying?",
      "dataField": "MultiLine143",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "How many days/hours per week",
      "dataField": "MultiLine144",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Roles/Requirements",
      "dataField": "MultiLine145",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Support/intervention required?",
      "dataField": "MultiLine146",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Frequency of supports",
      "dataField": "MultiLine147",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Live in care?",
      "dataField": "MultiLine148",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Leisure/Play/Interests/Roles",
      "dataField": "MultiLine149",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Community Access / Social Participation",
      "dataField": "Checkbox13",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they engage in social participation regularly?",
      "dataField": "MultiLine150",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Do they engage in social activities or groups?",
      "dataField": "MultiLine151",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Type",
      "dataField": "Checkbox14",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Ownership",
      "dataField": "Checkbox15",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comments",
      "dataField": "MultiLine152",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Location to home access",
      "dataField": "MultiLine153",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Access - (Front)",
      "dataField": "Checkbox16",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine158",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Access - (Rear)",
      "dataField": "Checkbox17",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine159",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Internal Level Change",
      "dataField": "Radio3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Internal Level Change",
      "dataField": "Checkbox18",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine160",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Lounge/Seating",
      "dataField": "Checkbox19",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Suitability of seating- e.g. height of seating, ability to transfer, pressure care needs",
      "dataField": "MultiLine161",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Dining Table- e.g. height, accessible seating, portable table",
      "dataField": "MultiLine162",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bench heights",
      "dataField": "MultiLine163",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Access to Amenities (e.g. kettle, microwave)",
      "dataField": "MultiLine164",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bedroom- Environment",
      "dataField": "Checkbox20",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine165",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bedroom- Bed Type",
      "dataField": "Checkbox21",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine166",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bedroom- Mattress",
      "dataField": "Checkbox22",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine167",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bedroom- Equipment",
      "dataField": "Checkbox23",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine168",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Where is toilet in relation to bedroom (nighttime toileting)",
      "dataField": "MultiLine169",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathroom- Configuration",
      "dataField": "Checkbox24",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine170",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Bathroom- Setup",
      "dataField": "Checkbox25",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Comment on above",
      "dataField": "MultiLine173",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Hob height, sliding or swing open shower door",
      "dataField": "MultiLine171",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Other Equipment?",
      "dataField": "MultiLine172",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Cultural Considerations",
      "dataField": "MultiLine174",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Any other additional comments",
      "dataField": "MultiLine175",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Subform",
      "dataField": "SubFormParse2",
      dataType: 'string',
      width: 700,
      formatFunction: (settings: any) => {
        if (settings.value) {
          var json = settings.value.replace(/'/g, '"');
          var contacts = JSON.parse(json);
          var result = '';
          for (var i = 0; i < contacts.length; i++) {
            result = result + `<tr style="height: auto;"><td>${contacts[i].MultiLine176}</td ></tr>`
          }

          var sat = `<table class="table smart-table-container">${result}</table>`;
          settings.template = `${sat}`;
        }
      }
    },
    {
      "label": "Therapist contact details provided (mobile and email), as well as an alternate contact number- Office Mobile- 1300 282 940",
      "dataField": "DecisionBox41",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client was provided with a clear action plan and knows when they are to expect next Therapist contact",
      "dataField": "DecisionBox42",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Next session booked into Diary",
      "dataField": "DecisionBox43",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Cancellation Policy explained",
      "dataField": "DecisionBox44",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Travel Charges Discussed",
      "dataField": "DecisionBox45",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Service Agreement Signed- (*likely to have prior to initial contact from Support team)",
      "dataField": "DecisionBox46",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Their right to choice and provider feedback",
      "dataField": "DecisionBox47",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Name",
      "dataField": "Name3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Phone",
      "dataField": "PhoneNumber4",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Email",
      "dataField": "Email3",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Client Id",
      "dataField": "SingleLine5",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Added Time",
      "dataField": "ADDED_TIME",
      "dataType": "string",
      "width": 400
    },
    {
      "label": "Referrer Name",
      "dataField": "REFERRER_NAME",
      "dataType": "string",
      "width": 400
    },
    {
      label: 'Created By',
      dataField: 'created_by',
      dataType: 'string',
      width: 200
    }
  ];
  keyboardNavigation: Boolean = true;
  paging: Boolean = true;
  pageIndex: Number = 0;
  pageSize: Number = 10;
  tooltip = true;
  freezeHeader: boolean = true;
  filtering: Boolean = true;
  columnMenu: Boolean = true;
  columnResize: Boolean = true;
  dataSourceSettings = {
    sanitizeHTML: 'all',
    sanitizeHTMLRender: 'html'
  }

  handleClick(event: Event, type: String) {
    this.table.exportData(type, 'Ndis_occupational_therapy_initial_assessment');
  }

  ngOnInit(): void {
    this.azureAdService.hasAdminAccess.subscribe(
      x => {
        this.hasAdminAccess = x;
      });

    let emailId = null;
    let profile = this.clientService.getProfile();
    if (profile) {
      emailId = profile.mail;
    }

    const that = this;
    this.httpService.get(`api/v1/formlist/ndis_occupational_therapy_initial_assessment_form_list/created-by/${emailId}/${this.hasAdminAccess}`).subscribe(function (dataRows) {

      that.dataSource = new window.Smart.DataAdapter({
        dataSource: dataRows,
        dataFields: [
          'DecisionBox: string',
          'DecisionBox1: string',
          'DecisionBox2: string',
          'DecisionBox3: string',
          'DecisionBox4: string',
          'DecisionBox5: string',
          'DecisionBox6: string',
          'DecisionBox7: string',
          'DecisionBox8: string',
          'DecisionBox9: string',
          'MultiLine: string',
          'DecisionBox10: string',
          'DecisionBox11: string',
          'DecisionBox12: string',
          'DecisionBox13: string',
          'DecisionBox14: string',
          'DecisionBox15: string',
          'DecisionBox16: string',
          'DecisionBox17: string',
          'MultiLine1: string',
          'Name: string',
          'Date_ISO8601: string',
          'Date: string',
          'Address_AddressLine1: string',
          'Address_City: string',
          'Address_Region: string',
          'Address_ZipCode: string',
          'PhoneNumber: string',
          'PhoneNumber1: string',
          'Email: string',
          'SubFormParse: string',
          'Number: string',
          'Date1_ISO8601: string',
          'Date1: string',
          'Date2_ISO8601: string',
          'Date2: string',
          'Dropdown: string',
          'SubFormParse1: string',
          'MultiLine2: string',
          'MultiLine3: string',
          'MultiLine4: string',
          'MultiLine5: string',
          'MultiLine6: string',
          'DateTime_ISO8601: string',
          'DateTime: string',
          'SingleLine2: string',
          'MultiLine7: string',
          'Checkbox: string',
          'MultiLine8: string',
          'Radio: string',
          'MultiLine23: string',
          'MultiLine16: string',
          'MultiLine24: string',
          'MultiLine25: string',
          'MultiLine26: string',
          'MultiLine27: string',
          'MultiLine29: string',
          'SingleLine3: string',
          'SingleLine4: string',
          'MultiLine9: string',
          'MultiLine10: string',
          'MultiLine11: string',
          'MultiLine12: string',
          'MultiLine13: string',
          'MultiLine14: string',
          'Checkbox1: string',
          'MultiLine15: string',
          'MultiLine17: string',
          'MultiLine18: string',
          'MultiLine19: string',
          'Checkbox26: string',
          'MultiLine20: string',
          'Checkbox2: string',
          'MultiLine28: string',
          'Checkbox27: string',
          'MultiLine21: string',
          'MultiLine41: string',
          'MultiLine42: string',
          'MultiLine43: string',
          'MultiLine46: string',
          'MultiLine47: string',
          'MultiLine48: string',
          'MultiLine49: string',
          'MultiLine51: string',
          'MultiLine52: string',
          'MultiLine53: string',
          'MultiLine54: string',
          'MultiLine55: string',
          'MultiLine56: string',
          'MultiLine57: string',
          'MultiLine59: string',
          'MultiLine60: string',
          'MultiLine63: string',
          'MultiLine64: string',
          'MultiLine65: string',
          'MultiLine66: string',
          'MultiLine67: string',
          'MultiLine68: string',
          'MultiLine69: string',
          'MultiLine70: string',
          'MultiLine71: string',
          'MultiLine72: string',
          'MultiLine73: string',
          'MultiLine74: string',
          'MultiLine75: string',
          'MultiLine77: string',
          'MultiLine76: string',
          'MultiLine78: string',
          'MultiLine79: string',
          'Checkbox3: string',
          'MultiLine80: string',
          'Checkbox4: string',
          'Radio1: string',
          'Radio2: string',
          'MultiLine86: string',
          'Checkbox5: string',
          'MultiLine96: string',
          'Checkbox6: string',
          'MultiLine104: string',
          'MultiLine105: string',
          'Checkbox7: string',
          'MultiLine22: string',
          'MultiLine108: string',
          'MultiLine109: string',
          'MultiLine114: string',
          'MultiLine115: string',
          'MultiLine116: string',
          'DecisionBox18: string',
          'DecisionBox19: string',
          'DecisionBox20: string',
          'DecisionBox21: string',
          'MultiLine121: string',
          'Checkbox8: string',
          'MultiLine127: string',
          'Checkbox9: string',
          'MultiLine134: string',
          'Checkbox10: string',
          'MultiLine138: string',
          'Checkbox11: string',
          'MultiLine139: string',
          'Checkbox12: string',
          'MultiLine143: string',
          'MultiLine144: string',
          'MultiLine145: string',
          'MultiLine146: string',
          'MultiLine147: string',
          'MultiLine148: string',
          'MultiLine149: string',
          'Checkbox13: string',
          'MultiLine150: string',
          'MultiLine151: string',
          'Checkbox14: string',
          'Checkbox15: string',
          'MultiLine152: string',
          'MultiLine153: string',
          'Checkbox16: string',
          'MultiLine158: string',
          'Checkbox17: string',
          'MultiLine159: string',
          'Radio3: string',
          'Checkbox18: string',
          'MultiLine160: string',
          'Checkbox19: string',
          'MultiLine161: string',
          'MultiLine162: string',
          'MultiLine163: string',
          'MultiLine164: string',
          'Checkbox20: string',
          'MultiLine165: string',
          'Checkbox21: string',
          'MultiLine166: string',
          'Checkbox22: string',
          'MultiLine167: string',
          'Checkbox23: string',
          'MultiLine168: string',
          'MultiLine169: string',
          'Checkbox24: string',
          'MultiLine170: string',
          'Checkbox25: string',
          'MultiLine173: string',
          'MultiLine171: string',
          'MultiLine172: string',
          'MultiLine174: string',
          'MultiLine175: string',
          'SubFormParse2: string',
          'DecisionBox41: string',
          'DecisionBox42: string',
          'DecisionBox43: string',
          'DecisionBox44: string',
          'DecisionBox45: string',
          'DecisionBox46: string',
          'DecisionBox47: string',
          'Name3: string',
          'PhoneNumber4: string',
          'Email3: string',
          'SingleLine5: string',
          'REFERRER_NAME: string',
          'IP_ADDRESS: string',
          'ADDED_TIME_ISO8601: string',
          'id: string',
          'ADDED_TIME: string',
          'created_by: string',
        ]
      });
    });
  }

}
