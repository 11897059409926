export const environment = {
  production: true,
  clientId: '55608458-c9d0-453a-b6c0-6080404b5130',
  tenantId: '7b989014-3d69-4ac0-bcae-a249658757b5',
  authority: 'https://login.microsoftonline.com/7b989014-3d69-4ac0-bcae-a249658757b5/',
  redirectUrl: 'https://therapist.nationalpbs.com.au/',
  graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
  resource_map: 'https://graph.microsoft.com/v1.0/me',
  postLogoutUrl: 'https://therapist.nationalpbs.com.au/',
  npbsApiUrl: 'https://therapistapi.nationalpbs.com.au/',
  tokenlocalAccountId: 'tokenlocalAccountId'
};
