import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-bookings',
  templateUrl: './car-bookings.component.html',
  styleUrls: ['./car-bookings.component.css']
})
export class CarBookingsComponent implements OnInit {
  iframeURI: string = "";
  constructor() { }

  ngOnInit(): void {
    this.iframeURI = `https://outlook.office365.com/owa/calendar/450f9cd7d3eb4025aec2b2504394104e@nationalpbs.com.au/15d95b672a0a4331a98ffd207d68b46e16648238880303949292/calendar.html`;
  }

}
