import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.css']
})
export class PersonComponent implements OnInit {
  urlExt: string;
  public persons: Person[] = [];

  constructor(private httpService: HttpService) {
    
  }

  ngOnInit(): void {

    this.urlExt = `api/v1/persons`;
    this.persons = this.httpService.get(this.urlExt).subscribe(result => {
      this.persons = result;
    }, error => console.error(error));
  }

}

interface Person {
  id: number;
  name: string;
  age: number;
}
