import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { PersonComponent } from './person/person.component';
import { RestrictivePracticesEnvironmentAssessmentComponent } from './restrictive-practices-environment-assessment/restrictive-practices-environment-assessment.component';
import { RiskAssessmentManualComponent } from './risk-assessment-manual/risk-assessment-manual.component';
import { RiskAssessmentComponent } from './risk-assessment/risk-assessment.component';
import { SafetyCheckComponent } from './safety-check/safety-check.component';
import { InitialScreeningAndPrepComponent } from './initial-screening-and-prep/initial-screening-and-prep.component';
import { NdisOccupationalThearapyInitialAssessmentComponent } from './ndis-occupational-thearapy-initial-assessment/ndis-occupational-thearapy-initial-assessment.component';
import { PaediatricInitialAssessmentComponent } from './paediatric-initial-assessment/paediatric-initial-assessment.component';
import { RiskAssessmentManualListComponent } from './risk-assessment-manual-list/risk-assessment-manual-list.component';
import { RiskAssessmentListComponent } from './risk-assessment-list/risk-assessment-list.component';
import { SafetyCheckListComponent } from './safety-check-list/safety-check-list.component';
import { InitialScreeningAndPrepListComponent } from './initial-screening-and-prep-list/initial-screening-and-prep-list.component';
import { RestrictivePracticesEnvironmentAssessmentListComponent } from './restrictive-practices-environment-assessment-list/restrictive-practices-environment-assessment-list.component';
import { NdisOccupationalThearapyInitialAssessmentListComponent } from './ndis-occupational-thearapy-initial-assessment-list/ndis-occupational-thearapy-initial-assessment-list.component';
import { PaediatricInitialAssessmentListComponent } from './paediatric-initial-assessment-list/paediatric-initial-assessment-list.component';
import { ClientListComponent } from './client-list/client-list.component';
import { RoleGaurd } from './role.guard';
import { WhodasChildComponent } from './whodas-child/whodas-child.component';
import { WhodasChildListComponent } from './whodas-child-list/whodas-child-list.component';
import { StressThermometerToolListComponent } from './stress-thermometer-tool-list/stress-thermometer-tool-list.component';
import { StressThermometerToolComponent } from './stress-thermometer-tool/stress-thermometer-tool.component';
import { InterimBehaviourSupportComponent } from './interim-behaviour-support/interim-behaviour-support.component';
import { InterimBehaviourSupportListComponent } from './interim-behaviour-support-list/interim-behaviour-support-list.component';
import { InformationGatheringOnsiteOrZoomComponent } from './information-gathering-onsite-or-zoom/information-gathering-onsite-or-zoom.component';
import { InformationGatheringOnsiteOrZoomListComponent } from './information-gathering-onsite-or-zoom-list/information-gathering-onsite-or-zoom-list.component';
import { PurposeOfMedicationComponent } from './purpose-of-medication/purpose-of-medication.component';
import { PurposeOfMedicationListComponent } from './purpose-of-medication-list/purpose-of-medication-list.component';
import { ComprehensiveBehaviourSupportComponent } from './comprehensive-behaviour-support/comprehensive-behaviour-support.component';
import { ComprehensiveBehaviourSupportListComponent } from './comprehensive-behaviour-support-list/comprehensive-behaviour-support-list.component';
import { RestrictivePracticesPhysicalRestraintProtocolComponent } from './restrictive-practices-physical-restraint-protocol/restrictive-practices-physical-restraint-protocol.component';
import { RestrictivePracticesPhysicalRestraintProtocolListComponent } from './restrictive-practices-physical-restraint-protocol-list/restrictive-practices-physical-restraint-protocol-list.component';
import { WhoqolBrefLinkAndPaperComponent } from './whoqol-bref-link-and-paper/whoqol-bref-link-and-paper.component';
import { WhoqolBrefLinkAndPaperListComponent } from './whoqol-bref-link-and-paper-list/whoqol-bref-link-and-paper-list.component';
import { FadeOutPlanComponent } from './fade-out-plan/fade-out-plan.component';
import { FadeOutPlanListComponent } from './fade-out-plan-list/fade-out-plan-list.component';
import { NewFbaComponent } from './new-fba/new-fba.component';
import { NewFbaListComponent } from './new-fba-list/new-fba-list.component';
import { FaiComponent } from './fai/fai.component';
import { FaiListComponent } from './fai-list/fai-list.component';
import { ObservationChartComponent } from './observation-chart/observation-chart.component';
import { ObservationChartListComponent } from './observation-chart-list/observation-chart-list.component';
import { BehaviourEscalationFrequencyComponent } from './behaviour-escalation-frequency/behaviour-escalation-frequency.component';
import { BehaviourEscalationFrequencyListComponent } from './behaviour-escalation-frequency-list/behaviour-escalation-frequency-list.component';
import { StructuredAbcDataSheetComponent } from './structured-abc-data-sheet/structured-abc-data-sheet.component';
import { StructuredAbcDataSheetListComponent } from './structured-abc-data-sheet-list/structured-abc-data-sheet-list.component';
import { JustificationLetterComponent } from './justification-letter/justification-letter.component';
import { JustificationLetterListComponent } from './justification-letter-list/justification-letter-list.component';
import { LetterOfSupportComponent } from './letter-of-support/letter-of-support.component';
import { LetterOfSupportListComponent } from './letter-of-support-list/letter-of-support-list.component';
import { RecommendationsLetterComponent } from './recommendations-letter/recommendations-letter.component';
import { RecommendationsLetterListComponent } from './recommendations-letter-list/recommendations-letter-list.component';
import { HoursForNewFundingPeriodComponent } from './hours-for-new-funding-period/hours-for-new-funding-period.component';
import { HoursForNewFundingPeriodListComponent } from './hours-for-new-funding-period-list/hours-for-new-funding-period-list.component';
import { BehaviourAssessmentReportComponent } from './behaviour-assessment-report/behaviour-assessment-report.component';
import { BehaviourAssessmentReportListComponent } from './behaviour-assessment-report-list/behaviour-assessment-report-list.component';
import { OutcomeReportComponent } from './outcome-report/outcome-report.component';
import { OutcomeReportLsitComponent } from './outcome-report-list/outcome-report-list.component';
import { EnvironmentalAssessmentReportComponent } from './environmental-assessment-report/environmental-assessment-report.component';
import { EnvironmentalAssessmentReportListComponent } from './environmental-assessment-report-list/environmental-assessment-report-list.component';
import { ConsentToShareInformationComponent } from './consent-to-share-information/consent-to-share-information.component';
import { ConsentToShareInformationListComponent } from './consent-to-share-information-list/consent-to-share-information-list.component';
import { VisualConsentComponent } from './visual-consent/visual-consent.component';
import { VisualConsentListComponent } from './visual-consent-list/visual-consent-list.component';
import { ConsentFormPsychAttachApsCharterComponent } from './consent-form-psych-attach-aps-charter/consent-form-psych-attach-aps-charter.component';
import { ConsentFormPsychAttachApsCharterListComponent } from './consent-form-psych-attach-aps-charter-list/consent-form-psych-attach-aps-charter-list.component';
import { VisualIntroductionComponent } from './visual-introduction/visual-introduction.component';
import { VisualIntroductionListComponent } from './visual-introduction-list/visual-introduction-list.component';
import { VisualConsentPsychologyComponent } from './visual-consent-psychology/visual-consent-psychology.component';
import { VisualConsentPsychologyListComponent } from './visual-consent-psychology-list/visual-consent-psychology-list.component';
import { InitialAnalysisMseComponent } from './initial-analysis-mse/initial-analysis-mse.component';
import { InitialAnalysisMseListComponent } from './initial-analysis-mse-list/initial-analysis-mse-list.component';
import { IntakeAssessmentComponent } from './intake-assessment/intake-assessment.component';
import { IntakeAssessmentListComponent } from './intake-assessment-list/intake-assessment-list.component';
import { IntakeFormSeventeenAndUnderComponent } from './intake-form-seventeen-and-under/intake-form-seventeen-and-under.component';
import { IntakeFormSeventeenAndUnderListComponent } from './intake-form-seventeen-and-under-list/intake-form-seventeen-and-under-list.component';
import { PsychologyOutcomeReportComponent } from './psychology-outcome-report/psychology-outcome-report.component';
import { PsychologyOutcomeReportListComponent } from './psychology-outcome-report-list/psychology-outcome-report-list.component';
import { TreatmentPlanComponent } from './treatment-plan/treatment-plan.component';
import { TreatmentPlanListComponent } from './treatment-plan-list/treatment-plan-list.component';
import { SessionNoteComponent } from './session-note/session-note.component';
import { SessionNoteListComponent } from './session-note-list/session-note-list.component';
import { WhodasChildAndYouthComponent } from './whodas-child-and-youth/whodas-child-and-youth.component';
import { WhodasChildAndYouthListComponent } from './whodas-child-and-youth-list/whodas-child-and-youth-list.component';
import { DisdatComponent } from './disdat/disdat.component';
import { DisdatListComponent } from './disdat-list/disdat-list.component';
import { SuicideAssessmentKitComponent } from './suicide-assessment-kit/suicide-assessment-kit.component';
import { SuicideAssessmentKitListComponent } from './suicide-assessment-kit-list/suicide-assessment-kit-list.component';
import { ApsCharterForClientsComponent } from './aps-charter-for-clients/aps-charter-for-clients.component';
import { ApsCharterForClientsListComponent } from './aps-charter-for-clients-list/aps-charter-for-clients-list.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentListComponent } from './incident-list/incident-list.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { ComplaintListComponent } from './complaint-list/complaint-list.component';
import { DeskBookingsComponent } from './desk-bookings/desk-bookings.component';
import { CarBookingsComponent } from './car-bookings/car-bookings.component';
import { PeerSupportBookingsComponent } from './peer-support-bookings/peer-support-bookings.component';
import { RoomBookingsComponent } from './room-bookings/room-bookings.component';
import { ListOfStrategiesComponent } from './list-of-strategies/list-of-strategies.component';
import { PdDayComponent } from './pd-day/pd-day.component';
import { NewsComponent } from './news/news.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsListForTherapistComponent } from './news-list-for-therapist/news-list-for-therapist.component';
import { CommunicationFunctionComponent } from './communication-function/communication-function.component';
import { CommunicationFunctionListComponent } from './communication-function-list/communication-function-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'counter',
    component: CounterComponent
  },
  {
    path: 'fetch-data',
    component: FetchDataComponent
  },
  {
    path: 'person',
    component: PersonComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'clients',
    component: ClientListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'restrictive_practices_environment_assessment',
    component: RestrictivePracticesEnvironmentAssessmentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'risk_assessment_manual',
    component: RiskAssessmentManualComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'risk_assessment',
    component: RiskAssessmentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'safety_check',
    component: SafetyCheckComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'initial_screening_and_prep',
    component: InitialScreeningAndPrepComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'ndis_occupational_thearapy_initial_assessment',
    component: NdisOccupationalThearapyInitialAssessmentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'paediatric_initial_assessment',
    component: PaediatricInitialAssessmentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whodas_child_form',
    component: WhodasChildComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'stress_thermometer_tool',
    component: StressThermometerToolComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'interim_behaviour_support',
    component: InterimBehaviourSupportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'risk_assessment_manual_list',
    component: RiskAssessmentManualListComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [MsalGuard]
  },
  {
    path: 'risk_assessment_list',
    component: RiskAssessmentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'safety_check_list',
    component: SafetyCheckListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'initial_screening_and_prep_list',
    component: InitialScreeningAndPrepListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'restrictive_practices_environment_assessment_list',
    component: RestrictivePracticesEnvironmentAssessmentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'ndis_occupational_thearapy_initial_assessment_list',
    component: NdisOccupationalThearapyInitialAssessmentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'paediatric_initial_assessment_list',
    component: PaediatricInitialAssessmentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whodas_child_form_list',
    component: WhodasChildListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'stress_thermometer_tool_list',
    component: StressThermometerToolListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'interim_behaviour_support_list',
    component: InterimBehaviourSupportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'information_gathering_onsite_or_zoom',
    component: InformationGatheringOnsiteOrZoomComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'information_gathering_onsite_or_zoom_list',
    component: InformationGatheringOnsiteOrZoomListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'purpose_of_medication',
    component: PurposeOfMedicationComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'purpose_of_medication_list',
    component: PurposeOfMedicationListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'comprehensive_behaviour_support',
    component: ComprehensiveBehaviourSupportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'comprehensive_behaviour_support_list',
    component: ComprehensiveBehaviourSupportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'restrictive_practices_physical_restraint_protocol',
    component: RestrictivePracticesPhysicalRestraintProtocolComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'restrictive_practices_physical_restraint_protocol_list',
    component: RestrictivePracticesPhysicalRestraintProtocolListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whoqol_bref_link_and_paper',
    component: WhoqolBrefLinkAndPaperComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whoqol_bref_link_and_paper_list',
    component: WhoqolBrefLinkAndPaperListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'fade_out_plan',
    component: FadeOutPlanComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'fade_out_plan_list',
    component: FadeOutPlanListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'new_fba',
    component: NewFbaComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'new_fba_list',
    component: NewFbaListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'fai',
    component: FaiComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'fai_list',
    component: FaiListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'observation_chart',
    component: ObservationChartComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'observation_chart_list',
    component: ObservationChartListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'behaviour_escalation_frequency',
    component: BehaviourEscalationFrequencyComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'behaviour_escalation_frequency_list',
    component: BehaviourEscalationFrequencyListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'structured_abc_data_sheet',
    component: StructuredAbcDataSheetComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'structured_abc_data_sheet_list',
    component: StructuredAbcDataSheetListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'justification_letter',
    component: JustificationLetterComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'justification_letter_list',
    component: JustificationLetterListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'letter_of_support',
    component: LetterOfSupportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'letter_of_support_list',
    component: LetterOfSupportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'recommendations_letter',
    component: RecommendationsLetterComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'recommendations_letter_list',
    component: RecommendationsLetterListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'hours_for_new_funding_period',
    component: HoursForNewFundingPeriodComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'hours_for_new_funding_period_list',
    component: HoursForNewFundingPeriodListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'behaviour_assessment_report',
    component: BehaviourAssessmentReportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'behaviour_assessment_report_list',
    component: BehaviourAssessmentReportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'outcome_report',
    component: OutcomeReportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'outcome_report_list',
    component: OutcomeReportLsitComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'environmental_assessment_report',
    component: EnvironmentalAssessmentReportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'environmental_assessment_report_list',
    component: EnvironmentalAssessmentReportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'consent_to_share_information',
    component: ConsentToShareInformationComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'consent_to_share_information_list',
    component: ConsentToShareInformationListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_consent',
    component: VisualConsentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_consent_list',
    component: VisualConsentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'consent_form_psych_attach_aps_charter',
    component: ConsentFormPsychAttachApsCharterComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'consent_form_psych_attach_aps_charter_list',
    component: ConsentFormPsychAttachApsCharterListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_introduction',
    component: VisualIntroductionComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_introduction_list',
    component: VisualIntroductionListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_consent_psychology',
    component: VisualConsentPsychologyComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'visual_consent_psychology_list',
    component: VisualConsentPsychologyListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'initial_analysis_mse',
    component: InitialAnalysisMseComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'initial_analysis_mse_list',
    component: InitialAnalysisMseListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'intake_assessment',
    component: IntakeAssessmentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'intake_assessment_list',
    component: IntakeAssessmentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'intake_form_17_and_under',
    component: IntakeFormSeventeenAndUnderComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'intake_form_17_and_under_list',
    component: IntakeFormSeventeenAndUnderListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'psychology_outcome_report',
    component: PsychologyOutcomeReportComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'psychology_outcome_report_list',
    component: PsychologyOutcomeReportListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'treatment_plan',
    component: TreatmentPlanComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'treatment_plan_list',
    component: TreatmentPlanListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'session_note',
    component: SessionNoteComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'session_note_list',
    component: SessionNoteListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whodas_child_and_youth',
    component: WhodasChildAndYouthComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'whodas_child_and_youth_list',
    component: WhodasChildAndYouthListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'disdat',
    component: DisdatComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'disdat_list',
    component: DisdatListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'suicide_assessment_kit',
    component: SuicideAssessmentKitComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'suicide_assessment_kit_list',
    component: SuicideAssessmentKitListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'aps_charter_for_clients',
    component: ApsCharterForClientsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'aps_charter_for_clients_list',
    component: ApsCharterForClientsListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'incident',
    component: IncidentComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'incident-list',
    component: IncidentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'complaint',
    component: ComplaintComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'complaint-list',
    component: ComplaintListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'desk-bookings',
    component: DeskBookingsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'car-bookings',
    component: CarBookingsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'peer-support-bookings',
    component: PeerSupportBookingsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'room-bookings',
    component: RoomBookingsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'list-of-strategies',
    component: ListOfStrategiesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'pd-day',
    component: PdDayComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'news-list',
    component: NewsListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'news-details',
    component: NewsDetailsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'news-list-for-therapist',
    component: NewsListForTherapistComponent,
    canActivate: [MsalGuard]
  },{
    path: 'communication_function',
    component: CommunicationFunctionComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'communication_function_list',
    component: CommunicationFunctionListComponent,
    canActivate: [MsalGuard]
  },
];

// Don't perform initial navigation in iframes or popups, except for logout
//const initialNavigation = (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) || window.location.href.indexOf("logout") > 0; // Remove this line to use Angular Universal

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups, except for logout
    /*initialNavigation: initialNavigation ? 'enabled' : 'disabled' // Remove this line to use Angular Universal*/
    initialNavigation: 'enabled'// Remove this line to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
